import { FormControl, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

const FormLabel = ({ label, showQuantity, quantityValue, setQuantityFunction, ...params }) => {

    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
        defaultMatches: true,
        noSsr: false
    });

    return (
        <div>
            <FormControl fullWidth>
                <Stack direction="row" spacing={1} alignItems={smUp ? "flex-end" : "flex-start"} minHeight={46} justifyContent={smUp ? "flex-end" : "flex-start"}>
                    <Typography sx={params.sx} variant='body1'>{label}:</Typography>
                    <Typography variant='body1' sx={{ fontWeight: 'bold' }}>{params.value} {params.currency}</Typography>
                </Stack>
            </FormControl>
        </div>
    );
}

export default FormLabel;
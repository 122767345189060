import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { ResponsiveBar } from '@nivo/bar';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatNumber } from '../misc/helperfunctions';
import { tokens } from '../theme/theme';
import { UserContext } from '../theme/userContext';
import { format } from 'd3-format';
const { v4: uuidv4 } = require('uuid');

/**
 * 
 * @param {
 *     params.type === 'alldividendsbyyear'  -> Summe aller Dividenden pro Jahr im DIV UseCase
 * } param0 
 * @returns 
 */
const BarChartDivYear = ({ identifier, ...params }) => {
    const { user } = useContext(UserContext);
    const [chartData, setChartData] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const [width, setWidth] = useState(window.innerWidth);

    const chartDataFormatted = chartData.map((d) => ({
        year: d.year.toString(),
        value: parseFloat(d.total_gross_amount)
    }));


    /*   useEffect(() => {
          const handleResize = () => setWidth(window.innerWidth);
          window.addEventListener('resize', handleResize);
          return () => window.removeEventListener('resize', handleResize);
      }, []); */

    // Fetch divByYear
    async function fetchDivByYear() {
        try {
            if ((identifier && identifier.length > 1) || params.type === 'alldividendsbyyear') {
                let url = process.env.REACT_APP_API_URI + "/api/dividendsbyyear?identifier=" + identifier;
                url += "&order=asc";

                if (params.type === 'alldividendsbyyear') {
                    url = process.env.REACT_APP_API_URI + "/api/alldividendsbyyear?puser=" + user.p_user + "&order=asc";
                }

                const response = await fetch(url);
                const data = await response.json();
                setChartData(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const fetchDivByYearCallback = useCallback(fetchDivByYear, [identifier, user, params.type]);
    useEffect(() => {
        console.log("BarChartDivYear useEffect fetchDivByYearCallback")
        fetchDivByYearCallback();
    }, [fetchDivByYearCallback]);


    /** Label oberhalb des Balkens. Wenn der Platz nicht ausreicht (bar.width <= 8), dann kein Label anzeigen */
    const addBarLabels = ({ bars, xScale, yScale }) => {
        return (
            <>
                {bars.map((bar) => {
                    if (bar.width > 8 && !params.hideLabel) {
                        return (
                            <text
                                key={uuidv4()}
                                x={bar.x + bar.width / 2}
                                y={bar.y - 10}
                                textAnchor="middle"
                                dominantBaseline="baseline"
                                style={{
                                    fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                                    fontSize: 9,
                                }}
                                transform={`rotate(-45 ${bar.x + bar.width / 2},${bar.y - 10})`}
                            >
                                {formatNumber(bar.data.value)}
                            </text>
                        );
                    }
                    return null;
                })}
            </>
        );
    };

    const CustomTooltip = ({ data }) => {
        const currentYear = new Date().getFullYear().toString();
        return (
            <div
                style={{
                    background: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                    color: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                    padding: '9px 12px',
                    border: '1px solid',
                    borderColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.grey[800],
                    borderRadius: 2,
                }}
            >
                <div>{data.indexValue}</div>
                <div>{`Jahr: ${data.year}`}</div>
                <div><strong>{`Betrag: ${formatNumber(data.value)}`}</strong></div>
                {data.year === currentYear && <div style={{ color: 'red' }}>!! Im aktuellen Jahr können diese Daten unvollständig sein !!</div>}
            </div>
        );
    };

    return (
        <Box id="barChartBox" m="2px" mb={12} height="100%" width="100%">
            <Typography variant="h5" >
                Grafische Darstellung
            </Typography>
            <ResponsiveBar
                data={chartDataFormatted}
                keys={['value']}
                indexBy="year"
                theme={{
                    axis: {
                        domain: {
                            line: {
                                stroke: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[800],
                            }
                        },
                        legend: {
                            text: {
                                fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            }
                        },
                        ticks: {
                            line: {
                                stroke: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                                strokeWidth: 1
                            },
                            text: {
                                fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            }
                        }
                    },
                    grid: {
                        line: {
                            stroke: theme.palette.mode === "dark" ? colors.primary[300] : colors.primary[800],
                            strokeWidth: 0.5
                        }
                    },
                    crosshair: {
                        line: {
                            stroke: '#FA9C00',
                            strokeWidth: 1,
                            strokeOpacity: 1,
                        },
                    },
                    tooltip: {
                        container: {
                            background: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                            color: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            fontSize: 12
                        },
                        basic: {},
                        chip: {},
                        table: {},
                        tableCell: {},
                        tableCellValue: {}
                    }
                }}
                margin={{ top: 20, right: 10, bottom: 50, left: 46 }}
                padding={0.6}
                colors={{ scheme: 'nivo' }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickValues: chartDataFormatted.map(d => d.year).filter((d, i) => i % 2 === 0),
                    tickRotation: -45,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    format: params.type === 'alldividendsbyyear' ? value => format('.2s')(value) : '',
                }}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                barWidth={5}
                enableLabel={false}
                tooltip={CustomTooltip}
                layers={['grid', 'axes', 'bars', 'markers', 'legends', addBarLabels]}
            />
        </Box>
    );
};

export default BarChartDivYear;

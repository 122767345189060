import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { formatDate, formatNumber } from "../misc/helperfunctions";

export function Typofield(props) {
    const { label, value, unit, copy, copyvalue } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let plottvalue = value ? value : '-';
    if (value === 0 || value === '0' || value === '0,00') {
        plottvalue = '-';
    }

    let bold = props.bold;
    let color = 'inherit';

    function copyToClipboard(e) {
        navigator.clipboard.writeText(copyvalue);
    };

    if (props.type && plottvalue !== '-') {
        if (props.type === 'date') {
            try {
                plottvalue = formatDate(value);
            } catch (error) {
                plottvalue = '-';
            }
        } else if (props.type === 'stylednumber') {
            try {
                color = colors.greenAccent[300]; // colors.greenAccent[200];
                if (value && Number(value) < 0) {
                    color = colors.redAccent[300]; //colors.redAccent[200];
                }
                plottvalue = formatNumber(value);
            } catch (error) {
                plottvalue = '-';
            }
        } else if (props.type === 'number') {
            try {
                plottvalue = formatNumber(value);
            } catch (error) {
                plottvalue = '-';
            }
        }
    }
    if (unit) {
        plottvalue += ' ' + unit;
    }

    return (
        <Stack direction='row' justifyContent='space-between' alignItems="center" borderBottom={1} borderColor="#727681">
            <Typography key={`${label}-typography`} inline="true" variant="body1" align="left" sx={{ fontWeight: bold ? 'bold' : '' }} >{label}:</Typography>
            <Stack key={`${label}-stack`} direction='row'>
                {copy && value && (
                    <IconButton aria-label="delete" size="small" onClick={copyToClipboard}>
                        <ContentCopyIcon fontSize="inherit" />
                    </IconButton>)}
                <Typography key={`${label}-copy-typography`} marginTop="4px" color={color} inline="true" variant="h6" align="right" sx={{ fontWeight: bold ? 'bold' : '' }}>{plottvalue}</Typography>
            </Stack>
        </Stack >
    );
}
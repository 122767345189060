import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Box, FormControl, Grid, MenuItem, useMediaQuery } from "@mui/material";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { formatMuiDate, formatNumber, getCurrencySymbol } from "../misc/helperfunctions";
import { tokens } from "../theme/theme";
import { useLocation, useNavigate } from "react-router-dom";
import CurrencyInput from "../components/currencyInput";
import TextInput from "../components/textInput";

import { UserContext } from "../theme/userContext";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";


function AccountTransaction() {

    const { user } = useContext(UserContext);
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const location = useLocation();
    const { state } = location;
    let accountransactionid, type, editMode, useAccount;
    if (state) {
        accountransactionid = state.accountransactionid;
        editMode = state.editMode;
        type = state.type;
        useAccount = state.useAccount;
    }
    const theme = useTheme();
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
        defaultMatches: true,
        noSsr: false
    });

    const navigate = useNavigate();
    // eslint-disable-next-line
    const [isEditMode, setIsEditMode] = useState(editMode ? editMode : false);
    const headerText = isEditMode ? 'Kontobewegung bearbeiten...' : 'Kontobewegung eingeben...';
    // eslint-disable-next-line
    const [errorMessage, setErrorMessage] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [balanceAccount, setBalanceAccount] = useState(0);  // Kuntostand des Kontos

    // Formvalues
    const [account, setAccount] = useState('');
    const [transactionType, setTransactionType] = useState(type ? type : 'INCOME');
    const [transactionDate, setTransactionDate] = useState(moment(formatMuiDate(new Date())));
    // eslint-disable-next-line
    const [toAccount, setToAccount] = useState('');  // Umbuchen auf...
    const [amount, setAmount] = useState('');
    const [currency, setCurrency] = useState('EUR'); // Currency des Kontos
    const [notes, setNotes] = useState('');

    // Helper values
    const [amountText, setAmountText] = useState('Betrag');

    // eslint-disable-next-line
    const colors = tokens(theme.palette.mode);

    // Fetch Portfolio for Edit-Mode
    async function fetchAccounttransaction() {
        try {
            if (isEditMode) {
                console.log("EDIT MODUS: Load: " + accountransactionid);
                let puser = user?.p_user;
                let url = process.env.REACT_APP_API_URI + "/api/accounttransactionsbyid?puser=" + puser + "&id=" + accountransactionid;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);

                if (data[0]) {
                    setTransactionType(data[0].transaction_type);
                    setTransactionDate(moment(data[0].transactiondate));
                    setValue("transactionDate", moment(data[0].transactiondate));
                    setAccount(accounts.find(account => account.id === data[0].account_id).name);
                    setValue("selAccount", accounts.find(account => account.id === data[0].account_id).name);
                    setAmount(data[0].amount);
                    setValue("amount", data[0].amount);
                    setNotes(data[0].description);
                    setValue("notes", data[0].description);
                    // get currency from account
                    let selAccount = accounts.find(account => account.id === data[0].account_id);
                    setCurrency(selAccount.currency_code);
                    setValue("currency", selAccount.currency_code);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        if (accountransactionid && accounts && accounts.length > 0) {
            console.log("useEffect AccountTransaction fetchAccounttransaction")
            fetchAccounttransaction();
        }
        // eslint-disable-next-line
    }, [accountransactionid, accounts]);

    useEffect(() => {
        if (useAccount && accounts.some((item) => item.name === useAccount)) {
            console.log("useEffect AccountTransaction useAccount: " + useAccount);
            setAccount(useAccount);
            setValue("selAccount", useAccount);
        }
    }, [useAccount, accounts, setValue]);

    useEffect(() => {
        // set setBalanceAccount for selected account
        if (account && accounts.some((item) => item.name === account)) {
            let selAccount = accounts.find((item) => item.name === account);
            setBalanceAccount(selAccount.balance);
            setCurrency(selAccount.currency_code);
        }
        // eslint-disable-next-line
    }, [account]);

    // Fetch Accounts
    async function fetchAccounts() {
        try {
            let puser = user?.p_user;
            let url = process.env.REACT_APP_API_URI + "/api/accounts?puser=" + puser;
            const response = await fetch(url);
            const data = await response.json();

            setAccounts(data.accounts);
        } catch (error) {
            console.error(error);
            setAccounts([]);
        }
    }

    useEffect(() => {
        fetchAccounts();
        const nextInput = document.querySelector(`[name=amount]`);
        if (nextInput) {
            console.log("nextInput.focus(); 1");
            nextInput.focus();
        }
        // eslint-disable-next-line
    }, []);


    // Change transaction Type
    useEffect(() => {
        console.log("useEffect transactionType..." + transactionType);
        if (transactionType === 'INCOME') {
            setAmountText('Gutschrift');
        } else if (transactionType === 'EXPENSE') {
            setAmountText('Ausgabe');
        } else if (transactionType === 'TRANSFER') {
            setAmountText('Betrag');
        }
        // eslint-disable-next-line
    }, [transactionType]);


    // Fired on each change like a key press
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'amount') {
            setAmount(value);
        } else if (name === 'currency') {
            setCurrency(value);
        } else if (name === 'transactionDate') {
            setTransactionDate(value);
        } else if (name === 'selAccount') {
            setAccount(value);
        } else if (name === 'notes') {
            setNotes(value);
        } else if (name === 'selTransactionType') {
            setTransactionType(value);
        }
    }

    // Focus next Input Element on Key Enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const onSubmit = async (data) => {
        console.log("onSubmit");

        const date = moment.unix(transactionDate / 1000); // divide by 1000 to convert from milliseconds to seconds
        const formattedDate = date.format('YYYY-MM-DD'); // format the date as "TT.MM.JJJJ"
        console.log("TransactionDate: " + formattedDate);
        // put transactiondate to data
        data.transactionDate = formattedDate;
        console.log(data);
        try {
            await handleSave(data);
            handleClose();
        } catch (error) {
            console.error(error.message);
        }

    };


    // Save transaction to databas
    async function handleSave(data) {

        let url = process.env.REACT_APP_API_URI + "/api/createaccounttransaction";

        //  const { accountName, puser, transId, accountCurrency, transactionDate, transactionType, transactionText, amount, description } = transactionData;

        let currency = 'EUR';
        if (data) {
            currency = accounts.find(account => account.name === data.selAccount).currency_code;
        }

        // if data.amount > 0 and type === EXPENSE then set amount to negative
        if (data.amount > 0 && data.selTransactionType === 'EXPENSE') {
            data.amount = data.amount * -1;
        }

        let transactionText;

        switch (data.selTransactionType) {
            case 'INCOME':
                transactionText = "Einnahme manuell angelegt.";
                break;
            case 'EXPENSE':
                transactionText = "Ausgabe manuell angelegt.";
                break;
            case 'TRANSFER':
                transactionText = "Umbuchnung von Konto auf Konto " + parseFloat(data.amount).toLocaleString('de-DE', { style: 'currency', currency: currency });
                break;
            default:
                transactionText = data.selTransactionType + " " + parseFloat(data.amount).toLocaleString('de-DE', { style: 'currency', currency: currency });
                break;
        }

        await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: accountransactionid,
                accountName: data.selAccount,
                puser: user.p_user,
                transId: null,
                // get currency from accounts   
                accountCurrency: currency,
                transactionDate: data.transactionDate,
                transactionType: data.selTransactionType,
                amount: parseFloat(data.amount),
                description: data.description,
                // set transactiontext to type of transaction (INCOME, EXPENSE, TRANSFER) an amount in german format
                // INCOME translate to "Einnahme", EXPENSE translate to "Ausgabe", TRANSFER translate to "Überweisung"
                transactionText: transactionText,
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then((error) => {
                        throw new Error(error.message);
                    });
                }
                return response.json();
            })
            .then((data) => {
                console.log("Changes saved");
            })
            .catch((error) => {
                console.error(error.message);
                throw new Error(error.message);
            });

    }

    // Close view
    const handleClose = () => {
        navigate(-1);
    };

    return (
        <Box id="portTableMainBox" m="20px"  >
            <Box
                marginTop={2}
                padding={0}
                border={theme.palette.mode === "dark" ? 0 : 0}
                borderRadius="8px"
                backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
            >
                <form onSubmit={handleSubmit(onSubmit)} >
                    <DialogTitle fontSize={24}>
                        <Stack direction="row" spacing={4} alignItems="center" minHeight={46}>
                            <Typography variant="h3">{smUp ? headerText : "Aktion..."}</Typography>
                            <Typography color="error" variant="h5">{errorMessage ? errorMessage : ''}</Typography>
                        </Stack>
                    </DialogTitle>
                    <DialogContent dividers>

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                            maxWidth="800px"
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center">

                            <Grid item xs={8}>
                                <TextInput
                                    id="accountSelect"
                                    select
                                    label="Verrechnungskonto"
                                    fullWidth
                                    required
                                    {...register("selAccount", { required: true })}
                                    value={account}
                                    onChange={handleChange}
                                    disabled={isEditMode}
                                    error={Boolean(errors.selAccount)}
                                    helperText={errors.selAccount && "Verrechnungskonto wählen"}
                                >
                                    {accounts.map(item => (
                                        <MenuItem key={item.id} value={item.name}>
                                            {`${item.name}`} ({getCurrencySymbol(item.currency_code)})
                                        </MenuItem>
                                    ))}
                                </TextInput>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography mt={2} textAlign="right" variant='body1'>Kontostand: </Typography>
                                <Typography variant='body1' textAlign="right">{formatNumber(balanceAccount)} {getCurrencySymbol(currency)}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    id="typeSelect"
                                    value={transactionType}
                                    label='Typ'
                                    name="selTransactionType"
                                    fullWidth
                                    select
                                    {...register("selTransactionType", { required: true })}
                                    onChange={handleChange}
                                    disabled={false}
                                >
                                    <MenuItem value="INCOME">Einnahme</MenuItem>
                                    <MenuItem value="EXPENSE">Ausgabe</MenuItem>
                                </TextInput>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <Typography variant='body1'>Datum</Typography>
                                    <Stack direction="row" spacing={0} alignItems="center" marginTop={0} >
                                        <DatePicker
                                            value={transactionDate}
                                            label=""
                                            format="DD.MM.YYYY"
                                            onChange={(newValue) => setTransactionDate(newValue)}
                                            fullWidth
                                        />
                                    </Stack>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    name="amount"
                                    control={control}
                                    defaultValue={amount}
                                    // amount must be greater than 0    
                                    rules={{ required: true, min: 0.01, pattern: /^[0-9,\\.]+$/ }}
                                    render={({ field }) => <CurrencyInput id="curInput"
                                        {...field}
                                        name="amount"
                                        currencyName="currency"
                                        label={amountText}
                                        value={field.value}
                                        handleValueChange={(e) => {
                                            setAmount(e.target.value);
                                            field.onChange(e);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        disabled={false}
                                        currencySelect={false}
                                        currency={currency}
                                        handleCurrencyChange={handleChange}
                                        error={Boolean(errors.amount)}
                                        helpertext={errors.amount && "Enter amount"}
                                    />}
                                />
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>

                            <Grid item xs={12}>
                                <TextInput
                                    margin="dense"
                                    {...register("notes")}
                                    label="Notizen"
                                    rows={2}
                                    fullWidth
                                    disabled={false}
                                    onKeyDown={handleKeyDown}
                                    value={notes}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={1} alignItems="center" marginLeft={2} >
                            <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
                            <Button variant="contained" color="success" type="submit" disabled={false}>Speichern</Button>
                        </Stack>
                    </DialogActions>
                </form>
            </Box>
        </Box>

    );
};


export default AccountTransaction
import { Box, Divider, Stack, Typography } from '@mui/material';
import Header from '../components/header';

export default function Impressum() {
    return (
        <Box id="impMainBox" m="20px" >
            <Stack id="stackHeader" direction="row" display="flex">
                <Header title="IMPRESSUM" />
            </Stack>
            <Divider />
            <Typography variant="body1" align="justify" mt={2}>
                Angaben gemäß § 5 TMG:
                <br />
                Finalyser GmbHiG
                <br />
                Kreisstrasse
                <br />
                21445 Wulfsen
                <br />
                <br />
                <b>Kontakt:</b>
                <br />
                Telefon: +49 (0)123 456789
                <br />
                E-Mail: info@finalyser.de
                <br />
                <br />
                Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                <br />
                Finalyser GmbHiG
                <br />
                Kreisstrasse
                <br />
                21445 Wulfsen
                <br />
                <br />
                <b>Haftungsausschluss:</b>
                <br />
                Die in dieser Anwendung dargestellten Wertpapierkurse werden mit einer Verzögerung von mindestens 15 Minuten angezeigt. Die Anwendung dient ausschließlich zu Informationszwecken und stellt keine Anlageberatung dar. Jegliche Haftung für die Richtigkeit, Vollständigkeit und Aktualität der angezeigten Informationen wird ausgeschlossen.
                <br />
                <br />
                <b>Links zu externen Webseiten:</b>
                <br />
                Diese Anwendung enthält Links zu externen Webseiten. Wir haben keinen Einfluss auf den Inhalt dieser Seiten und übernehmen keinerlei Haftung für deren Inhalte. Für den Inhalt der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
                <br />
                <br />
                <b>Urheberrecht:</b>
                <br />
                Die Inhalte dieser Anwendung sind urheberrechtlich geschützt. Jede Verwendung, Vervielfältigung oder Verbreitung der Inhalte bedarf der schriftlichen Zustimmung des jeweiligen Rechteinhabers.
            </Typography>
        </Box>
    );
}

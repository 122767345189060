import { Alert, Box, Button, Collapse, Container, Link, TextField, Typography } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { sendPasswordResetMail, signInUser } from "../theme/firebase";
import { UserContext } from "../theme/userContext";
import { Stack } from "@mui/system";
import { getAuth } from "firebase/auth";

export default function Login() {

  const navigate = useNavigate();

  const { user, error: contextError, setError: setContextError } = useContext(UserContext);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    // validate the inputs
    if (!email || !password) {
      setError("Please enter your email and password.");
      return;
    }

    // clear the errors
    setError("");
    setContextError("");

    const auth = getAuth();

    if (auth.currentUser) {
      // User is already signed in, navigate to /feed
      console.log("User is already signed in");
      navigate("/error-tech", { replace: true, state: { email: auth.currentUser.email } });
    } else {
      // No user is signed in, try to sign in
      console.log("No user is signed in, try to sign in");
      try {
        let loginResponse = await signInUser(email, password);
        if (loginResponse) {
          navigate("/feed", { replace: true });
        }
      } catch (error) {
        console.error(error.message);
        setError(error.message);
      }
    }

  }

  const onPasswordReset = async () => {

    // validate the inputs
    if (!email) {
      setError("Please enter your email.");
      return;
    }

    // clear the errors
    setError("");
    setContextError("");

    try {
      await sendPasswordResetMail(email);
      setOpen(true);
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    }

  }


  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
      }}
    >
      <Container maxWidth="xs" sx={{ mt: 12 }}>
        <Typography variant="h5" component="h1" gutterBottom textAlign="center">
          Finalyser Login
        </Typography>
        {contextError && <Alert severity="error" sx={{ my: 2 }}>{contextError}</Alert>}
        {error && <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>}
        <Box component="form" onSubmit={onSubmit}>
          <TextField
            label="Email"
            variant="outlined"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value.toLowerCase())}
            sx={{ mt: 1 }}
            fullWidth
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mt: 3 }}
            fullWidth
          />
          <Button variant="contained" type="submit" sx={{ mt: 3 }} fullWidth>Login</Button>
          <Stack direction="row" sx={{ mt: 4 }} alignItems="center" justifyContent="center">
            <Typography variant="body2">Ich habe noch kein Profil.</Typography>
            <Link ml={1} variant="body2" href="/register">Jetzt registrieren!</Link>
          </Stack>
          <Stack direction="row" sx={{ mt: 1 }} alignItems="center" justifyContent="center">
            <Typography mr={1} variant="body2">Passwort vergessen?</Typography>
            <Link component="button" variant="body2" onClick={onPasswordReset} >Neues Passwort per Mail anfordern</Link>
          </Stack>
        </Box>
        <Collapse in={open}>
          <Alert mt={2} severity="success">Es wurde eine Mail zum Passwort Reset versendet!</Alert>
        </Collapse>
        {(user && user.hasOwnProperty('p_user')) && <Navigate to="/feed" state={user} replace={true} />}
      </Container>
    </Box>
  )
}
import { Alert, Box, Button, Container, Link, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { createUser } from "../theme/firebase";

export default function Register() {

    const navigate = useNavigate();

    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();

        // validate the inputs
        if (!email || !password || !name || !surname) {
            setError("Please enter your email, name, surename and password.");
            return;
        }
        if (password !== password2) {
            setError("Passwords do not match.");
            return;
        }

        // clear the errors
        setError("");

        try {
            // Create new user in database
            await handleSave()
            // create user in firebase
            let createResponse = await createUser(email, password);
            console.log(createResponse);
            // everthing fine...
            navigate("/");
        } catch (error) {
            console.error(error.message);
            setError(error.message);
        }

    }

    // Save transaction to databas
    async function handleSave() {
        let isError = false;
        let url = process.env.REACT_APP_API_URI + "/api/createuser";

        await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                name: name,
                surname: surname,
                settings: {
                    "mode": "light",
                    "tableDensity": "standard"
                }
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then((error) => {
                        throw new Error(error.message);
                    });
                }
                return response.json();
            })
            .then((data) => {
                console.log("Changes saved");
            })
            .catch((error) => {
                console.error(error.message);
                setError(error.message);
                isError = true;
                throw new Error(error.message);
            });

        if (!isError) {
            setError(null);
        }

    };

    return (
        <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom textAlign="center">
                Bei Finalyser registrieren...
            </Typography>
            {error && <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>}
            <Box component="form" onSubmit={onSubmit}>
                <TextField
                    label="Email"
                    variant="outlined"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    sx={{ mt: 1 }}
                    fullWidth
                />
                <TextField
                    label="Vorname"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{ mt: 1 }}
                    fullWidth
                />
                <TextField
                    label="Nachname"
                    variant="outlined"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    sx={{ mt: 1 }}
                    fullWidth
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ mt: 3 }}
                    fullWidth
                />
                <TextField
                    label="Password (re-enter)"
                    variant="outlined"
                    type="password"
                    autoComplete="new-password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    sx={{ mt: 3 }}
                    fullWidth
                />
                <Button variant="contained" type="submit" sx={{ mt: 3 }} fullWidth>Register</Button>
                <Box sx={{ mt: 2 }}>
                    Do you have allready an account? <Link href="/login">Login</Link>
                </Box>
            </Box>
        </Container>
    )
}
import React, { createContext, useEffect, useState } from "react";
import { onAuthStateChange } from "./firebase";


// create context
const UserContext = createContext();

/*
Der UserContextProvider speichert und verteilt den angemeldeten User. Nach dem erfolgreichen Firebase 
login (auth) wird der User aus der Datenbank (API) gelesen und in den State gesetzt.
Sollte der User in der DB nicht vorhanden sein, wird user auf null gesetzt und der Login ist nicht möglich.
*/

const UserContextProvider = ({ children }) => {

    const [user, setUser] = useState(() => {
        const storedUser = sessionStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : {};
    });

    const [error, setError] = useState(null);

    // Get firebase authentification changes like login, logout
    useEffect(() => {
        const unsubscribe = onAuthStateChange(prepairUser);
        return () => {
            unsubscribe();
        };
        // eslint-disable-next-line
    }, []);

    // Logout user and clean enviroment
    const logoutHandler = () => {
        sessionStorage.removeItem("user");
        sessionStorage.clear();
        setUser(null);
    };

    // Login user and set some user values
    const loginHandler = async (firebaseUser) => {
        console.log("UserContextProvider.loginHandler " + JSON.stringify(firebaseUser));
        await fetchUser(firebaseUser.email);
    };

    // calling from firebase
    const prepairUser = (firebaseUser) => {
        if (firebaseUser) {
            console.log(firebaseUser.email + " has currently logged in...");
            loginHandler(firebaseUser);
        } else {
            logoutHandler();
        }
    }

    async function fetchUser(email) {
        try {
            console.log("fetchUser from database..." + email);
            if (email) {
                const encodedEmail = encodeURIComponent(email);
                let url = process.env.REACT_APP_API_URI + "/api/user?email=" + encodedEmail;
                console.log(url);
                const response = await fetch(url);
                const data = await response.json();

                if (data[0]) {
                    console.log("Found user: " + JSON.stringify(data[0]));
                    // Set formValues
                    setUser(data[0]);
                    sessionStorage.setItem('user', JSON.stringify(data[0]));
                } else {
                    console.error("User not found... " + email);
                    logoutHandler();
                }
            }
        } catch (error) {
            console.error(error);
            logoutHandler();
            setError("Keine Verbindung zum Backend-Server. Sind Sie online? Bitte Netzwerk prüfen... " + error); // Set the error state
        }
    }

    return (
        // the Provider gives access to the context to its children
        <UserContext.Provider value={{
            user: user,
            setUser: setUser,
            onLogout: logoutHandler,
            onLogin: loginHandler,
            error: error, // Provide the error state
            setError: setError,
        }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserContextProvider };
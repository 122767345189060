async function updateUserData(userData) {

    let url = process.env.REACT_APP_API_URI + "/api/createuser";

    await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email: userData.email,
            name: userData.name,
            surname: userData.surname,
            settings: userData.settings,
        }),
    })
        .then((response) => {
            if (response.status !== 200) {
                return response.json().then((error) => {
                    throw new Error(error.message);
                });
            }
            return response.json();
        })
        .then((data) => {
            console.log("Changes saved");
        })
        .catch((error) => {
            console.error(error.message);
            throw new Error(error.message);
        });

}

export default updateUserData;
import { Box, Slider, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { ResponsiveBar } from '@nivo/bar';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { formatNumber, getCurrencySymbol } from '../misc/helperfunctions';
import { tokens } from '../theme/theme';
import { UserContext } from '../theme/userContext';

/**
 * 
 * @param {
 *     params.type === 'alldividendsbyyear'  -> Summe aller Dividenden pro Jahr im DIV UseCase
 * } param0 
 * @returns 
 */
const BarChartDipGainFinder = ({ ...params }) => {
    const { user } = useContext(UserContext);
    const [chartData, setChartData] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
        defaultMatches: true,
        noSsr: false
    });
    const [sliderValue, setSliderValue] = useState(smUp ? 7 : 4);

    const chartDataFormatted = chartData.map((d) => ({
        id: d.identifier,
        value: parseFloat(d.dip_close_200d),
        name: d.name,
        close: d.close,
        currencycode: d.currencycode,
        lo_250d: d.lo_250d,
        hi_250d: d.hi_250d,
        ema_200d: d.ema_200d,
    }));

    async function fetchDips() {
        try {

            // wenn user.p_user = "undefind", dann abbruch
            if (!user.p_user) {
                return;
            }

            let url = process.env.REACT_APP_API_URI + "/api/dipgainfinder?puser=" + user.p_user + "&maxCount=" + sliderValue;
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            let filteredData = [];

            if ((sliderValue * 2) <= data.length) {
                filteredData = [
                    ...data.slice(0, sliderValue),
                    ...data.slice(-sliderValue)
                ];
                setChartData(filteredData);
            } else {
                console.log("sliderValue ist größer als die Anzahl der Daten");
                setChartData(data);
            }

        } catch (error) {
            console.error(error);
        }
    }

    /*  useEffect(() => {
         console.log("BarChartDipGainFinder useEffect fetchDips")
         fetchDips();
         // eslint-disable-next-line
     }, [user, sliderValue]); */

    // eslint-disable-next-line
    const fetchDipsCallback = useCallback(fetchDips, [sliderValue]);
    useEffect(() => {
        console.log("useEffect fetchDipsCallback")
        fetchDipsCallback();
    }, [fetchDipsCallback]);


    /** Label oberhalb des Balkens. Wenn der Platz nicht ausreicht (bar.width <= 8), dann kein Label anzeigen */
    const addBarLabels = ({ bars, xScale, yScale }) => {
        return (
            <>
                {bars.map((bar) => {
                    if (bar.width > 8 && !params.hideLabel) {
                        return (
                            <text
                                key={bar.data.indexValue}
                                x={(bar.x + bar.width / 2) + 2}
                                y={bar.y - 10}
                                textAnchor="middle"
                                dominantBaseline="baseline"
                                style={{
                                    fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                                    fontSize: 9,
                                }}
                                transform={`rotate(-45 ${bar.x + bar.width / 2},${bar.y - 10})`}
                            >
                                {formatNumber(bar.data.value)}
                            </text>
                        );
                    }
                    return null;
                })}
            </>
        );
    };

    const CustomTooltip = ({ data }) => {
        return (
            <div
                style={{
                    background: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                    color: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                    padding: '9px 12px',
                    border: '1px solid',
                    borderColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.grey[800],
                    borderRadius: 2,
                }}
            >
                <div>{data.indexValue}</div>
                <div>{`${data.name}`}</div>
                <div>{`Identifier: ${data.id}`}</div>
                <div>{`Kurs: ${formatNumber(data.close)} ${getCurrencySymbol(data.currencycode)}`}</div>
                <div>{`Low 250d: ${formatNumber(data.lo_250d)}`}</div>
                <div>{`High 250d: ${formatNumber(data.hi_250d)}`}</div>
                <div>{`EMA 200d: ${formatNumber(data.ema_200d)}`}</div>
                <div><strong>{`Gain: ${formatNumber(data.value)} %`}</strong></div>
            </div>
        );
    };

    const handleChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setSliderValue(newValue);
        }
    };

    if (!user.p_user) {
        return <div>Anmeldung...</div>;
    }

    return (
        <Box id="barChartBox" m="2px" mb={12} height="100%" width="100%">
            <Typography variant="h5" >
                DIP - GAIN FINDER (close to 200d in %)
            </Typography>
            {smUp && (<Slider value={sliderValue} step={1} defaultValue={7} min={1} max={20} size='small'
                onChange={handleChange} color="primary" />)}
            <ResponsiveBar
                data={chartDataFormatted}
                keys={['value']}
                indexBy="name"
                theme={{
                    axis: {
                        domain: {
                            line: {
                                stroke: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[800],
                            }
                        },
                        legend: {
                            text: {
                                fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            }
                        },
                        ticks: {
                            line: {
                                stroke: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                                strokeWidth: 1
                            },
                            text: {
                                fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            }
                        }
                    },
                    grid: {
                        line: {
                            stroke: theme.palette.mode === "dark" ? colors.primary[300] : colors.primary[800],
                            strokeWidth: 0.5
                        }
                    },
                    crosshair: {
                        line: {
                            stroke: '#FA9C00',
                            strokeWidth: 1,
                            strokeOpacity: 1,
                        },
                    },
                    tooltip: {
                        container: {
                            background: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                            color: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            fontSize: 12
                        },
                        basic: {},
                        chip: {},
                        table: {},
                        tableCell: {},
                        tableCellValue: {}
                    }
                }}
                margin={{ top: 30, right: 10, bottom: 140, left: 46 }}
                padding={0.6}
                //colors={{ scheme: 'nivo' }}
                colors={d => d.data.value < 0 ? 'red' : 'green'}
                // layout="horizontal"
                reverse={false}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32,
                    tickRotation: -45,
                    format: (value) => {
                        const maxLength = 16; // maximale Anzahl an Zeichen
                        if (value.length > maxLength) {
                            return value.substring(0, maxLength - 3) + '...'; // abgeschnittener Text
                        }
                        return value;
                    },
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                barWidth={5}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                tooltip={CustomTooltip}
                layers={['grid', 'axes', 'bars', 'markers', 'legends', addBarLabels]}
            />
        </Box>
    );
};

export default React.memo(BarChartDipGainFinder);

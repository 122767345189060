import { FormControl, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { forwardRef } from 'react';


const CurrencyInput = forwardRef((props, ref) => {
    const { label, currencySelect, name, disabled, value, handleValueChange, currencyName, currency, handleCurrencyChange, sx } = props;

    return (
        <div>
            <FormControl fullWidth>
                <Typography sx={sx} variant='body1'>{label}</Typography>
                <Stack direction="row" spacing={0} alignItems="center" >
                    <TextField
                        variant={disabled ? 'filled' : 'outlined'}
                        name={name}
                        label=""
                        value={value}
                        fullWidth
                        onChange={handleValueChange}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                            }
                        }}
                        disabled={disabled}
                        sx={sx}
                        error={props.error}
                        helperText={props.helperText}
                        inputProps={{ type: "number", step: "0.01" }}
                    />
                    <TextField
                        variant={disabled ? 'filled' : 'outlined'}
                        // labelId="currency-select-label"
                        id="currency-select"
                        select={currencySelect ? currencySelect : false}
                        disabled={!currencySelect || disabled}
                        value={currency}
                        name={currencyName}
                        onChange={handleCurrencyChange}
                        sx={sx}
                        error={props.error}
                        // helperText={props.error ? ' ' : ''}  // wenn aktiv, sind die TextFields versetzt
                        style={{ minWidth: 80, maxWidth: 100 }}
                    >
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="GBP">GBP</MenuItem>
                        <MenuItem value="HKD">HKD</MenuItem>
                        <MenuItem value="CHF">CHF</MenuItem>
                        <MenuItem value="SEK">SEK</MenuItem>
                        <MenuItem value="NOK">NOK</MenuItem>
                        <MenuItem value="AUD">AUD</MenuItem>
                    </TextField>
                </Stack>
            </FormControl>
        </div>
    );
});

export default CurrencyInput
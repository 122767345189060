import { Typography, useTheme } from "@mui/material";
import { formatNumber } from "../misc/helperfunctions";
import { tokens } from "../theme/theme";

export const TypoStyledNumber = (props) => {
    const { value, unit } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let color = colors.greenAccent[300]; // colors.greenAccent[200];
    if (value && Number(value) < 0) {
        color = colors.redAccent[300]; //colors.redAccent[200];
    }
    const stylednumber = formatNumber(value) + ' ' + unit;

    return (
        <Typography
            color={color}
            marginLeft={2}
            variant="body2" display="block" >
            {stylednumber}
        </Typography>
    );
}
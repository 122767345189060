import React from "react";
import { Box } from "@mui/material";

const Insider = () => {

    return (
        <Box bgcolor={"background.default"} color={"text.primary"} flex={5} p={2} >Insider</Box>
    )

}

export default Insider
import { Avatar, Stack, styled, Tooltip, tooltipClasses, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";
import InfoIcon from '@mui/icons-material/Info';

export function Typoheader(props) {
    const { label, hint } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            background: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
            color: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
            padding: '9px 12px',
            border: '1px solid',
            borderColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.grey[800],
            borderRadius: 2,
            fontSize: 12,
        },
    }));

    return (
        <Stack direction='row' justifyContent='space-between' alignItems="center" paddingBottom={1} paddingTop={1}>
            <Typography color={colors.greenAccent[300]} variant="h5">{label}</Typography>

            <HtmlTooltip title={hint}>
                <Avatar
                    display="flex"
                    sx={{
                        backgroundColor: 'success.main',
                        height: 20,
                        width: 20
                    }} >
                    <InfoIcon />
                </Avatar>
            </HtmlTooltip>
        </Stack>
    );
}
import { formatDateTime, formatNumber, getClassForPositiveNegativeValue } from "../../misc/helperfunctions";

export function getStockcolumns() {

    const columns = [
        // field 'identifier' to add by the caller
        // field 'name' to add by the caller
        {
            field: "quantity",
            headerName: "Bestand",
            width: 100,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "close",
            headerName: "Kurs",
            width: 90,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "currencycode",
            headerName: "Currency",
            width: 60,
        },
        {
            field: "profit_1d",
            headerName: "Abs. Perf. 1d",  // Performance 1 day
            width: 100,
            type: "number",
            cellClassName: (params) => {
                return getClassForPositiveNegativeValue(params);
            },
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "change_p",
            headerName: "Change %",
            width: 90,
            type: "number",
            cellClassName: (params) => {
                return getClassForPositiveNegativeValue(params);
            },
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "pricedate",
            headerName: "Kursdatum",
            width: 140,
            type: "date",
            valueFormatter: (params) => {
                const valueFormatted = formatDateTime(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "ttwror30",
            headerName: "Perf. 1M",
            width: 80,
            type: "number",
            description:
                'TTWROR 30 (Performance 1 Monat): 30 Tage Time Weighted Return on Risk. Prozentuale Veränderung der letzten 30 Tage.',
            cellClassName: (params) => {
                return getClassForPositiveNegativeValue(params);
            },
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "total_buy_price",
            headerName: "Einstandspreis",
            width: 120,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "total_market_price",
            headerName: "Marktwert",
            width: 120,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "profit",
            headerName: "Gewinn/Verlust",
            width: 120,
            type: "number",
            cellClassName: (params) => {
                return getClassForPositiveNegativeValue(params);
            },
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "abs_performance",
            headerName: "Abs. Performance",
            width: 140,
            type: "number",
            description:
                'Kursgewinne + Realisierte Gewinne + Brutto Dividende - Steuer in EUR',
            cellClassName: (params) => {
                return getClassForPositiveNegativeValue(params);
            },
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "abs_performance_p",
            headerName: "Abs.Perf.%",
            width: 90,
            type: "number",
            cellClassName: (params) => {
                return getClassForPositiveNegativeValue(params);
            },
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "dividends_brutto_total",
            headerName: "∑ Dividenden",
            width: 120,
            type: "number",
            description:
                'Summe aller Dividenden (Brutto) in EUR',
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "dividend_yield_total",
            headerName: "Div % total",
            width: 90,
            type: "number",
            description:
                'Dividendenertrag gesamt in %',
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "dividendyield",
            headerName: "Div % aktuell",
            width: 90,
            type: "number",
            description:
                'Dividendenrendite aktuell (bei Neuerwerb an Börse)',
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "owner_dividend_yield",
            headerName: "Eigene DivRend. %",
            width: 90,
            type: "number",
            description:
                'Eigene Dividendenrendite bezogen auf den Einstandskurs und die Ausschüttung pro Jahr',
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "total_gross_dividend_in_eur",
            headerName: "Div pro Jahr €",
            width: 100,
            type: "number",
            description:
                'Brutto Ausschüttungen pro Jahr in € bezogen auf den heutigen Bestand und die zur erwartende Ausschüttung',
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },



    ];

    return columns;
}


import { Box, CircularProgress, Divider, Grid, IconButton, List, ListItemButton, ListItemText, Menu, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCurrencySymbol } from '../misc/helperfunctions';
import AccountDialog from '../scenes/dialogs/AccountDialog';
import { tokens } from '../theme/theme';
import { UserContext } from '../theme/userContext';
import { TypoStyledNumber } from './typhoStyledNumber';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmDialog from '../scenes/dialogs/confirmDialog';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AccountTransactionDialog from '../scenes/dialogs/AccountTransactionDialog';

export const AccountList = ({ mainViewWillReload, onSelectItem }) => {
    const { user } = useContext(UserContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // for loading Icon
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editingAccount, setEditingAccount] = useState(null);
    const [willReload, setWillreload] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const [selectedItem, setSelectedItem] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false); // TransactionDialog
    const [transactionDialogOpen, setTransactionDialogOpen] = useState(false);

    // Fetch Accounts
    async function fetchAccounts() {
        try {
            setIsLoading(true);
            let puser = user?.p_user;
            let url = process.env.REACT_APP_API_URI + "/api/accounts?puser=" + puser;
            const response = await fetch(url);
            const data = await response.json();

            setAccounts(data.accounts);
            setIsLoading(false);
            setWillreload(false);
        } catch (error) {
            console.error(error);
            setAccounts([]);
            setIsLoading(false);
            setWillreload(false);
        }
    }

    async function deleteAccountApi() {
        console.log("Account will delete: " + selectedItem.name)
        try {
            if (selectedItem) {
                let url = process.env.REACT_APP_API_URI + "/api/deleteaccount";

                await fetch(url, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: selectedItem.id,
                        puser: user?.p_user,
                    }),
                })
                    .then((response) => response.json())
                    .then(() => {
                        console.log("Changes saved");
                    })
                    .catch((error) => {
                        console.error(error);
                        return false;
                    })

                // Datensatz gelöscht
                setSelectedItem(null);
                setWillreload(true);

            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        console.log("useEffect fetchAccountsCallback")
        fetchAccounts();
        //eslint-disable-next-line
    }, [user, willReload, mainViewWillReload]);

    const handleEditAccount = () => {
        setEditingAccount(selectedItem);
        console.log("Open Edit Dialog: " + selectedItem.name);
        console.log(selectedItem);
        setAnchorEl(null);
        setDialogOpen(true);
    };

    const deleteAccount = () => {
        console.log("Delete account: " + selectedItem.name);
        setAnchorEl(null);
        setConfirmOpen(true);
    };

    // Open Dialog for new account
    const handleOpenDialog = () => {
        setSelectedItem(null);
        setEditingAccount(null);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setEditingAccount(null);
    };

    // Action Menu
    const handleMenuClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        console.log("handleMenuClick: " + event + " " + item.name);
        setSelectedItem(item);
    };

    // Action Menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleListClick = (event, item) => {
        console.log("handleListClick: " + event + " " + item.name);
        setSelectedItem(item);
        onSelectItem(item);
    }

    return (
        <Box
            marginTop={1}
            padding={2}
            border={theme.palette.mode === "dark" ? 0 : 0}
            borderRadius="8px"
            backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box>
                    <Typography variant="h4" mt={1} ml={1}>
                        Verrechnungskonten
                    </Typography>
                </Box>
                <Stack direction="row" >
                    {isLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {(!isLoading) && (
                        <IconButton aria-label="add" onClick={handleOpenDialog} >
                            <AddCircleOutlineIcon />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
            <Divider />
            <List>
                {Array.isArray(accounts) && accounts.map((item, i) => (
                    <ListItemButton onClick={(e) => handleListClick(e, item)}
                        divider={i < accounts.length - 1}
                        key={`item-${i}`}
                        sx={{ marginRight: 0, paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{item.name}</Typography>
                                <Typography variant="h7">{item.description}</Typography>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                <TypoStyledNumber value={item.balance} unit={getCurrencySymbol(item.currency_code)} />
                            </Grid>
                            <Grid item xs={1} sx={{ textAlign: 'right' }}>
                                {/* <Button variant="text" color="primary" onClick={() => handleEditAccount(Object.assign({}, item))}>Bearbeiten</Button> */}
                                <div>
                                    <IconButton
                                        aria-label="more"
                                        id={`item-menu-${item.id}`}
                                        aria-controls={`item-menu-${item.id}-menu`}
                                        aria-expanded={menuOpen ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(e) => handleMenuClick(e, item)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id={`item-menu-${item.id}-menu`}
                                        anchorEl={anchorEl}
                                        open={menuOpen}
                                        onClose={handleMenuClose}
                                        PaperProps={{
                                            style: {
                                                width: '20ch',
                                                boxShadow: 'none',
                                                border: '1px solid rgba(0,0,0,0.2)',
                                                elevation: 0,
                                            },
                                        }}
                                    >
                                        {/* <MenuItem onClick={() => setTransactionDialogOpen(true)}>Einzahlung</MenuItem> */}
                                        <MenuItem
                                            component={Link}
                                            to={{
                                                pathname: "/accounttransaction",
                                            }}
                                            state={{ useAccount: selectedItem?.name, type: 'INCOME' }}
                                        >
                                            <ListItemText>Einnahme</ListItemText>
                                        </MenuItem>
                                        <MenuItem
                                            component={Link}
                                            to={{
                                                pathname: "/accounttransaction",
                                            }}
                                            state={{ useAccount: selectedItem?.name, type: 'EXPENSE' }}
                                        >
                                            <ListItemText>Ausgabe</ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={() => handleEditAccount()}>Konto Bearbeiten</MenuItem>
                                        <MenuItem onClick={() => deleteAccount()}>Konto Löschen</MenuItem>
                                    </Menu>
                                </div>
                            </Grid>
                        </Grid>
                    </ListItemButton>
                ))}
            </List>

            <AccountDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                account={editingAccount}
                onSave={(account) => {
                    // Add new account to accounts list
                    setWillreload(true);
                    handleCloseDialog();
                    setEditingAccount(null);
                }}
            />
            <AccountTransactionDialog
                open={transactionDialogOpen}
                onClose={() => setTransactionDialogOpen(false)}
                accounts={accounts}
            />
            <ConfirmDialog
                title="Delete Account?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteAccountApi}
            >
                Soll das Verrechnungskonto {selectedItem?.name} wirklich gelöscht werden?
            </ConfirmDialog>
        </Box >
    )
};

import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Button,
  CircularProgress, Grid,
  IconButton,
  InputBase,
  Stack, Tab, Tabs, Typography, useMediaQuery, useTheme
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FinDataGrid from "../../components/findatagrid";
import Header from "../../components/header";
import { formatNumber } from "../../misc/helperfunctions";
import { tokens } from "../../theme/theme";
import { UserContext } from "../../theme/userContext";
import Detailbar from "./detailbar";
import { getStockcolumns } from "./stockcolumns";
import Tabdetailbar from "./tabdetailbar";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

const Portfolio = () => {
  const { user } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("");  // for filter Inputtext.value
  const [isLoading, setIsLoading] = useState(true); // for loading Icon
  const [willReload, setWillreload] = useState(false);
  const [tabValue, setTabValue] = useState(() => {
    const storedTab = sessionStorage.getItem('tab');
    return storedTab ? JSON.parse(storedTab) : 0;
  });

  const [data, setData] = useState("");  // Portfolio performance summery datas for (Tab-) Detailbar
  const [isDataCached, setIsDataCached] = useState(false);
  const inputRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [scrollPosition, setScrollPosition] = useState(0);
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    defaultMatches: true,
    noSsr: false
  });
  // For iPhone 11 or less
  const is375pxOrLess = useMediaQuery("(max-width: 375px)");
  const is390pxOrLess = useMediaQuery("(max-width: 390px)");
  const is430pxOrLess = useMediaQuery("(max-width: 430px)");
  const is480pxOrLess = useMediaQuery("(max-width: 480px)");
  const hasFilter = filter && filter.toString().trim() !== "";
  // const [density, setDensity] = useState(user?.settings?.tableDensity);
  const [density, setDensity] = useState('compact');
  const [initialState, setInitialState] = useState({
    sorting: {
      sortModel: [{ field: 'total_market_price', sort: 'desc' }],
    },
  });
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: 'id',
        operatorValue: '>',
        value: 0,
      },
    ],
  });
  let columns = getStockcolumns();
  let columnsMobil = [];
  const [mobilColModus, selMobilColModus] = useState(0);

  async function fetchPortfolioDetails() {
    try {
      let url = process.env.REACT_APP_API_URI + "/api/performance?puser=" + user.p_user;
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
      setIsDataCached(true);
    } catch (error) {
      console.error(error);
    }
  }

  // Load Database items
  async function fetchRows(aktualtab, aktualFilter) {
    try {
      setIsLoading(true);
      let type;
      let url = process.env.REACT_APP_API_URI + "/api/portfolio?puser=" + user.p_user;
      switch (aktualtab) {
        case 0:
          type = 'Common Stock';
          break;
        case 1:
          type = 'ETF';
          break;
        case 2:
          type = 'FUND';
          break;
        case 3:
          type = 'OTHERS';
          break;
        default: {
          console.log("No tab selected.");
          type = 'Common Stock';
        }
      }
      if (type) {
        url += "&type=" + type;
      }
      // Workaround for automatic timer reloading
      if (!aktualFilter && filter) {
        aktualFilter = filter;
      }
      if (aktualFilter) {
        url += "&filter=" + aktualFilter;
      }
      console.log(url);
      const response = await fetch(url);
      const data = await response.json();
      setRows(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  // Effekted by search button click or ENTER 
  const handleSearchClick = () => {
    console.log("handleSearchClick");
    fetchRows(tabValue, filter);
  };

  // Clear Filter Button
  const handleClearFilter = () => {
    inputRef.current.value = "";
    setFilter("");
    // fetchRows(tabValue, "");
  };

  // Change filter by key click
  const handleInputChange = (event) => {
    setFilter(event.target.value);
  };

  /** FIXME -> write an application wide user settings provider */
  useEffect(() => {
    if (user && user.settings) {
      setDensity(user.settings.tableDensity);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    console.log("useEffect 1");
    fetchRows(tabValue, filter);

    // Reload every 5 Minutes
    const interval = setInterval(() => {
      console.log("Timer Interval...");
      fetchRows(tabValue, filter);
      setIsDataCached(false);
    }, 5 * 60 * 1000);
    return () => {
      console.count("useEffect Portfolio return...");
      clearInterval(interval);
    }
    // wenn filter mit ins array übernommen wird, so 
    // wird bei jedem neuem Key-Click eine Datenbank Suche ausgelöst
    // eslint-disable-next-line
  }, [tabValue, filter, willReload]);

  useEffect(() => {
    if (!isDataCached) {
      fetchPortfolioDetails();
    }
  }, [isDataCached]);

  /** Set ScrollPosition in Mobil at´fter Change mobile view */
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 50)
  }, [scrollPosition]);

  // Helper function
  function findRowById(id) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].id === id) {
        return rows[i];
      }
    }
    return null;
  }

  // component Tabpanel to make things visible in the tab area
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          // Diese Box enthaelt den Tab Client Context
          <Box sx={{ p: 0 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  // helper function
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // change tab
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    sessionStorage.setItem('tab', JSON.stringify(newValue));
  };

  // Save sortmodel to have access on every tab
  const handleSortModelChange = (sortModel) => {
    setInitialState({
      sorting: { sortModel }
    });
  }

  // Save filtermodel to have access on every tab
  const handleFilterModelChange = (filterModel) => {
    if (filterModel) {
      setFilterModel(filterModel);
      //console.log(JSON.stringify(filterModel));
    }
  }

  // Save density to have access on every tab
  // Attention: this may costs performance
  const handleStateChange = (state) => {
    if (state) {
      density !== state.density.value && setDensity(state.density.value)
      // console.log(JSON.stringify(filterModel));
    }
  }

  // helper function
  function findRowByName(name) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].name === name) {
        return rows[i];
      }
    }
    return null;
  }

  const colLogo = {
    field: "logo",
    headerName: "Logo",
    width: 40,
    renderCell: (params) => {
      const row = findRowById(params.id);
      let imageURL = process.env.REACT_APP_API_URI + '/images/' + row.identifier + '.png';
      /*   if (!row.logourl) {
          imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
        } */
      return (
        <Avatar src={imageURL} alt={'Company Logo'}
          sx={{
            height: 30,
            width: 30
          }}
        >
        </Avatar>
      );
    },
  };

  function getColumnWidth() {
    let mobilWidth = 190;
    if (is375pxOrLess) {
      mobilWidth = 170;
    } else if (is390pxOrLess) {
      mobilWidth = 190;
    } else if (is430pxOrLess) {
      mobilWidth = 216;
    } else if (is480pxOrLess) {
      mobilWidth = 240;
    }
    console.log("getColumnWidth: " + mobilWidth)
    return mobilWidth;
  }
  const mobilWidth = getColumnWidth();

  const colName = {
    field: "name",
    headerName: "Name",
    width: smUp ? 200 : mobilWidth,
    renderCell: (params) => {
      const row = findRowByName(params.value);
      let isPositive = row.profit >= 0;
      let cellClassName = "name-column--cell";
      if (!isPositive) {
        cellClassName = "negative-column--cell";
      }
      return (
        <Button color="inherit" variant="text" size="medium" component={Link}
          to={{
            pathname: "/detailview",
          }}
          state={{ identifier: row.identifier, portfolioid: row.id }}
          sx={{ m: 0, p: 0, minWidth: 0 }}
        >
          {smUp && (<Stack className={cellClassName} maxWidth={smUp ? 200 : mobilWidth}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
            <Typography variant="h7" noWrap>{row.isin} · {row.identifier}</Typography>
          </Stack>)}
          {!smUp && (<Stack maxWidth={smUp ? 200 : mobilWidth}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              width: "100%",
            }}>
            <Typography className={cellClassName} variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
            <Stack direction="row">
              <Typography variant="h7" noWrap >x{formatNumber(row.quantity)}</Typography>
              <Typography ml={1} variant="h7" noWrap fontWeight="bold"> {formatNumber(row.total_market_price)} €</Typography>
            </Stack>
            <Stack direction="row" sx={{ gap: 0 }} alignItems="center">
              {isPositive && (<NorthIcon fontSize="small" className={cellClassName} sx={{ marginLeft: '-2px', width: 12, height: 12, }} />)}
              {!isPositive && (<SouthIcon fontSize="small" className={cellClassName} sx={{ marginLeft: '-2px', width: 12, height: 12, }} />)}
              <Typography variant="h7" className={cellClassName} noWrap >{formatNumber(row.abs_performance_p)} %</Typography>
              {/* <Typography ml={1} variant="h7" className={cellClassName} noWrap >{formatNumber(row.profit)} €</Typography> */}
            </Stack>
          </Stack>)}
        </Button>
      );
    },
  };
  const handleColModusChanged = () => {
    let col = mobilColModus + 1;
    if (col >= 2) {
      col = 0;
    }
    selMobilColModus(col);
    setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
  };

  // For Mobil Devices
  const colCompact = {
    field: "compact",
    headerName: "Kurs",
    width: 106,
    align: "right",
    renderCell: (params) => {
      const row = findRowById(params.id);
      let curSymbol = '';
      if (row.currencycode === 'EUR') {
        curSymbol = '€';
      } else if (row.currencycode === 'USD') {
        curSymbol = '$';
      } else {
        curSymbol = row.currencycode ? row.currencycode : '';
      }
      if (curSymbol === 'Unknown') {
        curSymbol = '';
      }
      const closeFormatted = formatNumber(row.close) + ' ' + curSymbol;
      let formattedValue = formatNumber(row.change_p) + ' %';
      if (mobilColModus === 1) {
        formattedValue = formatNumber(row.profit_1d) + curSymbol;
      }
      return (
        <Stack justifyContent="flex-end" alignItems="center">
          <Typography variant="body1" noWrap>{closeFormatted}</Typography>
          <Button variant="contained" size="small" color={row.change_p < 0 ? 'error' : 'success'}
            sx={{ m: 0, p: "2px", minWidth: "64px", maxWidth: "64px" }}
            onClick={handleColModusChanged}
          >
            <Typography variant="body2" noWrap>{formattedValue}</Typography>
          </Button>
        </Stack>
      );
    },
  };
  // Add special field 'identifier'
  /* columns.splice(0, 0, {
    field: "identifier",
    headerName: "Identifier",
    width: 100,
    renderCell: (params) => {
      const row = findRowById(params.id);
      return (
        <Button color="inherit" variant="outlined" size="medium" component={Link}
          to={{
            pathname: "/detailview",
          }}
          state={{ identifier: row.identifier, portfolioid: row.id }}
          style={{
            maxWidth: '80px', minWidth: '80px', borderColor: colors.primary[300]
          }
          }>
          <Stack maxWidth="70px">
            <Typography variant="h7" noWrap>{row.identifier}</Typography>
          </Stack>
        </Button >
      );
    },
  }) */
  // Add special field 'logo'
  columns.splice(0, 0, colLogo);
  columnsMobil.splice(0, 0, colLogo);

  // Add special field 'name'
  columns.splice(1, 0, colName);
  columnsMobil.splice(1, 0, colName);
  columnsMobil.splice(2, 0, colCompact);

  return (
    <Box id="portTableMainBox" m="20px" >
      <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
        <Grid item xs={3} sm={2}>
          <Header title="PORTFOLIO" subtitle="" />
        </Grid>
        <Grid item xs={1} >
          <Box display="flex" justifyContent="center" ml={smUp ? 4 : 2} >
            {isLoading && (
              <CircularProgress color="inherit" />
            )}
            {(!isLoading) && (
              <IconButton ml={2} size="large" aria-label="refresh" onClick={setWillreload}>
                <RefreshIcon fontSize="large" />
              </IconButton>
            )}
          </Box>

        </Grid>
        <Grid item xs={4} sm={6}>
          <Box
            display="flex"
            backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
            borderRadius="8px"
            height="40px"
            padding="0px 16px"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              ref={inputRef}
              value={filter}
              inputProps={{ spellCheck: 'false' }}
              placeholder="Search"
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearchClick();
                }
              }}
            />
            <IconButton type="button" sx={{ p: 1 }} onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
            {hasFilter && (
              <IconButton onClick={handleClearFilter}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Detailbar data={data} />
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "none", lg: "flex" } }}>
          <Tabdetailbar value={tabValue} data={data} sx={{ mt: 2 }} />
        </Grid>
        <Grid item xs={4} marginTop={0}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="security tabs" textColor="secondary"
            indicatorColor="secondary" variant="fullWidth">
            <Tab label="Stocks"  {...a11yProps(0)} />
            <Tab label="ETFs"  {...a11yProps(1)} />
            <Tab label="Funds"  {...a11yProps(2)} />
            <Tab label="Other"  {...a11yProps(3)} />
          </Tabs>
        </Grid>
        <Grid item xs={8} marginTop={0}>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <FinDataGrid
              id="gridStocks"
              autoHeight
              headerHeight={smUp ? 60 : 0}
              rowHeight={60}
              rows={rows}
              columns={smUp ? columns : columnsMobil}
              density={smUp ? density : "comfortable"}
              components={{
                Toolbar: smUp ? GridToolbar : '',
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: smUp ? true : '{}',
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              initialState={initialState}
              // onRowClick={handleRowButtonClick}
              onSortModelChange={handleSortModelChange}
              filterModel={smUp ? filterModel : { items: [], }}
              onFilterModelChange={handleFilterModelChange}
              onStateChange={handleStateChange}
            />
            {/* <Stockhighlights identifier={identifier} /> */}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <FinDataGrid
              id="gridETF"
              autoHeight
              headerHeight={smUp ? 60 : 0}
              rowHeight={60}
              rows={rows}
              columns={smUp ? columns : columnsMobil}
              density={smUp ? density : "comfortable"}
              components={{
                Toolbar: smUp ? GridToolbar : '',
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: smUp ? true : '{}',
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              initialState={initialState}
              // onRowClick={handleRowButtonClick}
              onSortModelChange={handleSortModelChange}
              filterModel={smUp ? filterModel : { items: [], }}
              onFilterModelChange={handleFilterModelChange}
              onStateChange={handleStateChange}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <FinDataGrid
              id="gridFunds"
              autoHeight
              headerHeight={smUp ? 60 : 0}
              rowHeight={60}
              rows={rows}
              columns={smUp ? columns : columnsMobil}
              density={smUp ? density : "comfortable"}
              components={{
                Toolbar: smUp ? GridToolbar : '',
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: smUp ? true : '{}',
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              initialState={initialState}
              // onRowClick={handleRowButtonClick}
              onSortModelChange={handleSortModelChange}
              filterModel={smUp ? filterModel : { items: [], }}
              onFilterModelChange={handleFilterModelChange}
              onStateChange={handleStateChange}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <FinDataGrid
              id="gridOthers"
              autoHeight
              headerHeight={smUp ? 60 : 0}
              rowHeight={60}
              rows={rows}
              columns={smUp ? columns : columnsMobil}
              density={smUp ? density : "comfortable"}
              components={{
                Toolbar: smUp ? GridToolbar : '',
              }}
              componentsProps={{
                toolbar: {
                  showQuickFilter: smUp ? true : '{}',
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              initialState={initialState}
              // onRowClick={handleRowButtonClick}
              onSortModelChange={handleSortModelChange}
              filterModel={smUp ? filterModel : { items: [], }}
              onFilterModelChange={handleFilterModelChange}
              onStateChange={handleStateChange}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Portfolio;

import { Box, Container, Grid, Typography, useTheme, Button } from '@mui/material';
import { Link } from "react-router-dom";
import React from 'react';
import { tokens } from '../theme/theme';

function Footer() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            component="footer"
            bottom={0}
            left={0}
            sx={{
                width: "100%",
                height: "auto",
                paddingTop: "1rem",
                paddingBottom: "1rem",
            }}
            backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
        >
            <Container maxWidth="lg">
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            finalyser.app
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h7" fontWeight="bold">
                            <Button color="inherit" variant="text" size="medium" component={Link}
                                to={{
                                    pathname: "/impressum",
                                }}
                                sx={{ padding: 1 }}
                            >Copyright © 2023
                            </Button>
                            |
                            <Button color="inherit" variant="text" size="medium" component={Link}
                                to={{
                                    pathname: "/impressum",
                                }}
                                sx={{ padding: 1 }}
                            >Über uns
                            </Button> |
                            <Button color="inherit" variant="text" size="medium" component={Link}
                                to={{
                                    pathname: "/impressum",
                                }}
                                sx={{ padding: 1 }}
                            >Impressum
                            </Button>
                            {' | V 1.2'}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );

    /*  return (
         <Box
             component="footer"
             position="fixed"
             bottom={0}
             width="100%"
             minHeight={30}
             backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}>
 
             <Typography variant="body2" align="center">
                 Copyright © 2023 Finalyser
             </Typography>
         </Box >
     ); */
}

export default Footer;
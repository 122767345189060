const moment = require('moment');

function getCurrencySymbol(currencyCode) {
  switch (currencyCode) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    default:
      return currencyCode;
  }
}

function isSameDate(date1, date2) {

  let d1 = new Date(date1);
  let d2 = new Date(date2);

  // Setzen Sie die Stunden, Minuten, Sekunden und Millisekunden auf null
  d1.setHours(0, 0, 0, 0);
  d2.setHours(0, 0, 0, 0);

  // Vergleichen Sie die Zeitkomponenten der beiden Daten
  return d1.getTime() === d2.getTime();
}

function formatDate(date) {

  moment.locale("de");
  const formattedDate = moment(date).format("DD.MM.YYYY");
  return formattedDate;
}

function formatDateShort(date) {
  moment.locale("de");
  const formattedDate = moment(date).format("DD.MM.YY");
  return formattedDate;
}

function getYearFromDate(date) {

  moment.locale("de");
  const formattedDate = moment(date).format("YYYY");
  return formattedDate;
}

function formatDateLong(date) {

  const months = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ];

  const d = new Date(date);
  const day = d.getDate();
  const monthIndex = d.getMonth();
  const year = d.getFullYear();

  return `${day}. ${months[monthIndex]} ${year}`;
}

/* Returns the given date into "YYYY-MM-DD" */
/** Returns 2023-02-14 */
function formatMuiDate(date) {

  moment.locale("de");
  const formattedDate = moment(date).format("YYYY-MM-DD");
  return formattedDate;
}

/** Returns: "Sat Jan 14 2023 10:23:58 GMT 0100 (Mitteleuropäische Normalzeit)" */
function getJavaScriptDate(strDate) {
  let jsDate = moment(strDate, 'DD.MM.YYYY').toDate();
  return jsDate;
}

function formatDateTime(timestamp) {
  if (timestamp) {
    return moment(timestamp).format('DD.MM.YYYY HH:mm');
  } else {
    return "???";
  }
}

function formatNumber(number, digits) {
  if (number) {
    let numberString = Number(number).toFixed(digits ? digits : 2);
    numberString = numberString.toString().replace(".", ",");
    numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return numberString;
  } else {
    return "0,00";
  }
}

function isNumber(value) {
  return !isNaN(value);
}
function formatNumberWithoutDigits(number) {
  if (isNumber(number)) {
    const numericValue = parseFloat(number.toString().replace(',', '.'));
    const numberString = numericValue.toLocaleString('de-DE', { maximumFractionDigits: 0 });
    return numberString;
  } else {
    return "0";
  }
}

function formatNumberCurrency(number, currencyCode) {
  return formatNumber(number) + ' ' + currencyCode;
}

function getNumberofString(numberStr) {
  if (typeof numberStr === 'string') {
    let number = numberStr.replace(/[^\d,-]/g, '') // entferne alle ungültigen Zeichen
      .replace(',', '.') // Ersetze Komma durch Punkt
      .replace(/[,](?!.*,)/g, ''); // Entferne alle Tausender-Trennzeichen außer dem letzten
    return number;
  }
}

/* const getNumberofString = (numberStr) => {
  if (numberStr) {
    // test if numberStr is currently a correct number
    let testNmber = parseFloat(numberStr.replace(',', '.'));


    // let formattedNumber = numberStr;
    // formattedNumber = formattedNumber.replace(/[^\d,.-]/g, ""); // entferne alle ungültigen Zeichen außer Komma, Punkt und Minus
    // formattedNumber = formattedNumber.replace(/[.]/g, ""); // entferne alle Punkte
    // formattedNumber = formattedNumber.replace(/[,](?=[^,.-])/g, "."); // Ersetze alle Kommas durch Punkte, die nicht am Ende stehen
    return testNmber;
  }
} */

function getClassForPositiveNegativeValue(params) {

  if (params.value == null) {
    return '';
  }
  let classname = '';
  if (params.value < 0) {
    classname = 'negative-column--cell';
  } else {
    classname = 'positive-column--cell';
  }
  return classname;

}

// eslint-disable-next-line
function testMe() {

  let numberStr = '37,40';
  console.log(numberStr + " ergibt: " + getNumberofString(numberStr));

  numberStr = '  1.537,40  ';
  console.log(numberStr + " ergibt: " + getNumberofString(numberStr));
  numberStr = '37.40';
  console.log(numberStr + " ergibt: " + getNumberofString(numberStr));
  numberStr = '23.000.344,78';
  console.log(numberStr + " ergibt: " + getNumberofString(numberStr));


}


module.exports = {
  getCurrencySymbol,
  formatDate,
  formatDateShort,
  getYearFromDate,
  formatDateLong,
  formatMuiDate,
  getJavaScriptDate,
  formatDateTime,
  formatNumber,
  formatNumberWithoutDigits,
  formatNumberCurrency,
  getNumberofString,
  getClassForPositiveNegativeValue,
  isSameDate
};

//testMe();


import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Secdetail = () => {
    const [counter, setCounter] = useState(
        Number(localStorage.getItem("counter")) || 0
    );
    const routeParams = useParams();
    const navigate = useNavigate();

    console.log(routeParams.identifier);
    console.count("Secdetail geladen");

    useEffect(() => {
        localStorage.setItem("counter", counter);
        return () => {
            // Destructor
            console.count("useEffect Secdetail return...");
        }
    }, [counter]);

    return (
        <Box bgcolor={"background.default"} color={"text.primary"} flex={5} p={2}>
            Secdetail {routeParams.identifier} {counter}
            <Button onClick={() => setCounter(counter + 1)}>Increment</Button>
            <Button onClick={() => navigate(-1)}>go back</Button>
        </Box>
    );
};

export default Secdetail;
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { useCallback, useContext, useEffect, useState } from 'react';
import { formatDate, getJavaScriptDate } from '../misc/helperfunctions';
import { tokens } from '../theme/theme';
import { UserContext } from '../theme/userContext';

/*
Linechart erzeugt einen Linienchart über den Kursverlauf von Identifier.
Die Kursdaten sowie Transaktionen (BUY, SELL, DIVIDEND) werden automatisch geladen und im Chart dargestellt.

Die Kursdaten werden vollständig in einen state geladen (orgdaten) und je nach range (5D, 1M,...) im Speicher reduzeirt und in
pricedata abgeleget.

Gute Infos zu den Details gibt es hier:
https://nivo.rocks/line/
https://developer.mozilla.org/en-US/docs/Web/SVG/Element/rect
https://www.dtreelabs.com/blog/composable-charts-using-nivo-in-react

*/

const Linechart = ({ identifier, ...params }) => {

    const { user } = useContext(UserContext);  // User für den Abruf der Transaktionen
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [orgdata, setOrgdata] = useState([]);  // Originale Kursdaten. Komplett
    const [pricedata, setPricedata] = useState([]);  // reduzierte Daten je nach range
    const [transactions, setTransactions] = useState([]);  // alle Portfolio transactions zu identifier
    const [range, setRange] = useState(7);  // über die ButtonGroup einzustellenden range. Default: 3 Jahre
    const [tickValues, setTickValues] = useState('every 90 days'); // helper zum justieren der tick Symbole auf der X-Achse
    const [axisBottomFormat, setAxisBottomFormat] = useState('%b %d');  // Ausgabeformat der tick Symbole auf der X-Achse
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    /** Helperfunction zum check des Kursdatums in der eingestellten Range */
    function isDateInCurrentange(date) {

        const fiveDaysAgo = Date.now() - 5 * 24 * 60 * 60 * 1000;
        const thirteeDaysAgo = Date.now() - 30 * 24 * 60 * 60 * 1000;
        const ninetyDaysAgo = Date.now() - 90 * 24 * 60 * 60 * 1000;
        const sixMonthsAgo = Date.now() - 180 * 24 * 60 * 60 * 1000;
        const oneYearAgo = Date.now() - 365 * 24 * 60 * 60 * 1000;
        const threeYearsAgo = Date.now() - 365 * 3 * 24 * 60 * 60 * 1000;
        const fiveYearsAgo = Date.now() - 365 * 5 * 24 * 60 * 60 * 1000;

        switch (range) {
            case 1:
                setTickValues(lgUp ? 'every day' : 5);
                setAxisBottomFormat('%d.%m.%Y');
                return (date >= fiveDaysAgo) ? true : false;
            case 2:
                setTickValues(lgUp ? 'every 10 days' : 5);
                setAxisBottomFormat('%d.%m.%Y');
                return (date >= thirteeDaysAgo) ? true : false;
            case 3:
                setTickValues(lgUp ? 'every 30 days' : 5);
                setAxisBottomFormat('%d.%b');
                return (date >= ninetyDaysAgo) ? true : false;
            case 4:
                setTickValues(lgUp ? 'every 30 days' : 5);
                setAxisBottomFormat('%d.%b');
                return (date >= sixMonthsAgo) ? true : false;
            case 5: // YTD
                const currentYear = new Date().getFullYear();
                const dateToCheck = date;
                const yearOfDateToCheck = dateToCheck.getFullYear();
                const isCurrentYear = currentYear === yearOfDateToCheck;
                setTickValues(5);
                setAxisBottomFormat('%d.%m.%Y');
                return (isCurrentYear) ? true : false;
            case 6:
                setTickValues(lgUp ? 'every 30 days' : 5);
                setAxisBottomFormat('%d.%b');
                return (date >= oneYearAgo) ? true : false;
            case 7:
                setTickValues(lgUp ? 'every 90 days' : 5);
                setAxisBottomFormat('%b %Y');
                return (date >= threeYearsAgo) ? true : false;
            case 8:
                setTickValues(5);
                setAxisBottomFormat('%b %Y');
                return (date >= fiveYearsAgo) ? true : false;
            case 9: // MAx
                setTickValues(8);
                setAxisBottomFormat('%b %Y');
                return true;
            default:
                return false;
        }

    }

    /** Reduktion der Orgdaten auf die Pricedatan innerhalb der eingestellten range */
    async function createPricedata() {

        if (orgdata) {
            // console.log("createPricedata");

            const outputArray = [
                {
                    "id": "stock",
                    "color": "#FA9C00",
                    "data": []
                }
            ];
            orgdata.forEach(function (element) {
                const parsedDate = getJavaScriptDate(element.date);
                if (isDateInCurrentange(parsedDate)) {
                    outputArray[0].data.push({
                        "x": parsedDate,
                        "y": element.close
                    });
                }
            });

            setPricedata(outputArray);
        }

    }
    // eslint-disable-next-line
    const createPricedataCallback = useCallback(createPricedata, [orgdata, range]);
    useEffect(() => {
        // console.log("useEffect createPricedataCallback");
        createPricedataCallback();
    }, [createPricedataCallback]);


    // Fetch alle Kursdaten
    async function fetchPriceHistorical() {
        try {
            let url = process.env.REACT_APP_API_URI + "/api/pricehistoric?identifier=" + identifier;
            const response = await fetch(url);
            const data = await response.json();

            for (let i = data.length - 1; i >= 1; i--) {
                const close = data[i].close;
                if (!data[i - 1].close) {
                    data[i - 1].close = close;
                }
            }
            setOrgdata(data);

        } catch (error) {
            console.error(error);
        }
    }
    const fetchPriceHistoricalCallback = useCallback(fetchPriceHistorical, [identifier]);
    useEffect(() => {
        console.log("useEffect fetchPriceHistoricalCallback")
        fetchPriceHistoricalCallback();
    }, [fetchPriceHistoricalCallback]);

    // Fetch alle Portfoliotransaktionen
    async function fetchPorttransactions() {
        try {
            console.log("fetchPorttransactions: " + identifier);
            if (identifier && identifier.length > 1) {
                let puser = user?.p_user;
                let url = process.env.REACT_APP_API_URI + "/api/porttransaction?puser=" + puser + "&identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                setTransactions(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    // eslint-disable-next-line
    const fetchPorttransactionsCallback = useCallback(fetchPorttransactions, [identifier]);
    useEffect(() => {
        console.log("useEffect fetch porttransaction")
        fetchPorttransactionsCallback();
    }, [fetchPorttransactionsCallback]);

    /** Index auf alle Transaktionen zur Performancesteigerung. Dann muss nicht
     * bei jedem Rendervorgang das gesamte Array durchsucht werden 
    */
    const transactionIndex = transactions.reduce((acc, transaction) => {
        acc[formatDate(transaction.transactiondate)] = transaction;
        return acc;
    }, {});

    const findElementByTransactionDate = (index, date) => index[date] || null;

    /** Custom Layer zur Darstellung von Portfolio Transaktionen BUY, SELL, DIVIDEND */
    /** Die Anzeige eines Symbols erfolgt, wenn an dem Kursdatum eine Transaktion stattgefunden hat */
    const buyPoints = (props) => {
        // console.log(props);

        return (
            <>
                {props.points.map((point) => {
                    const transaction = findElementByTransactionDate(transactionIndex, point.data.xFormatted);
                    if (transaction && transaction.transaction === 'DIVIDEND') {
                        return (
                            <rect
                                key={`point-${point.data.x}`}
                                x={point.x - 4}
                                y={props.height - 130}
                                width="10"
                                height="10"
                                fill="#FA9C00"
                                stroke="black"
                                style={{ pointerEvents: "none" }}
                            />
                            /*  <circle
                                 key={`point-${point.data.x}`}
                                 cx={point.x}
                                 cy={props.height - 80}
                                 r={5}
                                 fill="white"
                                 stroke="white"
                                 style={{ pointerEvents: "none" }}
                             /> */
                        );
                    } else if (transaction && transaction.transaction === 'BUY') {
                        const side = 12;
                        const angle = 120;
                        const radians = (angle / 180) * Math.PI;

                        const x1 = point.x;
                        const y1 = point.y;
                        const x2 = x1 + side * Math.cos(radians - (Math.PI / 3));
                        const y2 = y1 + side * Math.sin(radians - (Math.PI / 3));
                        const x3 = x2 + side * Math.cos(radians + (Math.PI / 3));
                        const y3 = y2 + side * Math.sin(radians + (Math.PI / 3));

                        const pointsPoly = `${x1},${y1} ${x2},${y2} ${x3},${y3}`;

                        return (
                            <polygon
                                key={`point-${point.data.x}`}
                                points={pointsPoly}
                                fill="green"
                                stroke="white" />
                        );
                    } else if (transaction && transaction.transaction === 'SELL') {
                        const side = 12;
                        const angle = 120;
                        const radians = (angle / 180) * Math.PI;

                        const x1 = point.x + 6;
                        const y1 = point.y;
                        const x2 = x1 + side * Math.cos(radians);
                        const y2 = y1 + side * Math.sin(radians);
                        const x3 = x2 + side * Math.cos(radians + (2 * Math.PI / 3));
                        const y3 = y2 + side * Math.sin(radians + (2 * Math.PI / 3));

                        const pointsPoly = `${x1},${y1} ${x2},${y2} ${x3},${y3}`;

                        return (
                            <polygon
                                key={`point-${point.data.x}`}
                                points={pointsPoly}
                                fill="red"
                                stroke="white" />
                        );
                    }

                    return null;
                })}
            </>
        );
    };


    return (
        <Box id="lineChartBox" m="2px" mb={12} height="100%" width="100%">
            <Box height={40} mt={5} mr={5} ml={4} mb={1} color="inherit">
                {/* <ButtonGroup size="small" variant="outlined" aria-label="text button group" color="primary"> */}
                <Stack direction="row">
                    <Button color="primary" onClick={() => setRange(1)} variant={range === 1 ? "contained" : "text"} sx={{ display: { xs: "none", sm: "flex" } }}>5 Tage</Button>
                    <Button color="primary" onClick={() => setRange(2)} variant={range === 2 ? "contained" : "text"} sx={{ display: { xs: "none", sm: "flex" } }}>1 Mon</Button>
                    <Button color="primary" onClick={() => setRange(3)} variant={range === 3 ? "contained" : "text"} >3 Mon</Button>
                    <Button color="primary" onClick={() => setRange(4)} variant={range === 4 ? "contained" : "text"} sx={{ display: { xs: "none", sm: "flex" } }}>6 Mon</Button>
                    <Button color="primary" onClick={() => setRange(5)} variant={range === 5 ? "contained" : "text"} sx={{ display: { xs: "none", sm: "flex" } }}>YTD</Button>
                    <Button color="primary" onClick={() => setRange(6)} variant={range === 6 ? "contained" : "text"} >1 Jahr</Button>
                    <Button color="primary" onClick={() => setRange(7)} variant={range === 7 ? "contained" : "text"} >3 Jahre</Button>
                    <Button color="primary" onClick={() => setRange(8)} variant={range === 8 ? "contained" : "text"} >5 Jahre</Button>
                    <Button color="primary" onClick={() => setRange(9)} variant={range === 9 ? "contained" : "text"} sx={{ display: { xs: "none", sm: "flex" } }}>Max</Button>
                </Stack>
                {/* </ButtonGroup> */}
            </Box>
            <ResponsiveLine
                data={pricedata}
                theme={{
                    axis: {
                        domain: {
                            line: {
                                stroke: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[800],
                            }
                        },
                        legend: {
                            text: {
                                fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            }
                        },
                        ticks: {
                            line: {
                                stroke: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                                strokeWidth: 1
                            },
                            text: {
                                fill: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            }
                        }
                    },
                    grid: {
                        line: {
                            stroke: theme.palette.mode === "dark" ? colors.primary[300] : colors.primary[800],
                            strokeWidth: 0.5
                        }
                    },
                    crosshair: {
                        line: {
                            stroke: '#FA9C00',
                            strokeWidth: 1,
                            strokeOpacity: 1,
                        },
                    },
                    tooltip: {
                        container: {
                            background: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                            color: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                            fontSize: 12
                        },
                        basic: {},
                        chip: {},
                        table: {},
                        tableCell: {},
                        tableCellValue: {}
                    }
                }}
                margin={{ top: 10, right: 10, bottom: 100, left: 50 }}
                enableSlices="x"
                xScale={{
                    type: 'time',
                    format: '%d.%m.%Y',
                    useUTC: false,
                    precision: 'day',
                }}
                xFormat="time:%d.%m.%Y"
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false
                }}

                axisTop={null}
                axisRight={null}
                axisBottom={{
                    format: axisBottomFormat,
                    tickValues: tickValues,
                    legend: '',
                    legendOffset: -12,
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: -50,
                    legendPosition: 'middle'
                }}
                enablePoints={false}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                layers={[
                    'grid',
                    'markers',
                    'areas',
                    'lines',
                    'crosshair',
                    'slices',
                    'axes',
                    'points',
                    'legends',
                    buyPoints,
                ]}
                sliceTooltip={({ slice }) => {
                    return (
                        <div
                            style={{
                                background: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                                color: theme.palette.mode === "dark" ? colors.primary[100] : colors.primary[900],
                                padding: '9px 12px',
                                border: '1px solid',
                                borderColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.grey[800],
                                borderRadius: 2,
                            }}
                        >

                            {slice.points.map((point) => {
                                const transaction = findElementByTransactionDate(transactionIndex, point.data.xFormatted);
                                return (
                                    <div
                                        key={point.id}
                                        style={{
                                            padding: '5px 0',
                                        }}
                                    >
                                        <div>Datum: {point.data.xFormatted}</div>
                                        <strong>Kurs: {point.data.yFormatted}</strong>
                                        {transaction && (<div><strong>Aktion: {transaction.transaction} {transaction.price}</strong></div>)}
                                    </div>
                                );
                            })}
                        </div>
                    )
                }}
            />
        </Box>
    );


}

export default Linechart;
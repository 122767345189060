import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import ErrorPage from "./scenes/error-page";
import Login from "./scenes/login";
import Register from "./scenes/register";
import { UserContextProvider } from "./theme/userContext";

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      /*{
        path: "portfolio",
        element: <Portfolio />,
        errorElement: <ErrorPage />,
      },
        {
        path: "stocks",
        element: <Stocks />,
      },
      {
        path: "etfs",
        element: <Etfs />,
      },
      {
        path: "screener",
        element: <Screener />,
      },
      {
        path: "detailview",
        element: <Detailview />,
      },
      {
        path: "transactiondividend",
        element: <TransactionDividend />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "cronjobs",
        element: <Cronjobs />,
      }, */
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <UserContextProvider>
    <RouterProvider router={router} />
  </UserContextProvider>
  //</React.StrictMode>
);

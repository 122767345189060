import { Box, CircularProgress, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import Header from "../components/header";
import { UserContext } from "../theme/userContext";
import { AccountList } from "../components/accountList";
import FinDataGrid from "../components/findatagrid";
import { formatDate, formatDateShort, formatNumber, getCurrencySymbol } from "../misc/helperfunctions";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { tokens } from "../theme/theme";
import ConfirmDialog from "./dialogs/confirmDialog";

const Accounts = () => {

    const { user } = useContext(UserContext);
    // eslint-disable-next-line
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // for loading Icon
    const [willReload, setWillreload] = useState(false);
    // eslint-disable-next-line
    const [d30Change, setD30Change] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [totalBalanceCurrency, setTotalBalanceCurrency] = useState('EUR');
    const [accountTransactions, setAccountTransactions] = useState([]);
    const [selectedItem, setSelectedItem] = useState(() => {
        const selItem = sessionStorage.getItem('selItem');
        return selItem ? JSON.parse(selItem) : null;
    });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
        defaultMatches: true,
        noSsr: false
    });
    const [confirmOpen, setConfirmOpen] = useState(false); // TransactionDialog
    const [rowId, setRowId] = useState(null);

    async function fetchAccounts() {
        try {
            setIsLoading(true);
            let url = process.env.REACT_APP_API_URI + "/api/accounts?puser=" + user.p_user;
            const response = await fetch(url);
            const data = await response.json();
            setData(data.accounts);
            setTotalBalance(data.totalBalance);
            setTotalBalanceCurrency(data.totalBalanceCurrency);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    async function fetchAccounttransactions() {
        try {
            setIsLoading(true);
            let url = process.env.REACT_APP_API_URI + "/api/accounttransactions?puser=" + user.p_user + "&account_id=" + selectedItem?.id;
            const response = await fetch(url);
            const data = await response.json();
            console.log("fetchAccounttransactions " + data.length);
            setAccountTransactions(data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {

        console.log("useEffect accounts");
        fetchAccounts();

        // eslint-disable-next-line
    }, [willReload]);

    useEffect(() => {
        if (selectedItem) {
            console.log("useEffect accounttransactions");
            fetchAccounttransactions();
        }
        // eslint-disable-next-line
    }, [selectedItem, willReload]);


    function onSelectItem(item) {
        console.log("onSelectItem " + item.name);
        setSelectedItem(item);
        sessionStorage.setItem('selItem', JSON.stringify(item));
    }

    const deleteTransactionConfirm = (id) => {
        console.log("Porttransactions will delete transaction with id: " + id + " open ConfirmDialog")
        setRowId(id);
        setConfirmOpen(true);
    };

    async function deleteAccountApi() {
        console.log("Accounttransaction will delete: " + rowId)
        try {
            if (rowId) {
                let url = process.env.REACT_APP_API_URI + "/api/deleteaccounttransaction";

                await fetch(url, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: rowId,
                        puser: user?.p_user,
                    }),
                })
                    .then((response) => response.json())
                    .then(() => {
                        console.log("Changes saved");
                    })
                    .catch((error) => {
                        console.error(error);
                        return false;
                    })

                // Datensatz gelöscht
                setRowId(null);
                setWillreload(true);

            }
            setWillreload(true);
        } catch (error) {
            console.error(error);
        }
    }


    const columns = [
        //  { field: 'id', headerName: 'ID', width: 70 },
        {
            field: "transactiondate",
            headerName: "Datum",
            width: 120,
            headerAlign: "left",
            type: "date",
            valueFormatter: (params) => {
                const valueFormatted = formatDate(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "transaction_text",
            headerName: "Text",
            width: 400,
            align: "left",
            headerAlign: "left",
            cellClassName: 'word-wrap'
        },
        {
            field: "amount",
            headerName: "Betrag",
            width: 120,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                const val = formatNumber(params.value) + " €";
                return (
                    <div style={{ textAlign: 'right' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{val}</Typography>
                    </div>
                );
            }
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: (params) => {
                return ([
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        //  disabled={params.row.transaction_type === "BUY" || params.row.transaction_type === "SELL" || params.row.transaction_type === "DIVIDEND"}
                        component={Link}
                        to={{
                            pathname: "/accounttransaction",
                        }}
                        state={{ editMode: true, accountransactionid: params.id }}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        //  disabled={params.row.transaction_type === "BUY" || params.row.transaction_type === "SELL" || params.row.transaction_type === "DIVIDEND"}
                        onClick={() => deleteTransactionConfirm(params.id)}
                        showInMenu
                    />,
                ]);
            },
        }

    ];

    const mobilColumns = [
        //  { field: 'id', headerName: 'ID', width: 70 },
        {
            field: "transactiondate",
            headerName: "Datum",
            width: 80,
            headerAlign: "left",
            type: "date",
            renderCell: (params) => {
                const val = params.value;
                const valueFormatted = formatDateShort(params.value);
                return (
                    <div style={{ textAlign: 'left' }}>

                        <Stack>
                            <Stack direction="row" minWidth={200} justifyContent="space-between">
                                <Typography variant="h7" sx={{ fontWeight: 'bold' }} noWrap>{valueFormatted}</Typography>
                            </Stack>
                            <Typography variant="h7" minHeight={14} noWrap>{false ? val : " "}</Typography>
                        </Stack>
                    </div>
                );
            }
        },
        {
            field: "amount",
            headerName: "Betrag",
            width: 220,
            renderCell: (params) => {
                const val = formatNumber(params.value) + " €";
                return (
                    <Stack>
                        <Stack direction="row" minWidth={200} justifyContent="space-between">
                            <Typography variant="h7" sx={{ fontWeight: 'bold' }} noWrap>{params.row.transaction_type}</Typography>
                            <Typography variant="h7" sx={{ fontWeight: 'bold' }} noWrap>{val}</Typography>
                        </Stack>
                        <Typography variant="h7" maxWidth={200} noWrap>{params.row.transaction_text}</Typography>
                    </Stack>
                );
            }
        },
        {
            field: 'actions',
            type: 'actions',
            width: 40,
            getActions: (params) => {
                return ([
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        //disabled={params.row.transaction_type === "BUY" || params.row.transaction_type === "SELL" || params.row.transaction_type === "DIVIDEND"}
                        component={Link}
                        to={{
                            pathname: "/accounttransaction",
                        }}
                        state={{ editMode: true, accountransactionid: params.id }}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        //disabled={params.row.transaction_type === "BUY" || params.row.transaction_type === "SELL" || params.row.transaction_type === "DIVIDEND"}
                        onClick={() => deleteTransactionConfirm(params.id)}
                        showInMenu
                    />,
                ]);
            },
        }

    ];

    const Smartbox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        borderRadius: "8px",
        padding: "10px",
        paddingBottom: "20px",
    }));

    return (
        <Box
            id="dashboardMainBox"
            m="20px"
            sx={{
                "& .word-wrap": {
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                },
            }}
        >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
                <Grid item xs={3} sm={2}>
                    <Header title="CASH" subtitle="Verrechnungskonten" />
                </Grid>
                <Grid item xs={1} >
                    <Box display="flex" justifyContent="center" ml={4} >
                        {isLoading && (
                            <CircularProgress color="inherit" />
                        )}
                        {(!isLoading) && (
                            <IconButton ml={2} size="large" aria-label="refresh" onClick={setWillreload}>
                                <RefreshIcon fontSize="large" />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={8} >
                </Grid>
                <Grid item xs={4} mt={-2}>
                    <Smartbox>
                        <Typography variant="h5" mt={1} ml={2}>Cash gesamt:</Typography>
                        <Typography variant="h5" mt={1} ml={2} sx={{ fontWeight: 'bold' }}>{formatNumber(totalBalance)} {getCurrencySymbol(totalBalanceCurrency)}</Typography>
                    </Smartbox>
                </Grid>
                <Grid item xs={8} >
                </Grid>
                <Grid item xs={4} mt={-1}>
                    <AccountList mainViewWillReload={willReload} onSelectItem={onSelectItem} />
                </Grid>
                <Grid item xs={8} >
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="h4">
                        Umsätze {selectedItem?.name}
                    </Typography>
                    <FinDataGrid
                        id="gridAccountTransactions"
                        autoHeight
                        density={smUp ? "compact" : "standard"}
                        pageSize={25}
                        // rowHeight={60}
                        headerHeight={smUp ? 60 : 0}
                        rows={accountTransactions}
                        columns={smUp ? columns : mobilColumns}
                    />
                </Grid>
            </Grid>
            <ConfirmDialog
                title="Delete transaction?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteAccountApi}
            >
                Soll die Transaktion wirklich gelöscht werden?
            </ConfirmDialog>
        </Box>

    )

}

export default Accounts
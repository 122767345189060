import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, Stack, Typography } from "@mui/material";
import TextInput from "../components/textInput";
import updateUserData from "../misc/userProvider";
import { UserContext } from "../theme/userContext";
import { handleSignOut } from "../theme/firebase";
import { useNavigate } from "react-router-dom";

const Settings = () => {

    const { user, onLogout } = useContext(UserContext);
    const [tableDensity, setTableDensity] = useState('compact');
    const [mode, setMode] = useState('dark');
    const [notifications, setNotifications] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.settings) {
            setTableDensity(user.settings.tableDensity);
            setMode(user.settings.mode);
            setNotifications(user.settings.notifications || false);
        }
        // eslint-disable-next-line
    }, [user]);

    // Close view
    const handleClose = () => {
        navigate(-1);
    };

    const handleSave = () => {

        console.log("Settings handleSave()... ");
        const userData = { ...user, settings: { ...user.settings, "tableDensity": tableDensity, "mode": mode, "notifications": notifications } };
        updateUserData(userData);

        if (handleSignOut()) {
            console.log("user signed out");
            onLogout();
            navigate('/login');
        }
    };

    return (
        <Box id="portTableMainBox" m="20px" >
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4}>
                    <Typography variant="h2">Settings</Typography>
                </Grid>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant="h5">{user.email}</Typography>
                </Grid>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4} mt={4}>
                    <TextInput
                        id="typeMode"
                        value={mode}
                        label='Mode (Dark/Light)'
                        name="selModeType"
                        fullWidth
                        select
                        onChange={(e) => setMode(e.target.value)}
                    >
                        <MenuItem value="dark">Dark</MenuItem>
                        <MenuItem value="light">Light</MenuItem>
                    </TextInput>
                    <Grid item xs={4} minHeight={12}>

                    </Grid>
                    <TextInput
                        id="typeSelect"
                        value={tableDensity}
                        label='Tabellengröße'
                        name="selDensityType"
                        fullWidth
                        select
                        onChange={(e) => setTableDensity(e.target.value)}
                    >
                        <MenuItem value="compact">Compact</MenuItem>
                        <MenuItem value="standard">Standard</MenuItem>
                        <MenuItem value="comfortable">Comfortable</MenuItem>
                    </TextInput>

                </Grid>
                <Grid item xs={8}>

                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <FormControlLabel control={<Checkbox
                            checked={notifications}
                            onChange={(e) => setNotifications(e.target.checked)}
                            inputProps={{ 'aria-label': 'notifications checkbox' }}
                        />} label="Mail Benachrichtigungen" />
                        <Typography variant="h7">Tägliche Mail mit Angaben zu Dividendenzahlungen und Insiderkäufen.</Typography>
                    </Stack>
                </Grid>

                <Grid item xs={8}>

                </Grid>

                <Grid item xs={4} mt={4}>
                    <Stack direction="row" alignItems="center" justifyContent='space-between'>
                        <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
                        <Typography mr={1} ml={1} variant="h7" textAlign="right">Nach dem Speichern werden Sie automatisch abgemeldet.</Typography>
                        <Button ml={2} variant="contained" onClick={handleSave} color="success">Speichern</Button>
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                </Grid>

            </Grid>
        </Box>
    )
}

export default Settings


import {
    Box,
    InputBase,
    IconButton,
    Stack,
    Typography,
    useTheme,
  } from "@mui/material";
  import { DataGrid } from "@mui/x-data-grid";
  import { tokens } from "../theme/theme";
  import { mockDataTeam } from "../data/mockData";
  import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
  import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
  import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
  import Header from "../components/header";
  import SearchIcon from "@mui/icons-material/Search";
  
  const Users = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const columns = [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "Name",
        flex: 1,
        cellClassName: "name-column--cell",
      },
      {
        field: "age",
        headerName: "Age",
        type: "number",
        headerAlign: "left",
        align: "left",
      },
      {
        field: "phone",
        headerName: "Phone Number",
        flex: 1,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
      },
      {
        field: "accessLevel",
        headerName: "Access Level",
        flex: 1,
        renderCell: ({ row: { access } }) => {
          return (
            <Box
              width="60%"
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={
                access === "admin"
                  ? colors.greenAccent[600]
                  : access === "manager"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
              }
              borderRadius="4px"
            >
              {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
              {access === "manager" && <SecurityOutlinedIcon />}
              {access === "user" && <LockOpenOutlinedIcon />}
              <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                {access}
              </Typography>
            </Box>
          );
        },
      },
    ];
  
    return (
      <Box id="stockMainBox" m="10px" width="75%" height="100%">
        <Stack id="stackHeader" direction="row" display="flex">
          <Header title="USERS" subtitle="Fiktive Users MOCK Tabelle..." />
          <Box id="helperBox" height="80px" width="100px"  > </Box>
          <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
            width="40%"
            height="40px"
            marginTop="26px"
            padding= "0px 10px"
          >
            <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
            <IconButton type="button" sx={{ p: 1 }}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Stack>
        <Box
          id="tableBox"
          m="2px 0 0 0"
          height="75vh"
          display="flex"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-cell:hover": {
              color: colors.blueAccent[200],
            },
          }}
        >
          <DataGrid
            sx={{ typography: "body1" }}
            checkboxSelection
            rows={mockDataTeam}
            columns={columns}
          />
        </Box>
      </Box>
    );
  };
  
  export default Users;
  
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, useTheme
} from "@mui/material";
import { useContext, useState } from "react";
import { handleSignOut } from "../theme/firebase";
import { ColorModeContext, tokens } from "../theme/theme";
import { UserContext } from "../theme/userContext";
import Logout from '@mui/icons-material/Logout';
import updateUserData from "../misc/userProvider";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddTaskIcon from '@mui/icons-material/AddTask';


const Navbar = (props) => {
  const { user, onLogout } = useContext(UserContext);
  const { onSidebarOpen } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElAdd, setAnchorElAdd] = useState(null);
  const openAdd = Boolean(anchorElAdd);
  const handleClickAdd = (event) => {
    setAnchorElAdd(event.currentTarget);
  };
  const handleCloseAdd = () => {
    setAnchorElAdd(null);
  };

  const handleToggleCloroMode = () => {
    colorMode.toggleColorMode();
    user.settings.mode = theme.palette.mode === 'dark' ? 'light' : 'dark';
    const userData = { ...user };
    updateUserData(userData);
  };


  return (
    <Stack
      id="stack"
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      backgroundColor={colors.primary[400]}
      color={colors.primary[100]}
      sx={{
        position: 'sticky',
        top: 0,
        minHeight: 60,
        left: 0,
        px: 2,
        boxShadow: 2,
        zIndex: 90,
      }}
    >
      <Box
        id="leftButtonBox"
        alignItems="center"
        marginLeft="20px"
        display="flex"
      >
        <IconButton edge="start" aria-label="menu" color="inherit" onClick={onSidebarOpen} sx={{ display: { xs: "inline-flex", lg: "none" } }}>
          <MenuIcon href="/" />
        </IconButton>
      </Box>
      <Box id="buttonBox" alignItems="center" display="flex">
      </Box>
      <Box id="rightButtonBox" display="flex" marginRight="0px">
        <IconButton color="inherit" onClick={handleClickAdd}>
          <AddTaskIcon />
        </IconButton>
        <Menu
          id="add-menu"
          anchorEl={anchorElAdd}
          open={openAdd}
          onClose={handleCloseAdd}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            component={Link}
            to={{
              pathname: "/transactiondividend",
            }}
            state={{ type: 'BUY' }}
            onClick={handleCloseAdd}
          >
            <ListItemText>Kauf</ListItemText>
            <ListItemIcon>
              <AddCircleOutlineIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem component={Link}
            to={{
              pathname: "/transactiondividend",
            }}
            state={{ type: 'SELL' }}
            onClick={handleCloseAdd}
          >
            <ListItemText>Verkauf</ListItemText>
            <ListItemIcon>
              <RemoveCircleOutlineIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
          <MenuItem component={Link}
            to={{
              pathname: "/transactiondividend",
            }}
            state={{ type: 'DIVIDEND' }}
            onClick={handleCloseAdd}
          >
            <ListItemText>Dividende</ListItemText>
            <ListItemIcon>
              <AttachMoneyIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </Menu>
        <IconButton onClick={handleToggleCloroMode} color="inherit">
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton color="inherit" disabled>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton color="inherit"
          component={Link}
          to={{
            pathname: "/settings",
          }}>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton color="inherit" onClick={handleClick}>
          <PersonOutlinedIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => {
            if (handleSignOut()) {
              console.log("user signed out");
              onLogout();
              handleClose();
            } else {
              // ERROR
              handleClose();
            }
          }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Abmelden</MenuItem>
        </Menu>
      </Box>
    </Stack >
  );
};

export default Navbar;

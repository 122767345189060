import React, { useContext } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { UserContext } from '../../theme/userContext';
import CurrencyInput from '../../components/currencyInput';


export default function AccountTransactionDialog(props) {
    const { open, onClose, accounts } = props;
    const { user } = useContext(UserContext);
    // eslint-disable-next-line no-unused-vars
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();

    const handleClose = () => {
        onClose();
    };

    async function upsertAccountTransaction(data) {

        let url = process.env.REACT_APP_API_URI + "/api/createaccounttransaction";

        //  const { accountName, puser, transId, accountCurrency, transactionDate, transactionType, transactionText, amount, description } = transactionData;


        await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ///get account name from account id    
                accountName: accounts.find(account => account.id === data.account_id).name,
                puser: user.p_user,
                transId: null,
                accountCurrency: data.currency,
                transactionDate: new Date(), //FIXME
                transactionType: data.type,
                amount: parseFloat(data.amount),
                description: data.description,
                transactionText: 'FIXME',
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then((error) => {
                        throw new Error(error.message);
                    });
                }
                return response.json();
            })
            .then((data) => {
                console.log("Changes saved");
            })
            .catch((error) => {
                console.error(error.message);
                throw new Error(error.message);
            });

    }

    const onSubmit = async (data) => {
        console.log(data);
        await upsertAccountTransaction(data);
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Neue Kontobuchung</DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <DialogContentText>
                        Bitte geben Sie die Details der Kontobuchung ein.
                    </DialogContentText>
                    <FormControl error={errors.account_id} fullWidth margin="dense">
                        <InputLabel id="account-label">Konto</InputLabel>
                        <Select
                            labelId="account-label"
                            id="account-select"
                            name="account_id"
                            fullWidth
                            defaultValue=""
                            inputProps={{
                                ...register("account_id", { required: true }),
                            }}
                        >
                            {accounts.map((account) => (
                                <MenuItem key={account.id} value={account.id}>{account.name}</MenuItem>
                            ))}
                        </Select>
                        {errors.account_id && <span>Konto ist ein Pflichtfeld</span>}
                    </FormControl>
                    <FormControl error={errors.type} fullWidth margin="dense">
                        <InputLabel id="type-label">Typ</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type-select"
                            name="type"
                            fullWidth
                            defaultValue=""
                            inputProps={{
                                ...register("type", { required: true }),
                            }}
                        >
                            <MenuItem value="INCOME">Einnahme</MenuItem>
                            <MenuItem value="EXPENSE">Ausgabe</MenuItem>
                        </Select>
                        {errors.type && <span>Typ ist ein Pflichtfeld</span>}
                    </FormControl>
                    <TextField
                        label="Währung"
                        name="currency"
                        fullWidth
                        margin="dense"
                        inputProps={{
                            ...register("currency", { required: true }),
                        }}
                        error={errors.currency}
                        helperText={errors.currency && "Währung ist ein Pflichtfeld"}
                    />
                    <TextField
                        label="Betrag"
                        name="amount"
                        fullWidth
                        margin="dense"
                        inputProps={{
                            ...register("amount", { required: true }),
                        }}
                        error={errors.amount}
                        helperText={errors.amount && "Betrag ist ein Pflichtfeld"}
                    />
                    <Controller
                        name="price"
                        control={control}
                        // defaultValue={price}
                        rules={{ required: true, pattern: /^[0-9,\\.]+$/ }}
                        render={({ field }) => <CurrencyInput id="curInput"
                            {...field}
                            name="price"
                            currencyName="currency"
                            label='label'
                            value={field.value}
                            handleValueChange={(e) => {
                                // setPrice(e.target.value);
                                field.onChange(e);
                            }}
                            // onKeyDown={handleKeyDown}
                            // disabled={!divDialog[0]}
                            currencySelect="true"
                            currency='EUR'
                            //  handleCurrencyChange={handleChange}
                            error={Boolean(errors.price)}
                            helpertext={errors.price && "Enter dividend per share."}
                        />}
                    />
                    <TextField
                        label="Beschreibung"
                        name="description"
                        fullWidth
                        margin="dense"
                        inputProps={{
                            ...register("description", { required: true }),
                        }}
                        error={errors.description}
                        helperText={errors.description && "Beschreibung ist ein Pflichtfeld"}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Abbrechen
                    </Button>
                    <Button type="submit" color="primary">
                        Speichern
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

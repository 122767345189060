import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Alert, Box, Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, useMediaQuery } from "@mui/material";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { formatDate, formatMuiDate, formatNumber } from "../../misc/helperfunctions";
import { tokens } from "../../theme/theme";
import { useLocation, useNavigate } from "react-router-dom";
import CurrencyInput from "../../components/currencyInput";
import TextInput from "../../components/textInput";
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from "../../theme/userContext";
import CompanyAvatar from "../../components/CompanyAvatar";
import FormLabel from "../../components/formLabel";
import SearchAutocomplete from "../../components/searchAutocomplete";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import HelpText from "../../components/helpText";

function TransactionDividend() {

    const { user } = useContext(UserContext);
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm();
    const location = useLocation();
    const { state } = location;
    let startidentifier, portfoliotransactionid, type, editMode, divDatas;
    if (state) {
        startidentifier = state.identifier;
        portfoliotransactionid = state.portfoliotransactionid;
        editMode = state.editMode;
        type = state.type;
        divDatas = state.divDatas;
    }

    const navigate = useNavigate();
    const [identifier, setIdentifier] = useState(startidentifier);
    const [transactionType, setTransactionType] = useState(type ? type : 'BUY');
    // eslint-disable-next-line
    const [isEditMode, setIsEditMode] = useState(editMode ? editMode : false);
    const headerText = isEditMode ? 'Transaktion bearbeiten...' : 'Transaktion eingeben...';
    const [errorMessage, setErrorMessage] = useState('');
    const [divDialog, setDivDialog] = useState({});
    const [infoalert, setInfoalert] = useState(false); // SearchDialog
    const [infoText, setInfoText] = useState(''); // SearchDialog
    const [rateText, setRateText] = useState('Wechselkurs');
    const [priceText, setPriceText] = useState('Kurs');
    const [sumText, setSumText] = useState('Kurs');
    const [account, setAccount] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const theme = useTheme();

    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState('EUR');
    // eslint-disable-next-line
    const [quantity, setQuantity] = useState(0);
    const [quantityPortfolio, setQuantityPortfolio] = useState(0);
    const [averagePrice, setAveragePrice] = useState(0);
    const [realizedCosts, setRealizedCosts] = useState(0);
    const [realizedGains, setRealizedGains] = useState(0);
    const [grossAmount, setGrossAmount] = useState(0);
    const [netAmount, setNetAmount] = useState(0);
    const [grossAmountForeign, setGrossAmountForeign] = useState(0);
    // eslint-disable-next-line 
    const [taxForeign, setTaxForeign] = useState(0);
    const [tax, setTax] = useState(0);
    const [transactionDate, setTransactionDate] = useState(moment(formatMuiDate(new Date())));
    const [rate, setRate] = useState(1);
    const [notes, setNotes] = useState('');
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
        defaultMatches: true,
        noSsr: false
    });
    const [bookToAccount, setBookToAccount] = useState(true);

    // eslint-disable-next-line
    const colors = tokens(theme.palette.mode);

    // Fetch Portfolio for Edit-Mode
    async function fetchPorttransaction() {
        try {
            if (isEditMode) {
                console.log("EDIT MODUS: Load: " + portfoliotransactionid);
                let url = process.env.REACT_APP_API_URI + "/api/porttransactionsbyid?id=" + portfoliotransactionid;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);

                if (data[0]) {
                    setTransactionType(data[0].transaction);
                    setPrice(data[0].price);
                    setValue("price", data[0].price);
                    setCurrency(data[0].currencycode);
                    setTransactionDate(moment(data[0].transactiondate));
                    setTax(data[0].tax);
                    setValue("tax", data[0].tax);
                    setTaxForeign(data[0].tax_foreign);
                    setValue("taxForeign", data[0].tax_foreign);
                    setRate(data[0].exchange_rate);
                    setValue("rate", data[0].exchange_rate);
                    setQuantity(data[0].quantity);
                    setValue("quantity", data[0].quantity);
                    setNotes(data[0].description);
                    setValue("notes", data[0].description);
                    setBookToAccount(false);
                    setRealizedGains(data[0].realized_gains);
                    setRealizedCosts(data[0].realized_costs);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    // eslint-disable-next-line
    const fetchPorttransactionCallback = useCallback(fetchPorttransaction, [portfoliotransactionid]);
    useEffect(() => {
        console.log("useEffect TransactionAction fetchPorttransactionCallback")
        fetchPorttransactionCallback();
    }, [fetchPorttransactionCallback]);


    // Fetch Accounts
    async function fetchAccounts() {
        try {
            let puser = user?.p_user;
            let url = process.env.REACT_APP_API_URI + "/api/accounts?puser=" + puser;
            const response = await fetch(url);
            const data = await response.json();

            setAccounts(data.accounts);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setAccounts([]);
        }
    }

    // Fetch Dialog Datas: get last dividend payment and fill the fields
    async function fetchPortfolio() {
        try {
            if (identifier && identifier.length > 1) {
                let url = process.env.REACT_APP_API_URI + "/api/divdialog?identifier=" + identifier + "&puser=" + user.p_user;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);
                setDivDialog(data);

                // Save Bestand
                setQuantityPortfolio(data[0].quantity);
                setAveragePrice(data[0].average_price);

                if (!isEditMode && data[0]) {

                    if (data[0].account) {
                        console.log("Account: " + data[0].account);
                        setAccount(data[0].account);
                        setValue("account", data[0].account);
                    }

                    if (transactionType === 'DIVIDEND' && data[0].value) {

                        // divDatas werden vom UseCase "Dividenden buchen" übergeben
                        // in diesem Falle sollen nicht alle Daten der letzten Ausschüttung
                        // verwendet werden 
                        if (divDatas) {
                            setTransactionDate(moment(divDatas.transactionDate));
                            setPrice(divDatas.pershare);
                            setValue("price", divDatas.pershare);
                            setQuantity(divDatas.quantity);
                            setValue("quantity", divDatas.quantity);
                        } else {

                            setPrice(data[0].value);
                            setValue("price", data[0].value);
                            setQuantity(data[0].quantity);
                            setValue("quantity", data[0].quantity);
                            setTransactionDate(moment(data[0].paymentdate));
                            setInfoText('Alle Werte der letzten Ausschüttung vom  ' + formatDate(data[0].paymentdate) + ' übernommen.');
                            setInfoalert(true);
                        }

                        setCurrency(data[0].currency ? data[0].currency : 'EUR');

                        // Tax berechnen
                        let numTaxForeign = 0;
                        let numTax = (data[0].quantity * data[0].value - (data[0].quantity * data[0].value * 0.74)).toFixed(2); // FIXME Steuersatz
                        if (data[0].currency !== 'EUR') {
                            const sum = parseFloat((data[0].quantity * data[0].value).toFixed(2));
                            numTaxForeign = (sum * 0.15).toFixed(2); // FIXME Steuersatz 15% Quellensteuer
                            numTax = (sum * 0.09).toFixed(2); // FIXME Steuersatz
                            //console.log(sum + "  " + tax_foreign + " " + tax);
                        }
                        setTax(numTax);
                        setValue("tax", numTax);
                        setTaxForeign(numTaxForeign);
                        setValue("taxForeign", numTaxForeign);

                    } else if (transactionType === 'BUY' || transactionType === 'SELL') {
                        setPrice(data[0].lastprice);
                        setValue("price", data[0].lastprice ? data[0].lastprice : 0);
                        setCurrency(data[0].pricecurrency ? data[0].pricecurrency : 'EUR');
                        setQuantity(0);
                        setValue("quantity", 0);
                        setTransactionDate(moment(new Date()));
                        setTax(0);
                        setValue("tax", 0);
                        setTaxForeign(0);
                        setValue("taxForeign", 0);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    // eslint-disable-next-line
    const fetchPortfolioCallback = useCallback(fetchPortfolio, [identifier, transactionType]);
    // Load the security after identifier has changed
    useEffect(() => {
        console.log("useEffect fetchPortfolioCallback")
        fetchPortfolioCallback();
        // Hide the InfoPanel after 5 seconds...
        const interval = setInterval(() => {
            setInfoalert(false);
            clearInterval(interval);
        }, 5 * 1000);
        return () => {
            clearInterval(interval);
        }
    }, [fetchPortfolioCallback]);

    // Fetch last price of currency rate
    async function fetchPriceOfCurrency() {
        try {
            console.log("fetchPriceOfCurrency");
            if (!isEditMode && identifier && identifier.length > 1) {
                if (currency === 'EUR') {
                    setRate(1);
                    setValue("rate", 1);
                } else {
                    const date = formatMuiDate(transactionDate);
                    // Wechselkurs von API holen...    
                    const curId = currency + 'EUR.FOREX';
                    let url = process.env.REACT_APP_API_URI + "/api/pricebydate?identifier=" + curId + "&filterdate=" + date;
                    const response = await fetch(url);
                    const data = await response.json();
                    console.log(data);

                    if (data && data.price) {
                        console.log("Set Rate: " + data.price + " " + data.currency);
                        setRateText('Wechselkurs EUR/' + currency);
                        setRate(data.price);
                        setValue("rate", data.price);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    async function fetchPrice() {
        try {
            console.log("fetchPrice");
            if (!isEditMode && identifier && identifier.length > 1) {

                const date = formatMuiDate(transactionDate);
                // Wechselkurs von API holen...    
                let url = process.env.REACT_APP_API_URI + "/api/pricebydate?identifier=" + identifier + "&filterdate=" + date;
                const response = await fetch(url);
                const data = await response.json();
                console.log(data);

                if (data && data.price && transactionType !== 'DIVIDEND') {
                    console.log("Set price: " + data.price + " " + identifier);
                    setPrice(data.price);
                    setValue("price", data.price ? data.price : 0);
                }

            }
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        console.log("TransactionAction fetchPriceOfCurrency " + identifier)
        fetchPriceOfCurrency();
        fetchPrice();
        // eslint-disable-next-line
    }, [currency, transactionDate]);


    useEffect(() => {
        setIsLoading(true);
        fetchAccounts();
        const nextInput = document.querySelector(`[name=quantity]`);
        if (nextInput) {
            console.log("nextInput.focus(); 1");
            nextInput.focus();
        }
        // eslint-disable-next-line
    }, []); // <-- empty array means 'run once'


    // Change transaction Type
    useEffect(() => {
        console.log("useEffect transactionType..." + transactionType);
        if (transactionType === 'BUY') {
            setPriceText('Kurs');
            setSumText('Kosten gesamt');
            setInfoalert(false);
            const nextInput = document.querySelector(`[name=quantity]`);
            if (nextInput) {
                console.log("nextInput.focus(); 2");
                nextInput.focus();
            }
        } else if (transactionType === 'SELL') {
            setPriceText('Kurs');
            setSumText('Erlöse netto gesamt');
            setInfoalert(false);
            const nextInput = document.querySelector(`[name=quantity]`);
            if (nextInput) {
                nextInput.focus();
            }
        } else if (transactionType === 'DIVIDEND') {
            setPriceText('Ausschüttung');
            setSumText('Auszahlung netto');
        }
        // eslint-disable-next-line
    }, [transactionType]);


    // useeffect for calculate the total costs amount
    useEffect(() => {
        // caculate total price
        console.log("Calculation Start...");
        var numQuantity = quantity;
        var numTax = tax;
        var numTaxForeign = taxForeign;
        var numPrice = price;
        var numRate = rate;

        let totalForeign = 0;
        let totalEUR = 0;
        let totalNet = 0;
        let numrealizedGains = 0;
        let numrealizedCosts = 0;

        if (transactionType === 'DIVIDEND') {
            totalForeign = numQuantity * numPrice;
            totalEUR = numQuantity * numPrice * numRate;
            totalNet = (numQuantity * numPrice * numRate) - parseFloat(numTax) - (numTaxForeign * numRate);
        } else if (transactionType === 'BUY') {
            totalForeign = numQuantity * numPrice;
            totalEUR = numQuantity * numPrice * numRate;
            totalNet = (numQuantity * numPrice * numRate) + parseFloat(numTax) + (numTaxForeign * numRate);
        } else if (transactionType === 'SELL') {
            totalForeign = numQuantity * numPrice;
            totalEUR = numQuantity * numPrice * numRate;
            totalNet = (numQuantity * numPrice * numRate) - parseFloat(numTax) - (numTaxForeign * numRate);

            // Calculate realized gains
            // realizedCosts = numQuantity * avgPrice;
            numrealizedCosts = numQuantity * averagePrice; // in EUR
            numrealizedGains = totalEUR - numrealizedCosts;
        }

        setGrossAmount(formatNumber(totalEUR));
        setGrossAmountForeign(formatNumber(totalForeign));
        setNetAmount(formatNumber(totalNet));
        setRealizedGains(numrealizedGains);
        setRealizedCosts(numrealizedCosts);

        // FIXME calculate Tax

        console.log("Calculation: totalNet = " + totalNet + " " + numTax + " " + taxForeign + " " + totalEUR);
        // eslint-disable-next-line
    }, [price, rate, tax, taxForeign, quantity]);

    // Fired on each change like a key press
    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'price') {
            setPrice(value);
        } else if (name === 'currency') {
            setCurrency(value);
        } else if (name === 'transactionDate') {
            setTransactionDate(value);
        } else if (name === 'quantity') {
            setQuantity(value);
        } else if (name === 'rate') {
            setRate(value);
        } else if (name === 'tax') {
            setTax(value);
        } else if (name === 'taxForeign') {
            setTaxForeign(value);
        } else if (name === 'notes') {
            setNotes(value);
        } else if (name === 'selTransactionType') {
            setTransactionType(value);
        } else if (name === 'selAccount') {
            setAccount(value);
        }
    }

    // Focus next Input Element on Key Enter
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    const onSubmit = async (data) => {
        console.log("onSubmit");
        console.log(data);
        try {
            await handleSave();
            handleClose();
        } catch (error) {
            console.error(error.message);
        }

    };


    // Save transaction to databas
    async function handleSave() {
        let isError = false;
        let url = process.env.REACT_APP_API_URI + "/api/createporttransaction";
        if (portfoliotransactionid) {
            // Update Modus
            url = process.env.REACT_APP_API_URI + "/api/updateporttransaction";
        }

        await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: portfoliotransactionid,
                puser: user?.p_user,
                identifier: identifier,
                transactiondate: transactionDate,
                transaction: transactionType,
                price: price,
                tax: tax ? tax : 0,
                currencycode: currency,
                exchange_rate: rate,
                quantity: quantity,
                description: notes,
                tax_foreign: taxForeign ? taxForeign : 0,
                account: account,
                bookToAccount: bookToAccount,
                realizedCosts: realizedCosts,
                realizedGains: realizedGains,
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then((error) => {
                        throw new Error(error.message);
                    });
                }
                return response.json();
            })
            .then((data) => {
                console.log("Changes saved");
            })
            .catch((error) => {
                console.error(error.message);
                setErrorMessage(error.message);
                isError = true;
                throw new Error(error.message);
            });

        if (!isError) {
            setErrorMessage(null);
        }

    };

    // Close view
    const handleClose = () => {
        navigate(-1);
    };

    const onPressQLink = () => {
        setQuantity(quantityPortfolio);
        setValue('quantity', quantityPortfolio);
    };

    const handleAutocompleteChange = (event, value) => {
        if (value) {
            console.log("handleAutocompleteChange: " + value.identifier)
            setIdentifier(value.identifier);
        } else {
            setIdentifier("");
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Box id="portTableMainBox" m="20px"  >
            <Box
                marginTop={2}
                padding={0}
                border={theme.palette.mode === "dark" ? 0 : 0}
                borderRadius="8px"
                backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
            >
                <form onSubmit={handleSubmit(onSubmit)} >
                    <DialogTitle fontSize={24}>
                        <Stack direction="row" spacing={4} alignItems="center" minHeight={46}>
                            <Typography variant="h3">{smUp ? headerText : "Aktion..."}</Typography>
                            <Typography color="error" variant="h5">{errorMessage ? errorMessage : ''}</Typography>
                            <Box maxHeight={40}>
                                <Collapse in={infoalert}>
                                    <Alert variant="filled"
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    setInfoalert(false);
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                        sx={{ mt: 0 }}
                                    >
                                        {infoText}
                                    </Alert>
                                </Collapse>
                            </Box>
                        </Stack>

                    </DialogTitle>
                    <DialogContent dividers>

                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                            maxWidth="800px"
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center">

                            {!identifier && (<Grid item xs={12}>
                                <SearchAutocomplete handleAutocompleteChange={handleAutocompleteChange} />
                            </Grid>)}
                            {identifier && (<Grid item xs={3} sm={6} md={10}>
                                <Stack direction="row" spacing={0} alignItems="center" marginTop={0}>
                                    <CompanyAvatar identifier={identifier ? identifier : '???'} alt={'Company Logo'}
                                        sx={{
                                            marginLeft: 0,
                                            height: 80,
                                            width: 80
                                        }}
                                    >
                                    </CompanyAvatar>
                                    <Stack>
                                        <Typography marginLeft={2} variant="h4" color={colors.greenAccent[300]} >{divDialog.length > 0 ? divDialog[0].name : "Wertpapier eingeben..."}</Typography>
                                        <Stack direction="row" marginLeft={2} spacing={2}>
                                            <Typography variant="body1">{(divDialog.length > 0 && divDialog[0].isin) ? divDialog[0].isin : ''}</Typography>
                                            <Typography variant="body1">{identifier ? identifier : ''}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>)}
                            {identifier && (<Grid item xs={1} sm={2}>
                                <Stack direction="row" spacing={0} alignItems={"center"} minHeight={46} justifyContent={"flex-end"}>
                                    <Button component="a" disabled={isEditMode} sx={{ p: 0, minWidth: 0 }} onClick={(event) => {
                                        setIdentifier(null);
                                        setDivDialog({});
                                    }} >
                                        Ändern
                                    </Button>
                                </Stack>
                            </Grid>)}
                            <Grid item xs={4}>
                                <TextInput
                                    id="typeSelect"
                                    value={transactionType}
                                    label='Typ'
                                    name="selTransactionType"
                                    fullWidth
                                    select
                                    onChange={handleChange}
                                    disabled={isEditMode || !divDialog[0]}
                                >
                                    <MenuItem value="BUY">KAUF</MenuItem>
                                    <MenuItem value="SELL">VERKAUF</MenuItem>
                                    <MenuItem value="DIVIDEND">DIVIDENDE</MenuItem>
                                </TextInput>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl>
                                    <Typography variant='body1'>Datum</Typography>
                                    <Stack direction="row" spacing={0} alignItems="center" marginTop={0} >
                                        <DatePicker
                                            name="transactionDate"
                                            disabled={!divDialog[0]}
                                            value={transactionDate}
                                            label=""
                                            format="DD.MM.YYYY"
                                            onChange={(newValue) => setTransactionDate(newValue)}
                                            fullWidth
                                            error={false}
                                            helpertext=""
                                        />
                                    </Stack>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextInput
                                    id="accountSelect"
                                    value={account}
                                    label='Verrechnungskonto'
                                    name="selAccount"
                                    fullWidth
                                    required={!isEditMode}
                                    select
                                    {...register("selAccount", { required: !isEditMode })}
                                    onChange={handleChange}
                                    disabled={isEditMode || !divDialog[0]}
                                >
                                    {accounts.map(item => (
                                        <MenuItem key={item.id} value={item.name}>{`${item.name}`}</MenuItem>
                                    ))}
                                    error={Boolean(true)}
                                    helpertext={errors.selAccount && "Verrechnungskonto wählen"}
                                </TextInput>
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="price"
                                    control={control}
                                    defaultValue={price}
                                    rules={{ required: true, pattern: /^[0-9,\\.]+$/ }}
                                    render={({ field }) => <CurrencyInput id="curInput"
                                        {...field}
                                        name="price"
                                        currencyName="currency"
                                        label={priceText}
                                        value={field.value}
                                        handleValueChange={(e) => {
                                            setPrice(e.target.value);
                                            field.onChange(e);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        disabled={!divDialog[0]}
                                        currencySelect="true"
                                        currency={currency}
                                        handleCurrencyChange={handleChange}
                                        error={Boolean(errors.price)}
                                        helpertext={errors.price && "Enter dividend per share."}
                                    />}
                                />
                            </Grid>
                            <Grid item xs={4}>

                                <Controller
                                    name="quantity"
                                    control={control}
                                    defaultValue={quantity}
                                    rules={{ required: true, pattern: /^[0-9,\\.]+$/ }}
                                    render={({ field }) => <TextInput
                                        {...field}
                                        autoFocus={true}
                                        label="Anzahl"
                                        fullWidth
                                        showQuantity={transactionType === 'SELL' || transactionType === 'DIVIDEND'}
                                        setQuantityFunction={onPressQLink}
                                        quantityValue={quantityPortfolio}
                                        value={field.value}
                                        disabled={!divDialog[0]}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => {
                                            setQuantity(e.target.value);
                                            field.onChange(e);
                                        }}
                                        error={Boolean(errors.quantity)}
                                        helpertext={errors.quantity && "Enter quantity per share."}
                                        inputProps={{ type: "number", step: "1.0" }}
                                    />
                                    }
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormLabel id="curInput"
                                    name="grossAmountForeign"
                                    label="Summe"
                                    disabled='true'
                                    sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
                                    value={grossAmountForeign}
                                    handleValueChange={handleChange}
                                    currency={currency}
                                    handleCurrencyChange={handleChange}
                                    error={false}
                                    helpertext=""
                                />
                            </Grid>
                            {currency !== 'EUR' && (<><Grid item xs={4}>
                                <Controller
                                    name="rate"
                                    control={control}
                                    defaultValue={rate}
                                    rules={{ required: true, pattern: /^[0-9,\\.]+$/ }}
                                    render={({ field }) => <TextInput
                                        {...field}
                                        label={rateText}
                                        fullWidth
                                        value={field.value}
                                        disabled={!divDialog[0]}
                                        sx={{ display: currency === 'EUR' ? 'none' : 'block' }}
                                        onKeyDown={handleKeyDown}
                                        onChange={(e) => {
                                            setRate(e.target.value);
                                            field.onChange(e);
                                        }}
                                        error={Boolean(errors.rate)}
                                        helpertext={errors.rate && "Enter exchange rate"}
                                        inputProps={{ type: "number", step: "0.001" }}
                                    />}
                                />
                            </Grid>
                                {smUp && (<Grid item xs={4}>
                                    <Box minHeight={80}></Box>
                                </Grid>)}
                                <Grid item xs={4}>
                                    <FormLabel id="curInput"
                                        name="grossAmount"
                                        label="Summe in EUR"
                                        disabled='true'
                                        sx={{ fontWeight: 'bold', fontStyle: 'italic', display: currency === 'EUR' ? 'none' : 'block' }}
                                        value={grossAmount}
                                        handleValueChange={handleChange}
                                        currency='EUR'
                                        handleCurrencyChange={handleChange}
                                        error={false}
                                        helpertext=""
                                    />
                                </Grid></>)}
                            <Grid item xs={4}>
                                <Controller
                                    name="taxForeign"
                                    control={control}
                                    defaultValue={taxForeign}
                                    rules={{ required: false, pattern: /^[0-9,\\.]+$/ }}
                                    render={({ field }) => <CurrencyInput id="curInputTaxForeign"
                                        {...field}
                                        name="curInputTaxForeign"
                                        currencyName="currency"
                                        label="Quellensteuer Ausland"
                                        disabled={!divDialog[0]}
                                        value={field.value}
                                        handleValueChange={(e) => {
                                            setTaxForeign(e.target.value);
                                            field.onChange(e);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        currency={currency}
                                        handleCurrencyChange={handleChange}
                                        error={Boolean(errors.taxForeign)}
                                        helpertext={errors.taxForeign && "Enter foreign tax"}
                                    />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="tax"
                                    control={control}
                                    defaultValue={tax}
                                    rules={{ required: false, pattern: /^[0-9,\\.]+$/ }}
                                    render={({ field }) => <CurrencyInput id="curInputTax"
                                        {...field}
                                        name="curInputTax"
                                        currencyName="currency"
                                        label="Steuern/Gebühren"
                                        disabled={!divDialog[0]}
                                        value={field.value}
                                        handleValueChange={(e) => {
                                            setTax(e.target.value);
                                            field.onChange(e);
                                        }}
                                        onKeyDown={handleKeyDown}
                                        currency='EUR'
                                        handleCurrencyChange={handleChange}
                                        error={Boolean(errors.tax)}
                                        helpertext={errors.tax && "Enter tax"}
                                    />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormLabel id="curInput"
                                    name="netAmount"
                                    label={sumText}
                                    disabled='true'
                                    sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
                                    value={netAmount}
                                    handleValueChange={handleChange}
                                    currency='EUR'
                                    handleCurrencyChange={handleChange}
                                    error={false}
                                    helpertext=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput
                                    margin="dense"
                                    {...register("notes")}
                                    label="Notizen"
                                    rows={2}
                                    fullWidth
                                    disabled={!divDialog[0]}
                                    onKeyDown={handleKeyDown}
                                    value={notes}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox disabled={isEditMode} defaultChecked checked={bookToAccount}
                                        onChange={(e) => setBookToAccount(e.target.checked)}
                                        inputProps={{ 'aria-label': 'booking checkbox' }} />} label="Buchung auf Verrechnungskonto durchführen" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "flex-start" }}>
                        <Stack direction="row" spacing={1} alignItems="center" marginLeft={2} >
                            <Button variant="outlined" onClick={handleClose}>Abbrechen</Button>
                            <Button variant="contained" color="success" type="submit" disabled={!divDialog[0]}>Speichern</Button>

                        </Stack>
                    </DialogActions>
                </form>
            </Box>
            <HelpText viewName='transactiondividend' />
        </Box>

    );
};


export default TransactionDividend
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Stack,
  TextField
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import FinDataGrid from "../components/findatagrid";
import Header from "../components/header";


const Cronjobs = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  function getFormattedDate(d) {
    const formattedDate = d.toLocaleDateString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  }

  function getStartDate(params) {
    const date = new Date(params.row.last_run_start);
    return getFormattedDate(date);
  }
  function getEndDate(params) {
    const date = new Date(params.row.last_run_end);
    return getFormattedDate(date);
  }

  function findRowById(id) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].id === id) {
        return rows[i];
      }
    }
    return null;
  }

  const handleEditButtonClick = (row) => {
    console.log("Edit button clicked " + row.id);
    const fullRow = findRowById(row.id);
    console.log("Found row " + fullRow.id + " " + fullRow.name + " " + fullRow);
    setSelectedRow(fullRow);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    console.log(
      "save new values: " +
      selectedRow.id +
      " " +
      selectedRow.name +
      " " +
      selectedRow.schedule
    );

    fetch(process.env.REACT_APP_API_URI + "/api/cronjobs/" + selectedRow.id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: selectedRow.id,
        name: selectedRow.name,
        description: selectedRow.description,
        schedule: selectedRow.schedule,
        active: selectedRow.active,
        startnow: selectedRow.startnow,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        console.log("Changes saved");
        fetchRows();
      })
      .catch((error) => console.error(error));

    setOpen(false);
  };

  async function fetchRows() {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + "/api/cronjobs");
      const data = await response.json();
      setRows(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRows();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", with: 20 },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "schedule",
      headerName: "Schedule",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "task",
      headerName: "Task",
      width: 220,
    },
    {
      field: "active",
      headerName: "Aktiv",
      width: 50,
    },
    {
      field: "last_run_start",
      headerName: "Last run",
      width: 160,
      valueGetter: getStartDate,
    },
    {
      field: "last_run_end",
      headerName: "Last stop",
      width: 160,
      valueGetter: getEndDate,
    },
    {
      field: "last_msg",
      headerName: "Message",
    },
    {
      field: "description",
      headerName: "Description",
      width: 240,
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (row) => (
        <Button color="inherit" onClick={() => handleEditButtonClick(Object.assign({}, row))}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <Box id="stockMainBox" m="10px" width="75%" height="100%">
      <Stack id="stackHeader" direction="row" display="flex">
        <Header title="CRON ***" subtitle="finalyse cron jobs..." />
        <Box id="helperBox" height="80px" width="100px">
          {" "}
        </Box>

      </Stack>
      <FinDataGrid
        id="gridCron"
        height="75vh"
        density="compact"
        rowHeight={60}
        rows={rows}
        columns={columns}
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      >
      </FinDataGrid >
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit cron job...</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="textName"
            label="Name"
            fullWidth
            variant="standard"
            placeholder={selectedRow.name}
            value={selectedRow.name}
            onChange={(event) => {
              setSelectedRow({ ...selectedRow, name: event.target.value });
              console.log("Editing...: " + selectedRow.name);
            }}
          />
          <TextField
            margin="dense"
            id="textSchedule"
            label="Schedule"
            fullWidth
            variant="standard"
            defaultValue={selectedRow.schedule}
            value={selectedRow.schedule}
            onChange={(event) => {
              setSelectedRow({
                ...selectedRow,
                schedule: event.target.value,
              });
              console.log(
                "Editing...: " + selectedRow.name + " " + selectedRow.schedule
              );
            }}
          />
          <TextField
            margin="dense"
            id="textDescription"
            label="Description"
            fullWidth
            variant="standard"
            placeholder={selectedRow.description}
            value={selectedRow.description}
            onChange={(event) => {
              setSelectedRow({
                ...selectedRow,
                description: event.target.value,
              });
              console.log(
                "Editing...: " +
                selectedRow.name +
                " " +
                selectedRow.description
              );
            }}
          />
          <TextField
            margin="dense"
            id="textActive"
            label="active (0/1)"
            fullWidth
            variant="standard"
            placeholder={selectedRow.active}
            value={selectedRow.active}
            onChange={(event) => {
              setSelectedRow({ ...selectedRow, active: event.target.value });
              console.log(
                "Editing...: " + selectedRow.name + " " + selectedRow.active
              );
            }}
          />
          <TextField
            margin="dense"
            id="textStartNow"
            label="Start now (0/1)"
            fullWidth
            variant="standard"
            placeholder={selectedRow.startnow}
            value={selectedRow.startnow}
            onChange={(event) => {
              setSelectedRow({
                ...selectedRow,
                startnow: event.target.value,
              });
              console.log(
                "Editing...: " + selectedRow.name + " " + selectedRow.startnow
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="inherit" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default Cronjobs;

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { Avatar, Box, Divider, Drawer, List, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { tokens } from '../theme/theme';
import { NavItem } from './nav-item';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { UserContext } from '../theme/userContext';
import EuroIcon from '@mui/icons-material/Euro';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Sidebar = (props) => {
    let location = useLocation();
    const { user } = useContext(UserContext);
    const isAdmin = user && user.role === 'admin';
    const { open, onClose } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
        defaultMatches: true,
        noSsr: false
    });

    useEffect(() => {
        if (open) {
            onClose?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const content = (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}
        >
            <div>
                <Box sx={{ p: 2 }}>
                    <Stack direction="row" spacing={0} alignItems="center" marginTop={0} >
                        <Avatar src='/static/BGSF.US.png' alt={'Company Logo'}
                            sx={{
                                marginLeft: 0,
                                height: 28,
                                width: 28
                            }}
                        >
                        </Avatar>
                        <Typography ml={2} variant="h4">finalyser.app</Typography>
                    </Stack>
                </Box>
            </div>
            <Divider
                sx={{
                    borderColor: '#2D3748',
                    my: 0
                }}
            />
            <Box sx={{ flexGrow: 1 }}>
                <List>
                    <NavItem key="Dashboard"
                        icon=<HomeIcon />
                        href="/feed"
                        title="Dashboard">
                    </NavItem>
                    <NavItem key="Portfolio"
                        icon=<AccountBalanceIcon />
                        href="/portfolio"
                        title="Portfolio">
                    </NavItem>
                    <NavItem key="Dividenden"
                        icon=<AttachMoneyIcon />
                        href="/dividends"
                        title="Dividenden">
                    </NavItem>
                    <NavItem key="Kauf / Verkauf"
                        icon=<StackedLineChartIcon />
                        href="/transactiondividend"
                        title="Kauf / Verkauf">
                    </NavItem>
                    <NavItem key="Cash"
                        icon=<EuroIcon />
                        href="/accounts"
                        title="Cash">
                    </NavItem>
                    <NavItem key="Screener"
                        icon=<StackedLineChartIcon />
                        href="/stocks"
                        title="Screener">
                    </NavItem>
                    {isAdmin && (<NavItem key="Cronjobs"
                        icon=<AlarmOnIcon />
                        href="/cronjobs"
                        title="Cronjobs">
                    </NavItem>)}
                    <NavItem key="Settings"
                        icon=<SettingsIcon />
                        href="/settings"
                        title="Settings">
                    </NavItem>
                    <Divider />
                    <NavItem key="Help"
                        icon=<HelpOutlineIcon />
                        href="/help"
                        title="Hilfe">
                    </NavItem>
                </List>
            </Box>
            <Divider sx={{ borderColor: '#2D3748' }} />
            <Box
                sx={{
                    px: 2,
                    py: 3
                }}
            >
            </Box>
        </Box>
    );
    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: colors.primary[400],
                        color: '#FFFFFF',
                        width: 240,
                        border: "none",
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: colors.primary[400],
                    color: '#FFFFFF',
                    width: 240,
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

Sidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
};

export default Sidebar
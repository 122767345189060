import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme/theme";

const FinDataGrid = ({ ...params }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const localeText = {
        noRowsLabel: 'Keine Daten vorhanden...',
    };

    return (
        <Box
            id="tableBox"
            m="2px 0 0 0"
            height={params.height}
            display="flex"
            sx={{
                "& .name-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .positive-column--cell": {
                    color: colors.greenAccent[300],
                },
                "& .positive-column-bg--cell": {
                    backgroundColor: "#2E7D32",
                    color: "#FFFFFF",
                },
                "& .negative-column--cell": {
                    color: colors.redAccent[300],
                },
                "& .negative-column-bg--cell": {
                    backgroundColor: "#D32F2F",
                    color: "#FFFFFF",
                },
                "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: colors.primary[500],
                    borderBottom: "0",
                    fontWeight: "bold",
                },
                "& .MuiDataGrid-virtualScroller": {
                    backgroundColor: colors.primary[500],
                },
                "& .MuiDataGrid-footerContainer": {
                    backgroundColor: colors.primary[500],
                },
                "& .MuiCheckbox-root": {
                    color: `${colors.greenAccent[300]} !important`,
                },
                "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                    color: `${colors.grey[100]} !important`,
                },
            }}
        >
            <DataGrid
                sx={{ typography: "body1" }}
                {...params}
                localeText={localeText}
            />
        </Box>
    );
};

export default FinDataGrid;
import { Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { formatNumberWithoutDigits } from "../misc/helperfunctions";
import { UserContext } from "../theme/userContext";
import FinDataGrid from "./findatagrid";
const { v4: uuidv4 } = require('uuid');

function renderYearCell(params) {
    return (
        <Typography variant="body2" noWrap > {params.value}</Typography>
    );
}
function renderActionCell(params) {
    // const row = findRowById(params.id);
    const val = formatNumberWithoutDigits(params.value);
    return (
        <Typography variant="body2" noWrap > {val}</Typography>
    );
}


const colWidth = 64;
const columns = [
    { field: 'year', headerName: 'Jahr', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderYearCell },
    { field: 'month1', headerName: 'Jan', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month2', headerName: 'Feb', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month3', headerName: 'Mar', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month4', headerName: 'Apr', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month5', headerName: 'May', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month6', headerName: 'Jun', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month7', headerName: 'Jul', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month8', headerName: 'Aug', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month9', headerName: 'Sep', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month10', headerName: 'Okt', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month11', headerName: 'Nov', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'month12', headerName: 'Dez', width: colWidth, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },
    { field: 'total', headerName: '∑', width: colWidth + 10, headerAlign: "center", align: "center", sortable: false, hideable: false, renderCell: renderActionCell },

];

export function HeatMapDividends({ divNetto, onCellClick }) {
    const { user } = useContext(UserContext);
    const [data, setData] = useState([]);

    const handleGridCellClick = (params, event) => {
        if (onCellClick) {
            onCellClick(params);
        }
    };


    async function fetchDivByMonth() {
        try {
            let url = process.env.REACT_APP_API_URI + "/api/alldividendsbymonth?puser=" + user.p_user;
            const response = await fetch(url);
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error(error);
        }
    }
    const fetchDivByMonthCallback = useCallback(fetchDivByMonth, [user]);
    useEffect(() => {
        console.log("HeatMapDividends useEffect fetchDivByMonthCallback")
        fetchDivByMonthCallback();
    }, [fetchDivByMonthCallback]);

    // Erstellen eines Objekts mit Monats-Keys für jede Jahreszahl
    const result = data.reduce((acc, item) => {
        const date = new Date(item.first_day_month);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;

        if (!acc[year]) {
            acc[year] = { year, [month]: divNetto ? item.total_net : item.total_gross };
        } else {
            acc[year][month] = divNetto ? item.total_net : item.total_gross;
        }
        return acc;
    }, {});

    const rows = Object.values(result).map((item) => {
        const monthlyValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => item[month] || 0);
        const rowTotal = monthlyValues.reduce((acc, value) => parseFloat(acc) + parseFloat(value), 0);
        return {
            id: uuidv4(),
            year: item.year,
            month1: item[1] || 0,
            month2: item[2] || 0,
            month3: item[3] || 0,
            month4: item[4] || 0,
            month5: item[5] || 0,
            month6: item[6] || 0,
            month7: item[7] || 0,
            month8: item[8] || 0,
            month9: item[9] || 0,
            month10: item[10] || 0,
            month11: item[11] || 0,
            month12: item[12] || 0,
            total: rowTotal,
        };
    });

    const getCellClassName = (params) => {
        if (params.field !== 'year') {
            const prevYear = rows.find((r) => r.year === params.row.year - 1);
            const prevValue = prevYear ? Number(prevYear[params.field]) : 0;
            //console.log("param.value: " + params.value + " prevYear: " + prevYear + " prevValue: " + prevValue);
            if (params.value > prevValue) {
                return 'positive-column-bg--cell';
            } else if (params.value !== 0 && params.value < prevValue) {
                return 'negative-column-bg--cell';
            }
        }
        return '';
    };

    return (
        <div style={{ width: '100%' }}>
            <FinDataGrid
                rows={rows}
                columns={columns}
                rowHeight={30}
                pageSize={25}
                getCellClassName={getCellClassName}
                autoHeight
                pagination="false"
                disableSelectionOnClick={true}
                hideFooter
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableColumnMenu={true}
                onCellClick={handleGridCellClick}
            />
        </div>
    );
}

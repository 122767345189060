import { Box, useTheme } from "@mui/material";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Pricecard } from "../../components/pricecard";
import { tokens } from "../../theme/theme";
import { formatNumber } from "../../misc/helperfunctions";


const Detailbar = (props) => {

    const { data } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // eslint-disable-next-line
    const Item = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        ...theme.typography.body2,
        marginTop: 1,
        padding: 2,
        border: theme.palette.mode === "dark" ? 0 : 0,
        borderRadius: "8px",
        textAlign: 'center',
        minHeight: 60,
        color: theme.palette.text.secondary,
    }));

    const totalMarketPriceDetailText = "Davon Cash: " + formatNumber(data.totalCashBalance) + ' €';
    const profit1DayDetailText = formatNumber(data.profit_1d_total_p) + '%';
    const absPerformDetailText = formatNumber(data.abs_performance_prz) + '%';
    const totalNetDividendsDetailText = "Netto: " + formatNumber(data.sum_dividends_net) + ' €';


    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={3}>
                <Pricecard label="Abs. Perf. 1 Tag" value={data.profit_1d_total} details={profit1DayDetailText} showvatar="true" />
            </Grid>
            <Grid item xs={4} sm={3}>
                <Pricecard label="Marktwert gesamt" value={data.total_market_price} details={totalMarketPriceDetailText} showvatar="false" />
            </Grid>
            <Grid item xs={4} sm={3}>
                {/* <Tooltip title="FIXME: WIRD NICHT ANGEZEIGT! Absolute Performance: Kursgewinne + Realisierte Gewinne + Brutto Dividende - Steuer in EUR"> */}
                <Pricecard label="Abs. Performance" value={data.sum_abs_performance} details={absPerformDetailText} showvatar="true" />
                {/* </Tooltip> */}
            </Grid>
            <Grid item xs={4} sm={3}>
                <Pricecard label="∑ Dividenden" value={data.sum_dividends} details={totalNetDividendsDetailText} showvatar="false" />
            </Grid>
            {/*  <Grid item xs={12}>
                <Item />
            </Grid> */}
        </Grid>

    );
};

export default Detailbar;
import { Avatar, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatNumberWithoutDigits } from "../misc/helperfunctions";
import { UserContext } from "../theme/userContext";
import FinDataGrid from "./findatagrid";
const { v4: uuidv4 } = require('uuid');



export function HeatMapIdentifier({ divNetto, setIsLoading, willReload }) {
    const { user } = useContext(UserContext);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    async function fetchDivByMonth() {
        try {
            setIsLoading(true);
            let url = process.env.REACT_APP_API_URI + "/api/alldividendsbyidentifier?puser=" + user.p_user;
            const response = await fetch(url);
            const data = await response.json();
            //console.log(data);
            const cols = await getColumns(data);
            const rs = await getRows(data, cols);
            setColumns(cols);
            setRows(rs);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        console.log("HeatMapIdentifier useEffect fetchDivByMonthCallback")
        fetchDivByMonth();
        // eslint-disable-next-line
    }, [willReload]);

    function renderNameCell(params) {
        let imageURL = process.env.REACT_APP_API_URI + '/images/' + params.row.identifier + '.png';
        if (params.row.identifier === 'Summe') {
            return (<Typography variant="body2" ml={5} sx={{ fontWeight: 'bold' }} > {params.row.identifier}</Typography>)
        }

        return (
            <Stack direction="row" spacing={2}>
                <Avatar src={imageURL} alt={'Company Logo'}
                    sx={{
                        height: 20,
                        width: 20
                    }}
                >
                </Avatar>
                <Button
                    sx={{
                        m: 0,
                        p: 0,
                        minWidth: 0,
                        textOverflow: 'ellipsis',
                    }}
                    component={Link}
                    to={{
                        pathname: '/detailview',
                    }}
                    state={{ identifier: params.row.identifier }}
                >
                    <Typography
                        variant="body2"
                        noWrap
                        sx={{
                            // fontWeight: 'bold',
                            maxWidth: '126px',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {params.row.name}
                    </Typography>
                </Button>
            </Stack>
        );
    }

    function renderActionCell(params) {
        // const row = findRowById(params.id);
        const val = formatNumberWithoutDigits(params.value);
        if (params.row.identifier === 'Summe') {
            return (<Typography variant="body2" sx={{ fontWeight: 'bold' }} > {val}</Typography>)
        }
        return (
            <Typography variant="body2" noWrap > {val}</Typography>
        );
    }

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const colWidth = 80;

    async function getColumns(data) {
        const colIdentifierLeft = { field: "identifier", headerName: "Wertpapier", width: 180, headerAlign: "left", align: "left", sortable: false, hideable: false, renderCell: renderNameCell }
        const colIdentifierRight = { field: "identifierR", headerName: "Name", width: 180, headerAlign: "left", align: "left", sortable: false, hideable: false, renderCell: renderNameCell }
        const columns = [];
        columns.push(colIdentifierLeft);

        if (data && data.length > 0) {
            const firstYear = new Date(data[0].first_day_month).getFullYear();
            const lastYear = new Date().getFullYear();
            const lastMonth = new Date().getMonth() + 1;

            for (let year = firstYear; year <= lastYear; year++) {
                const lastMonthOfYear = year === lastYear ? lastMonth : 12;

                for (let i = 0; i < lastMonthOfYear; i++) {
                    const monthIndex = i + 1;
                    const monthLabel = `${months[i]}. ${String(year).slice(-2)}`;

                    columns.push({
                        field: `${monthLabel}-${monthIndex}`,
                        headerName: monthLabel,
                        width: colWidth,
                        headerAlign: "center",
                        align: "center",
                        sortable: false,
                        hideable: false,
                        renderCell: renderActionCell
                    });
                }
            }

        }
        columns.push(colIdentifierRight);
        //console.log(columns);
        return columns;
    }


    // const securities = [...new Set(data.map((d) => d.identifier))];

    async function getRows(data, columns) {
        const rows = {};
        const sums = {}; // Objekt, um die Summen pro Monat zu speichern

        if (columns && columns.length > 0) {
            for (const item of data) {
                const security = item.identifier;
                const date = new Date(item.first_day_month);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const columnName = `${months[month - 1]}. ${String(year).slice(-2)}-${month}`;

                if (!rows[security]) {
                    rows[security] = {
                        id: uuidv4(),
                        identifier: security,
                        name: item.name,
                        monthLabel: columnName,
                        yearTotals: {},
                    };
                }

                const value = parseFloat(divNetto ? item.total_net : item.total_gross);
                rows[security][columnName] = value;
                if (rows[security].yearTotals[year]) {
                    rows[security].yearTotals[year] += value;
                } else {
                    rows[security].yearTotals[year] = value;
                }

                // Summenwerte berechnen
                if (sums[columnName]) {
                    sums[columnName] += value;
                } else {
                    sums[columnName] = value;
                }
            }

            // Zeilen mit Summenwerten erstellen und hinzufügen
            const sumRow = {
                id: uuidv4(),
                identifier: "Summe",
                name: "Summe",
                monthLabel: "Summen",
            };
            for (const column of columns) {
                if (column.field !== "identifier" && column.field !== "monthLabel") {
                    sumRow[column.field] = sums[column.field];
                }
            }
            rows["sum"] = sumRow;
        }

        const rowsArray = Object.values(rows);
        // console.log(rowsArray);
        return rowsArray;
    }


    return (
        <div style={{ width: '100%' }}>
            <FinDataGrid
                rows={rows}
                columns={columns}
                rowHeight={30}
                pageSize={100}
                autoHeight
                //pagination={false}
                disableSelectionOnClick={true}
                //hideFooter
                disableColumnFilter={true}
                disableColumnSelector={true}
                disableColumnMenu={true}
            />
        </div>
    );
}

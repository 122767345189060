import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";

const CompanyAvatar = ({ identifier, ...params }) => {
    const [imageUrl, setImageUrl] = useState(null);

    let fallbackImage = process.env.REACT_APP_API_URI + '/images/error.png';

    useEffect(() => {
        console.log("CompanyAvatar " + identifier);
        if (identifier && identifier.length > 1) {
            let imageURL = process.env.REACT_APP_API_URI + '/images/' + identifier + '.png';
            setImageUrl(imageURL);
            console.log("CompanyAvatar: " + imageURL);
        }
        // eslint-disable-next-line
    }, [identifier]);


    const errorHandler = () => {
        setImageUrl(fallbackImage);
    }

    return (
        <Avatar
            src={imageUrl}
            {...params}
            imgProps={{
                onError: errorHandler,
                style: {
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                },
            }}
        />
    )
};

export default CompanyAvatar;
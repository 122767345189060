import { Autocomplete, Avatar, Box, Stack, styled, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { debounce } from 'lodash';
import { UserContext } from "../theme/userContext";

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    fontStyle: 'italic'
}));

const GroupItems = styled('ul')({
    padding: 0,
});


const SearchAutocomplete = ({ handleAutocompleteChange }) => {
    const { user } = useContext(UserContext);
    const [options, setOptions] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);



    const fetchFilteredData = async (input) => {
        try {
            setIsLoading(true);
            console.log("SearchAutocomplete fetchRows: input " + input);
            let url = process.env.REACT_APP_API_URI + "/api/securitiesforautocomplete?puser=" + user.p_user;
            if (input) {
                url += "&";
            }
            if (input) {
                url += "filter=" + encodeURIComponent(input.toLowerCase());
            }
            const response = await fetch(url);
            const data = await response.json();
            console.log(data);
            setOptions(data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    // Verzögerte Version von fetchFilteredData
    const fetchFilteredDataDebounced = debounce(fetchFilteredData, 500);
    useEffect(() => {
        if (searchInput.length > 0) {
            fetchFilteredDataDebounced(searchInput);
        } else {
            setOptions([]);
        }
        // eslint-disable-next-line
    }, [searchInput]);

    const filterOptions = (options, { inputValue }) => {
        try {
            return options.filter(
                (option) =>
                    option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option.identifier.toLowerCase().includes(inputValue.toLowerCase()) ||
                    option.isin.toLowerCase().includes(inputValue.toLowerCase())
            );
        } catch (error) {
            return [];
        }

    };

    return (
        <Autocomplete
            id="searchAutoComplete"
            options={options}
            noOptionsText="Bitte Namen, ISIN oder Ticker eingeben..."
            groupBy={(option) => option.isbestand > 0 ? 'Mit Bestand' : 'Ohne Bestand'}
            onChange={handleAutocompleteChange}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.identifier === value.identifier}
            filterOptions={filterOptions}
            renderOption={(props, option) => (
                <Box component="li" sx={{
                    '& > img': { mr: 2, flexShrink: 0 }, height: 50,
                    display: 'flex',
                    alignItems: 'center',
                }} {...props}>
                    <Avatar src={process.env.REACT_APP_API_URI + '/images/' + option.identifier + '.png'} alt={'Company Logo'}
                        sx={{
                            height: 30,
                            width: 30
                        }}
                    >
                    </Avatar>
                    <Stack ml={2}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{option.name}</Typography>
                        <Stack direction="row" spacing={1}>
                            <Typography variant="h7" noWrap >{option.type}</Typography>
                            <Typography variant="h7" noWrap >·</Typography>
                            <Typography variant="h7" noWrap >{option.identifier}</Typography>
                            <Typography variant="h7" noWrap >·</Typography>
                            <Typography marginLeft="2px" variant="h7" noWrap>{option.isin}</Typography>
                        </Stack>
                    </Stack>
                </Box>)
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Bitte Namen, ISIN oder Ticker eingeben..."
                    variant="outlined"
                    onChange={(e) => setSearchInput(e.target.value)}
                />
            )}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
        />
    )
};

export default SearchAutocomplete;
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../theme/userContext";

function PrivateRoute({ children, ...rest }) {
    const { user } = useContext(UserContext);

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    const { role } = user;
    if (rest.adminOnly && role !== 'admin') {
        return <Navigate to="/" replace />;
    }

    return children;
}

export { PrivateRoute };


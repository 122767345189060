import React, { useState } from "react";
import { Box, CircularProgress, Grid, IconButton, Tab, Tabs } from "@mui/material";
import Header from "../components/header";
import RefreshIcon from '@mui/icons-material/Refresh';
import DivTabMain from "./diviTabMain";
import DivTabPayed from "./divTabPayed";
import DivTabBook from "./divTabBook";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

const Dividends = () => {

    const [isLoading, setIsLoading] = useState(false); // for loading Icon
    const [willReload, setWillreload] = useState(false);
    const [value, setValue] = useState(() => {
        const storedTab = sessionStorage.getItem('tabsDiv');
        return storedTab ? JSON.parse(storedTab) : 0;
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
        sessionStorage.setItem('tabsDiv', JSON.stringify(newValue));
    };

    return (
        <Box id="dividendsMainBox" m="20px" >
            <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} justifyContent="flex-start">
                        <Grid item xs={2} sm={2} >
                            <Header title="DIVIDENDEN" subtitle="" />
                        </Grid>
                        <Grid item xs={1} >
                            <Box display="flex" justifyContent="center" ml={4} >
                                {isLoading && (
                                    <CircularProgress color="inherit" />
                                )}
                                {(!isLoading) && (
                                    <IconButton ml={2} size="large" aria-label="refresh" onClick={setWillreload}>
                                        <RefreshIcon fontSize="large" />
                                    </IconButton>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={value} onChange={handleChange} textColor="secondary"
                            indicatorColor="secondary"
                            sx={{
                                "& .MuiTab-root": {
                                    minWidth: 120,
                                },
                            }}>
                            <Tab label="Übersicht" id="tab-0" />
                            <Tab label="Buchen" id="tab-1" />
                            <Tab label="Zahlungen" id="tab-2" />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <DivTabMain setIsLoading={setIsLoading} willReload={willReload} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <DivTabBook setIsLoading={setIsLoading} willReload={willReload} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <DivTabPayed setIsLoading={setIsLoading} willReload={willReload} />
                        </TabPanel>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dividends
import { Box, Stack, styled, Typography, useTheme } from "@mui/material";
import { TypoStyledNumber } from "../../components/typhoStyledNumber";
import { tokens } from "../../theme/theme";

function getType(value) {
    switch (value) {
        case 0:
            return "Common Stock";
        case 1:
            return "ETF";
        case 2:
            return "FUND";
        default:
            return "OTHERS";
    }
}


const Tabdetailbar = (props) => {
    const { value, data } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let label;
    let profit_1d = '0';
    let profit = '0';
    let totalbuyprice = '0';
    let sumtotalmarket = '0';
    let absperformance = '0';
    let sumdividends = '0';

    // Array.isArray(data) && data.length
    try {
        // Tabvalue of current tab
        if (value || value === 0) {
            // get const of type (Stock, Etfs,...)
            const type = getType(value);
            // get datasection of JSON 
            if (typeof data === "object" && data !== null) {
                const typedata = data.types.find(element => element.type === type);

                if (typedata) {
                    if (type && data.types.length > value) {
                        label = data.types[value].type;
                        if (data.types[value].type === 'Common Stock') {
                            label = "STOCKS"
                        }
                    }
                    profit_1d = typedata.profit_1d;
                    totalbuyprice = typedata.sumtotalbuy;
                    sumtotalmarket = typedata.sumtotalmarket;
                    absperformance = typedata.sum_abs_performance;
                    sumdividends = typedata.sum_dividends_brutto_total;
                    profit = sumtotalmarket - totalbuyprice;
                }
            }
        }
    } catch (error) {
        console.error(error);
    }

    const StyledBox = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        ...theme.typography.body2,
        marginTop: 1,
        padding: 12,
        border: theme.palette.mode === "dark" ? 0 : 0,
        borderRadius: "8px",
        minHeight: 60,
        color: theme.palette.text.secondary,
    }));

    return (
        <StyledBox id="lefttabBox" alignItems="center" width="100%" sx={props.sx}>
            <Stack
                id="stack"
                justifyContent="flex-start"
                direction="row"
                alignItems="center"
                height="100%"
            >
                <Typography
                    gutterBottom
                    color="#FA9C00"
                    width={16}
                    variant="h3" >
                    ∑
                </Typography>
                <Typography
                    color="textPrimary"
                    width={60}
                    variant="h5" display="block" >
                    {label}
                </Typography>
                <Box marginLeft={3}>
                    <Typography
                        color="textPrimary"
                        marginLeft={2}
                        variant="body1" display="block" >
                        Abs. Perf. 1 Tag
                    </Typography>
                    <TypoStyledNumber value={profit_1d} unit='€' />
                </Box>
                <Box marginLeft={2}>
                    <Typography
                        color="textPrimary"
                        marginLeft={2}
                        variant="body1" display="block" >
                        Einstandspreis
                    </Typography>
                    <TypoStyledNumber value={totalbuyprice} unit='€' />
                </Box>
                <Box marginLeft={2}>
                    <Typography
                        color="textPrimary"
                        marginLeft={2}
                        variant="body1" display="block" >
                        Marktwert ges.
                    </Typography>
                    <TypoStyledNumber value={sumtotalmarket} unit='€' />
                </Box>
                <Box marginLeft={2}>
                    <Typography
                        color="textPrimary"
                        marginLeft={2}
                        variant="body1" display="block" >
                        Gewinn / Verlust
                    </Typography>
                    <TypoStyledNumber value={profit} unit='€' />
                </Box>
                <Box marginLeft={2}>
                    <Typography
                        color="textPrimary"
                        marginLeft={2}
                        variant="body1" display="block" >
                        Abs. Performance
                    </Typography>
                    <TypoStyledNumber value={absperformance} unit='€' />
                </Box>
                <Box marginLeft={2}>
                    <Typography
                        color="textPrimary"
                        marginLeft={2}
                        variant="body1" display="block" >
                        ∑ Dividenden
                    </Typography>
                    <TypoStyledNumber value={sumdividends} unit='€' />
                </Box>
            </Stack>
        </StyledBox>

    );
};

export default Tabdetailbar;
import { Button, Container, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { handleSignOut } from "../theme/firebase";
import { UserContext } from "../theme/userContext";
import { useContext } from "react";

export default function ErrorTech() {

    const { onLogout } = useContext(UserContext);
    const location = useLocation();
    const userEmail = location.state.email;
    const navigate = useNavigate();

    const handleSave = () => {

        if (handleSignOut()) {
            console.log("user signed out");
            onLogout();

        }
        navigate('/login');
    };

    return (
        <Container maxWidth="xs" sx={{ mt: 2 }}>
            <Typography variant="h5" component="h1" gutterBottom>
                Oops!
            </Typography>
            <Typography variant="p" component="p" gutterBottom>
                Sorry, an unexpected error has occurred.
            </Typography>
            <Typography variant="p" component="p" gutterBottom>
                You are logged in as: <i>{userEmail}</i>
            </Typography>
            <Button ml={2} variant="outlined" onClick={handleSave}>Try login again...</Button>
        </Container>
    );
}
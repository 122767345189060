import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";
import moment from "moment";

const TimeSelectDialog = (props) => {
    const { open, handleClose, fromDate, toDate, onSave } = props;
    const [fromDateValue, setFromDateValue] = useState(moment(fromDate));
    const [toDateValue, setToDateValue] = useState(moment(toDate));

    // Submit: validation of each form fields
    const handleSubmit = (event) => {
        event.preventDefault();
        if (toDateValue.isBefore(fromDateValue, "day")) {
            alert("Das Enddatum darf nicht vor dem Startdatum liegen.");
            return;
        }
        onSave(fromDateValue, toDateValue);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} height={400}>
            <DialogTitle fontSize={24}>Zeitraum wählen...</DialogTitle>
            <DialogContent dividers>
                <Stack direction="row">
                    <DatePicker
                        value={fromDateValue}
                        label="von Datum"
                        format="DD.MM.YYYY"
                        onChange={(newValue) => setFromDateValue(newValue)}
                        sx={{ width: 140 }}
                    />
                    <DatePicker
                        value={toDateValue}
                        label="bis Datum"
                        format="DD.MM.YYYY"
                        onChange={(newValue) => setToDateValue(newValue)}
                        sx={{ width: 140 }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button onClick={handleSubmit}>Speichern</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TimeSelectDialog;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCSh20d9JMHPhkSpCbIIm_TLf2DAZsvbao",
    authDomain: "finalyser-31a51.firebaseapp.com",
    projectId: "finalyser-31a51",
    storageBucket: "finalyser-31a51.appspot.com",
    messagingSenderId: "708357048383",
    appId: "1:708357048383:web:63d812103a495947e7908a",
    measurementId: "G-T7WCF1T9NQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');

export const createUser = async (email, password) => {
    return createUserWithEmailAndPassword(getAuth(app), email, password);
}

export const signInUser = async (email, password) => {
    return signInWithEmailAndPassword(getAuth(app), email, password);
}

export const sendPasswordResetMail = async (email) => {
    return sendPasswordResetEmail(getAuth(app), email);
}

export const handleSignOut = () => {

    const auth = getAuth(app);
    auth.signOut().then(() => {
        console.log("handleSignOut");
        return true;
    }).catch((error) => {
        console.log(error);
        return false;
    });
};

export const onAuthStateChange = (callback) => {
    return getAuth(app).onAuthStateChanged(user => {
        if (user) {
            console.log("onAuthStateChange: The user is logged in " + user.email);
        } else {
            console.log("onAuthStateChange: The user is not logged in ");
        }
        callback(user);
    });
}
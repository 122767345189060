import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Avatar, Box, Grid, Typography, useTheme } from '@mui/material';
import { formatNumber } from '../misc/helperfunctions';
import { tokens } from '../theme/theme';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Pricecard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let color = colors.greenAccent[300];
    let postive = true;
    if (props.value && props.value < 0) {
        color = colors.redAccent[300];
        postive = false;
    }
    let value = formatNumber(props.value) + ' €';
    if (props.unit) {
        value = formatNumber(props.value) + ' ' + props.unit;
    }

    return (
        <Box
            {...props}
            marginTop={1}
            padding={2}
            border={theme.palette.mode === "dark" ? 0 : 0}
            borderRadius="8px"
            backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
        >
            <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ justifyContent: 'space-between' }}
            >
                <Grid item>
                    <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h5" display="block"
                    >
                        {props.label}
                    </Typography>
                    <Typography
                        color={color}
                        variant="h4"
                    >
                        {value}
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h7"
                    >
                        {props.details}
                    </Typography>

                </Grid>
                <Grid item>
                    {postive && props.showvatar === "true" && (<Avatar
                        display="flex"
                        sx={{
                            backgroundColor: 'success.main',
                            height: 40,
                            width: 40,
                        }}
                    >
                        <ArrowUpwardIcon />
                    </Avatar>)}
                    {!postive && props.showvatar === "true" && (<Avatar
                        display="flex"
                        sx={{
                            backgroundColor: 'error.main',
                            height: 40,
                            width: 40,
                        }}
                    >
                        <ArrowDownwardIcon />
                    </Avatar>)}
                    {props.showvatar === "false" && props.imageName !== 'CurrencyExchangeIcon' && (<Avatar
                        display="flex"
                        sx={{
                            backgroundColor: colors.greenAccent[400],
                            height: 40,
                            width: 40,
                        }}
                    >
                        <ShowChartIcon />
                    </Avatar>)}
                    {props.showvatar === "false" && props.imageName === 'CurrencyExchangeIcon' && (<Avatar
                        display="flex"
                        sx={{
                            backgroundColor: 'success.main',
                            height: 40,
                            width: 40,
                        }}
                    >
                        <CurrencyExchangeIcon />
                    </Avatar>)}
                </Grid>
            </Grid>
        </Box>
    )
};

import { Box, DialogContentText, useTheme } from "@mui/material";
import { tokens } from "../theme/theme";

const helpTexts = {
    transactiondividend: `
    <b>(Info) Eingabe von Transaktionen.</b>
    Sie können Wertpapiere <b>kaufen</b>, <b>verkaufen</b> oder <b>Dividenden</b> buchen.

    Geben Sie das Datum und den Namen des Wertpapiers ein. Für eine erweiterte Suche des Wertpapiers verwenden Sie den <a href="/stocks">Screener.</a>
  
    Für Käufe und Verkäufe werden Währung und Kurs automatisch ermittelt. 
    Bei Dividendenzahlungen wird die letzte Zahlung automatisch übernommen. Alle eingegebenen Daten können geändert werden.

    Wenn der Schalter "Buchung auf Verrechnungskonto durchführen" aktiviert ist, wird die Buchung automatisch auf dem Verrechnungskonto verbucht. \n
  `,

    dashboard1: `
    <b>(Info) Übersicht</b>
    <b>Marktwert gesamt:</b> Der aktuelle Marktwert aller Wertpapiere und Barmittel (Cash) in Ihrem Portfolio.
    <b>Cash:</b> Der Saldo aller Konten in Ihrem Portfolio.
    <b>Wert am:</b> Der Marktwert aller Wertpapiere und Barmittel (Cash) in Ihrem Portfolio zum angegebenen Datum.
    <b>Investiert:</b> Die Summe aller Käufe abzüglich aller Verkäufe in Ihrem Portfolio im angegebenen Zeitraum. Dieser Wert kann negativ sein, wenn mehr verkauft als gekauft wurde.
    <b>Ein- und Auszahlungen:</b> Die Summe aller Ein- und Auszahlungen aller Konten in Ihrem Portfolio im angegebenen Zeitraum.
  \n`,

    dashboard2: `
    <b>(Info) Rendite</b>
    <b>Kursgewinne:</b> Die Summe aller Kursgewinne der Wertpapiere in Ihrem Portfolio im angegebenen Zeitraum.
    <b>Realisierte Gewinne:</b> Die Summe aller realisierten (Netto-)Gewinne oder Verluste im angegebenen Zeitraum.
    <b>Dividenden:</b> Die Summe aller Brutto-Dividenden oder Ausschüttungen im angegebenen Zeitraum.
    <b>Steuern und Gebühren:</b> Die Summe aller Steuern und Gebühren im angegebenen Zeitraum für Dividenden und Verkäufe.
    <b>Absolute Performance:</b> Die Summe aller Kursgewinne plus Dividenden abzüglich Steuern und Gebühren im angegebenen Zeitraum.
    
    <b>Hinweis:</b> Die Kursgewinne beziehen sich jeweils auf die Währung des Wertpapiers. Währungsschwankungen werden bei den Kursgewinnen <b>nicht</b> berücksichtigt. Daher kann die aktuelle Performance im Vergleich zum Anfangswert (Wert am:) <b>abweichen</b>.
    \n`,

    dashboard3: `
    <b>(Info) My Tops and Flops</b>
    Die Tops- und Flops zeigen Ihnen die <b>besten</b> bzw. <b>schlechtesten</b> Wertpapiere in Ihrem Portfolio für den ausgewählten Zeitraum von wahlweise "heute" oder den letzten 30 Tagen. Die Ausgabe erfolgt wahlweise in % oder absoluten Werten.
    \n`,

    dashboard: `
    <b>(Info) Dip- und Gainfinder</b>
    Der Dip- und Gainfinder ermittelt Wertpapiere aus Ihrem Portfolio mit den <b>größten</b> Abweichungen zur <b>200-Tage-Linie</b> des Kursverlaufs.
    Dies ist hilfreich, um z.B. bei Kursrückgängen "Dips" nachzukaufen oder bei Kursanstiegen "Gains" zu reduzieren.
    \n  `,

};

const HelpText = ({ viewName }) => {
    const helpText = helpTexts[viewName];
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            marginTop={4}
            pl={2}
            pr={2}
            border={theme.palette.mode === "dark" ? 0 : 0}
            borderRadius="8px"
            backgroundColor={
                theme.palette.mode === "dark"
                    ? colors.primary[400]
                    : colors.primary[100]
            }
        >
            <DialogContentText
                dangerouslySetInnerHTML={{
                    __html: helpText.replace(/\n/g, "<br />"),
                }}
            />

        </Box>
    );
};

export default HelpText;

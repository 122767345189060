import React from "react";
import { Button, IconButton } from "@mui/material";
import DateRangeIcon from '@mui/icons-material/DateRange';

const RoundButton = ({ onClick, selected, children }) => {
    return (
        <Button
            color="primary"
            onClick={onClick}
            variant={selected ? "contained" : "text"}
            size="small"
            sx={{
                borderRadius: '50%',
                minWidth: 0,
                width: 32,
                height: 32,
                p: 2,
                m: '4px',
            }}
        >
            {children}
        </Button>
    );
};

const TimeSelectButtons = ({ range, handleRangeChange, handleClickOpen }) => {
    return (
        <>
            <RoundButton onClick={() => handleRangeChange(1)} selected={range === 1}>1T</RoundButton>
            <RoundButton onClick={() => handleRangeChange(2)} selected={range === 2}>1W</RoundButton>
            <RoundButton onClick={() => handleRangeChange(3)} selected={range === 3}>1M</RoundButton>
            <RoundButton onClick={() => handleRangeChange(4)} selected={range === 4}>3M</RoundButton>
            <RoundButton onClick={() => handleRangeChange(5)} selected={range === 5}>YTD</RoundButton>
            <RoundButton onClick={() => handleRangeChange(6)} selected={range === 6}>1Y</RoundButton>
            <RoundButton onClick={() => handleRangeChange(7)} selected={range === 7}>3Y</RoundButton>
            <RoundButton onClick={() => handleRangeChange(8)} selected={range === 8}>All</RoundButton>
            <IconButton aria-label="timerange" onClick={handleClickOpen}>
                <DateRangeIcon />
            </IconButton>
        </>
    );
};

export default TimeSelectButtons;

import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, styled, Switch, Typography, useTheme } from "@mui/material";
import { formatMuiDate } from "../misc/helperfunctions";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { tokens } from "../theme/theme";
import { HeatMapIdentifier } from "../components/heatmapIdentifier";

const DivTabPayed = ({ setIsLoading, willReload }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
    twoMonthsAgo.setDate(1);
    const [filterDate, setFilterDate] = useState(moment(formatMuiDate(twoMonthsAgo)));
    const [filterBisDate, setFilterBisDate] = useState(moment(new Date()));
    // eslint-disable-next-line
    const [divNetto, setDivNetto] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [open, setOpen] = useState(false);  // Dialog für Zeitraum

    const handleClose = () => {
        setOpen(false);
        setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
    };

    const handleSave = () => {
        setOpen(false);
        setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
    }

    const Smartbox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        borderRadius: "8px",
        padding: "10px",
        paddingBottom: "20px",
    }));

    return (
        <Box id="tabDivMainPayed">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
                <Grid item xs={12} mt={1}>
                    <Grid container alignItems="center"
                        rowSpacing={0}
                        backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
                        padding={2}
                        border={theme.palette.mode === "dark" ? 0 : 0}
                        borderRadius="8px"
                        justifyContent="space-between"
                    >
                        <Grid item xs={12}>
                            <Smartbox>
                                <Stack width="100%">
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography variant="h5" mb={2}>
                                            HeatMap pro Wertpapier {divNetto ? "(Netto)" : "(Brutto)"}
                                        </Typography>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={divNetto}
                                                    onChange={() => setDivNetto(!divNetto)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Brutto / Netto"
                                        />
                                    </Stack>
                                    <HeatMapIdentifier divNetto={divNetto} setIsLoading={setIsLoading} willReload={willReload} />
                                </Stack>

                            </Smartbox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose} height={400}>
                <DialogTitle>Zeitraum wählen...</DialogTitle>
                <DialogContent dividers>
                    <Stack direction="row">
                        <DatePicker
                            value={filterDate}
                            label="von Datum"
                            format="DD.MM.YYYY"
                            onChange={(newValue) => setFilterDate(newValue)}
                            sx={{ width: 140 }}
                        />
                        <DatePicker
                            value={filterBisDate}
                            label="bis Datum"
                            format="DD.MM.YYYY"
                            onChange={(newValue) => setFilterBisDate(newValue)}
                            sx={{ width: 140 }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button color="inherit" onClick={handleClose}>
                        Abbrechen
                    </Button>
                    <Button color="inherit" onClick={handleSave}>
                        Übernehmen
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )

}

export default DivTabPayed
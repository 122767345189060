import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
  Grid, useTheme, useMediaQuery, Avatar, Stack, Typography
} from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FinDataGrid from "../components/findatagrid";
import Header from "../components/header";
import { tokens } from "../theme/theme";

const Stocks = () => {
  const [rows, setRows] = useState([]);
  // eslint-disable-next-line
  const [identifier, setIdentifier] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // eslint-disable-next-line
  const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
    defaultMatches: true,
    noSsr: false
  });
  const hasFilter = filter && filter.toString().trim() !== "";

  async function fetchRows(filter) {
    try {
      console.log("Stocks: fetchRows...");
      setIsLoading(true);
      let url = process.env.REACT_APP_API_URI + "/api/securities";
      if (filter) {
        url += "?";
      }
      if (filter) {
        url += "filter=" + filter;
      }
      // if(age) {
      //     if(name) {
      //         url += '&age=' + age;
      //     }else {
      //         url += 'age=' + age;
      //     }
      // }
      const response = await fetch(url);
      const data = await response.json();
      setRows(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchRows();
  }, []);

  const handleSearchClick = () => {
    fetchRows(filter);
  };

  const handleInputChange = (event) => {
    setFilter(event.target.value);
    handleSearchClick();
  };

  const handleClearFilter = () => {
    inputRef.current.value = "";
    setFilter("");
    fetchRows();
  };

  // Helper function
  function findRowById(id) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].id === id) {
        return rows[i];
      }
    }
    return null;
  }

  // helper function
  function findRowByName(name) {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].name === name) {
        return rows[i];
      }
    }
    return null;
  }

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 40,
      renderCell: (params) => {
        const row = findRowById(params.id);
        let imageURL = process.env.REACT_APP_API_URI + '/images/' + row.identifier + '.png';
        if (!row.logourl) {
          imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
        }
        return (
          <Avatar src={imageURL} alt={'Company Logo'}
            sx={{
              height: 30,
              width: 30
            }}
          >
          </Avatar>
        );
      }
    },
    {
      field: "name",
      headerName: "Name",
      width: smUp ? 220 : 180,
      renderCell: (params) => {
        const row = findRowByName(params.value);
        let cellClassName = "name-column--cell";
        if (row.profit < 0) {
          cellClassName = "negative-column--cell";
        }
        return (
          <Button color="inherit" variant="text" size="medium" component={Link}
            to={{
              pathname: "/detailview",
            }}
            state={{ identifier: row.identifier }}
            sx={{ m: 0, p: 0, minWidth: 0 }}
          >
            <Stack className={cellClassName} maxWidth={smUp ? 200 : 180}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
              <Typography variant="h7" noWrap>{row.isin} · {row.identifier}</Typography>
            </Stack>
          </Button>
        );
      }
    },
    {
      field: "isin",
      headerName: "ISIN",
      width: 140,
    },
    {
      field: "code",
      headerName: "Code",
      width: 100,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
    },
    {
      field: "exchange",
      headerName: "Exchange",
      width: 100,
    },
    {
      field: "currency",
      headerName: "Währung",
      width: 80,
    },
    {
      field: "type",
      headerName: "Type",
      width: 140,
    },
  ];

  return (
    <Box id="stockMainBox" m="20px" >
      <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
        <Grid item xs={3} sm={2}>
          <Header title="SCREENER" subtitle="" />
        </Grid>
        <Grid item xs={1} >
          <Box display="flex" justifyContent="center" ml={2} >
            {isLoading && (
              <CircularProgress color="inherit" />
            )}
          </Box>

        </Grid>
        <Grid item xs={4} sm={6}>
          <Box
            display="flex"
            backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
            borderRadius="8px"
            height="40px"
            padding="0px 16px"
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              ref={inputRef}
              value={filter}
              inputProps={{ spellCheck: 'false' }}
              placeholder="Search"
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearchClick();
                }
              }}
            />
            <IconButton type="button" sx={{ p: 1 }} onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
            {hasFilter && (
              <IconButton onClick={handleClearFilter}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FinDataGrid
            id="gridStocks"
            autoHeight
            density="standard"
            rowHeight={60}
            headerHeight={smUp ? 60 : 0}
            rows={rows}
            columns={columns}
            components={{
              Toolbar: smUp ? GridToolbar : '',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Stocks;

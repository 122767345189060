import { Box, Button, CircularProgress, List, ListItem, ListItemAvatar, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { tokens } from '../theme/theme';
import { UserContext } from '../theme/userContext';
import { TypoStyledNumber } from './typhoStyledNumber';


export const Topsflops = (props) => {
    const { tops } = props;
    const { user } = useContext(UserContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let label = tops ? 'TOPS' : 'FLOPS';
    const [topsflops, setTopsflops] = useState([]);
    const [modus, setModus] = useState(tops ? 'topsprz' : 'flopsprz');
    const [range, setRange] = useState(1);
    const [isLoading, setIsLoading] = useState(true); // for loading Icon

    // Fetch Tops&Flops
    async function fetchTopsflops() {
        try {
            // wenn user.p_user = "undefind", dann abbruch
            if (!user.p_user) {
                return;
            }

            setIsLoading(true);
            console.log("fetchTopsflops ");
            let puser = user?.p_user;
            let url = process.env.REACT_APP_API_URI + "/api/topsflops?puser=" + puser + "&type=" + modus + "&range=" + range;
            const response = await fetch(url);
            const data = await response.json();

            for (let index = data.length - 1; index < 4; index++) {
                const element = {};
                element.id = '';
                element.identifier = '';
                element.name = '';
                element.isin = '';
                element.profit_1d = '0';
                element.change_p = '0';

                data.push(element);
            }
            setTopsflops(data);
            setIsLoading(false);

        } catch (error) {
            console.error(error);
            setTopsflops([{}, {}]);
            setIsLoading(false);
        }
    }
    // eslint-disable-next-line
    const fetchTopsflopsCallback = useCallback(fetchTopsflops, [modus, range]);
    useEffect(() => {
        console.log("useEffect fetchTopsflopsCallback")
        fetchTopsflopsCallback();
    }, [fetchTopsflopsCallback]);

    const handleRangeChanged = () => {
        range === 1 ? setRange(30) : setRange(1);
    }

    const handleModusChanged = () => {
        let newModus = '';
        if (tops) {
            newModus = modus === 'topsprz' ? 'topsabs' : 'topsprz';
        } else {
            newModus = modus === 'flopsprz' ? 'flopsabs' : 'flopsprz';
        }
        setModus(newModus);

    };

    function getImageURL(identifier, logourl) {
        let imageURL = process.env.REACT_APP_API_URI + '/images/' + identifier + '.png';
        if (!logourl) {
            imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
        }
        return imageURL;
    }

    if (!user.p_user) {
        return <div>Anmeldung...</div>;
    }

    return (
        <Box
            {...props}
            marginTop={1}
            padding={2}
            border={theme.palette.mode === "dark" ? 0 : 0}
            borderRadius="8px"
            backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box>
                    <Typography variant="h4" mt={1} ml={1}>
                        My {label}
                    </Typography>
                </Box>
                <Stack direction="row">
                    {isLoading && (
                        <CircularProgress color="inherit" />
                    )}
                    {(!isLoading) && (
                        <Button onClick={handleRangeChanged}>{range === 1 ? 'heute' : '30 Tage'}</Button>
                    )}
                    <Button disabled={range !== 1} onClick={handleModusChanged} >Abs. / Proz.</Button>
                </Stack>
            </Stack>
            <List>
                {Array.isArray(topsflops) && topsflops.map((item, i) => (
                    <ListItem
                        divider={i < topsflops.length - 1}
                        key={`item-${i}`}
                        sx={{ marginRight: 0, paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }}
                        secondaryAction={
                            <Stack alignItems="flex-end" mt={0} >
                                <TypoStyledNumber value={item.change_p} unit='%' />
                                {range === 1 && (<TypoStyledNumber value={item.profit_1d} unit='€' />)}
                            </Stack>
                        }
                        disablePadding
                    >
                        <ListItemAvatar>
                            <img
                                alt={'Company Logo'}
                                src={getImageURL(item.identifier, item.logourl)}
                                style={{
                                    height: 30,
                                    width: 30
                                }}
                            />
                        </ListItemAvatar>
                        <Button color="inherit" variant="text" size="medium" component={Link}
                            to={{
                                pathname: "/detailview",
                            }}
                            state={{ identifier: item.identifier, portfolioid: item.id }}
                            sx={{ m: 0, p: 0, minWidth: 0 }}
                        >
                            <Stack maxWidth={140}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{item.name}</Typography>
                                <Typography variant="h7" noWrap>{item.isin} · {item.identifier}</Typography>
                            </Stack>
                        </Button>
                    </ListItem>
                ))}
            </List>
        </Box >
    )
};

import React, { forwardRef } from 'react';
import { FormControl, Link, Stack, TextField, Typography } from '@mui/material';

const TextInput = forwardRef(({ label, showQuantity, quantityValue, setQuantityFunction, ...params }, ref) => {

    // FIXME colors as of theme

    return (
        <div>
            <FormControl fullWidth>
                <Stack direction="row" spacing={0} alignItems="center" marginTop={0} >
                    <Typography sx={params.sx} variant='body1'>{label}</Typography>
                    {showQuantity > 0 && (
                        <Link href="#" marginLeft={1} underline="hover" variant='body2' onClick={() => {
                            setQuantityFunction();
                        }}>(Bestand: {quantityValue})</Link>
                    )}
                </Stack>
                <Stack direction="row" spacing={0} alignItems="center" marginTop={0} >
                    <TextField
                        variant={params.disabled ? 'filled' : 'outlined'}
                        {...params}
                        ref={ref}
                    />
                </Stack>
            </FormControl>
        </div>
    );
});

export default TextInput;

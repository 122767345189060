import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, FormControlLabel, Grid, Stack, styled, Switch, Typography, useTheme } from "@mui/material";
import { UserContext } from "../theme/userContext";
//import { formatMuiDate } from "../misc/helperfunctions";
//import moment from "moment";
import { tokens } from "../theme/theme";
import { Typoheader } from "../components/typoheader";
import { Typofield } from "../components/typofield";
import BarChartDivYear from "../components/barchartDivYear";
import { HeatMapDividends } from "../components/heatmapDividends";

const DivTabMain = ({ setIsLoading, willReload }) => {

    const { user } = useContext(UserContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
    twoMonthsAgo.setDate(1);
    // const [filterDate, setFilterDate] = useState(moment(formatMuiDate(twoMonthsAgo)));
    // const [filterBisDate, setFilterBisDate] = useState(moment(new Date()));
    const [divNetto, setDivNetto] = useState(false);
    const [limit, setLimit] = useState(true); // Limit 5 for dividends table
    const [dividends, setDividends] = useState([]);

    async function fetchPortfolioDetails() {
        try {
            let url = process.env.REACT_APP_API_URI + "/api/performance?puser=" + user.p_user;
            const response = await fetch(url);
            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error(error);
        }
    }

    // Fetch Dividends by Year
    async function fetchDividendsByYear() {
        try {
            setIsLoading(true);

            let url = '';
            url = process.env.REACT_APP_API_URI + "/api/alldividendsbyyear?puser=" + user.p_user + "&order=desc";
            console.log(url);
            const response = await fetch(url);
            const data = await response.json();
            setDividends(data);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }
    // eslint-disable-next-line
    const fetchDividendsByYearCallback = useCallback(fetchDividendsByYear, [user, willReload, limit, divNetto]);
    useEffect(() => {
        console.log("useEffect fetchDividendsByYearCallback")
        fetchDividendsByYearCallback();
        fetchPortfolioDetails();
        // eslint-disable-next-line
    }, [fetchDividendsByYearCallback]);

    // Funktion zum Begrenzen der Daten auf 5 Elemente
    const getLimitedData = (data) => {
        if (limit) {
            return data.slice(0, 5); // nur die ersten 5 Elemente des Arrays zurückgeben
        }
        return data; // sonst das gesamte Array zurückgeben
    };


    // CellClick der Dividends HeatMap, um das Filterdatum einzustellen
    /* const handleCellClick = (params) => {
        console.log("Cell clicked: ", params);
        try {
            const year = params.row.year;
            const monthIndex = parseInt(params.field.match(/\d+/)[0]);

            const startDate = new Date(year, monthIndex - 1, 1);
            const endDate = new Date(year, monthIndex, 0);

            console.log(`Zeitraum: ${startDate} bis ${endDate}`);
            setFilterDate(moment(formatMuiDate(startDate)));
            setFilterBisDate(moment(formatMuiDate(endDate)));
            setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
        } catch (error) {
            console.log(error);
        }
    } */

    const Smartbox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        borderRadius: "8px",
        padding: "10px",
        paddingBottom: "20px",
    }));

    return (
        <Box id="tabDivMainBox">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
                <Grid item xs={12} mt={1}>
                    <Smartbox>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                            <Grid item xs={12} sm={6} lg={3} mt={1}>
                                <Typoheader label="Portfolio" hint="" />
                                <Stack>
                                    <Typofield type="number" label="Einstandspreis" value={data ? (parseFloat(data.total_cost_price) + parseFloat(data.totalCashBalance)) : '???'} unit='€' />
                                    <Typofield type="number" label="Marktwert" value={data ? data.total_market_price : '???'} unit='€' />
                                    <Typofield type="stylednumber" label="Gewinn / Verlust" value={data ? data.profit_total : '???'} unit='€' bold='true' />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3} mt={1}>
                                <Typoheader label="Erhaltene Dividenden" hint="Diese Übersicht zeigt dir deine erhaltenen Dividendeneinkünfte, den Dividendenertrag als prozentualen Anteil an deinem Investment und die aktuelle Dividendenrendite deines Portfolios. Außerdem kannst du deine individuelle Dividendenrendite berechnen lassen, um deine persönliche Performance zu tracken." />
                                <Stack>
                                    <Typofield type="number" label="∑ Dividenden Brutto" value={data ? data.sum_dividends : '???'} unit='€' />
                                    <Typofield type="number" label="∑ Steuern/Gebühren" value={data ? data.sum_tax : '???'} unit='€' />
                                    <Typofield type="number" label="∑ Dividenden Netto" value={data ? data.sum_dividends_net : '???'} unit='€' bold='true' />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3} mt={1}>
                                <Typoheader label="Einnahmen pro Jahr" hint="" />
                                <Stack>
                                    <Typofield type="number" label="Div-Brutto pro Jahr" value={data ? data.gross_dividends_by_year : '???'} unit='€' />
                                    <Typofield type="number" label="Pers. Steuersatz" value={26} unit='%' />
                                    <Typofield type="number" label="Div-Netto pro Jahr" value={data ? data.gross_dividends_by_year * 0.74 : '???'} unit='€' bold='true' />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3} mt={1}>
                                <Typoheader label="Pers. Div-Rendite" hint="" />
                                <Stack>
                                    <Typofield type="number" label="auf Marktwert" value={data ? data.dividend_yield_portfolio : '???'} unit='%' />
                                    <Typofield type="number" label="auf Einstandspreis" value={data ? data.dividend_yield_portfolio_total_cost_price : '???'} unit='%' bold='true' />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Smartbox>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Smartbox minHeight="280px">
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 4, md: 8, lg: 12 }}>
                            <Grid item xs={12} mb={1}>
                                <Typography variant="h5" >
                                    {divNetto ? "Ausschüttungen (Netto)" : "Ausschüttungen (Brutto)"}
                                </Typography>
                            </Grid>
                            {divNetto && (<Grid item xs={12} mt={1}>
                                {getLimitedData(dividends).map((row) => (
                                    <Typofield type="number" label={row.year} value={row.total_net_amount} unit="€" />
                                ))}
                            </Grid>)}
                            {!divNetto && (<Grid item xs={12} mt={1}>
                                {getLimitedData(dividends).map((row) => (
                                    <Typofield type="number" label={row.year} value={row.total_gross_amount} unit="€" />
                                ))}
                            </Grid>)}
                            <Grid item xs={12} mt={1}>
                                <Stack direction="row" justifyContent="space-between">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!limit}
                                                onChange={() => setLimit(!limit)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label={"Alle anzeigen"}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={divNetto}
                                                onChange={() => setDivNetto(!divNetto)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        }
                                        label="Brutto / Netto"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Smartbox>
                </Grid>
                <Grid item xs={12} sm={8} >
                    <Smartbox height="280px">
                        <BarChartDivYear type="alldividendsbyyear" hideLabel="true" height="280px" />
                    </Smartbox>
                </Grid>
                <Grid item xs={12}>
                    <Smartbox>
                        <Stack width="100%">
                            <Typography variant="h5" mb={1}>
                                HeatMap der Ausschüttungen {divNetto ? "(Netto)" : "(Brutto)"}
                            </Typography>
                            <HeatMapDividends divNetto={divNetto} />
                        </Stack>
                    </Smartbox>
                </Grid>

            </Grid>

        </Box>
    )

}

export default DivTabMain
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Grid, IconButton, Stack, Tab, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { UserContext } from "../theme/userContext";
import FinDataGrid from "../components/findatagrid";
import { Link } from "react-router-dom";
import { formatDate, formatMuiDate, formatNumber, getCurrencySymbol } from "../misc/helperfunctions";

import moment from "moment";
import DoneIcon from '@mui/icons-material/Done';
import { tokens } from "../theme/theme";
import PaidIcon from '@mui/icons-material/Paid';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TimeSelectDialog from "./dialogs/timeSelectDialog";

const DivTabBook = ({ setIsLoading, willReload }) => {

    const { user } = useContext(UserContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [rows, setRows] = useState([]);  // neue, nicht gebuchte Dividenden
    const [bookedDiv, setBookedDiv] = useState([]);  // gebuchte Dividenden
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
        defaultMatches: true,
        noSsr: false
    });
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
    twoMonthsAgo.setDate(1);
    const [filterDate, setFilterDate] = useState(() => {
        const storedDate = sessionStorage.getItem("filterDate");
        return storedDate
            ? moment(JSON.parse(storedDate))
            : moment(formatMuiDate(twoMonthsAgo));
    });
    const [filterBisDate, setFilterBisDate] = useState(() => {
        const storedDate = sessionStorage.getItem("filterBisDate");
        return storedDate
            ? moment(JSON.parse(storedDate))
            : moment(new Date());
    });
    const [tabValue, setTabValue] = useState(() => {
        const storedTab = sessionStorage.getItem('tabDiv');
        return storedTab ? JSON.parse(storedTab) : 0;
    });
    const [scrollPosition, setScrollPosition] = useState(0);
    const [open, setOpen] = useState(false);  // Dialog für Zeitraum

    /** Set ScrollPosition in Mobil at´fter Change mobile view */
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, scrollPosition);
        }, 5)
    }, [scrollPosition]);

    // Fetch Dividends
    async function fetchDividends() {
        try {
            setIsLoading(true);

            const date = moment.unix(filterDate / 1000); // divide by 1000 to convert from milliseconds to seconds
            const formattedDate = date.format('YYYY-MM-DD'); // format the date as "TT.MM.JJJJ"
            console.log(formattedDate); // output: "31.12.2022"
            const bisdate = moment.unix(filterBisDate / 1000); // divide by 1000 to convert from milliseconds to seconds
            const formattedBisDate = bisdate.format('YYYY-MM-DD'); // format the date as "TT.MM.JJJJ"

            // Neue, nicht gebuchte Dividenden laden
            let url = '';
            url = process.env.REACT_APP_API_URI + "/api/alldividends?puser=" + user.p_user + "&filterdate=" + formattedDate + "&filterbisdate=" + formattedBisDate;
            console.log(url);
            const response = await fetch(url);
            const data = await response.json();
            // für diesen UseCase nur die nicht gebuchten übernehmen
            const filteredArray = data.filter(item => item.payed === "0");
            setRows(filteredArray);

            // Gebuchte Dividenden laden
            url = process.env.REACT_APP_API_URI + "/api/bookeddividends?puser=" + user.p_user + "&filterdate=" + formattedDate + "&filterbisdate=" + formattedBisDate;
            console.log(url);
            const response2 = await fetch(url);
            const data2 = await response2.json();
            setBookedDiv(data2);

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }
    // eslint-disable-next-line
    const fetchDividendsCallback = useCallback(fetchDividends, [user, filterDate, filterBisDate, willReload]);
    useEffect(() => {
        console.log("useEffect fetchDividendsCallback")
        fetchDividendsCallback();
        // eslint-disable-next-line
        // eslint-disable-next-line
    }, [fetchDividendsCallback]);


    // Helper function
    function findRowById(id) {
        if (tabValue === 0) {
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].id === id) {
                    return rows[i];
                }
            }
        } else {
            for (let i = 0; i < bookedDiv.length; i++) {
                if (bookedDiv[i].id === id) {
                    return bookedDiv[i];
                }
            }
        }
        return null;
    }

    // helper function
    function findRowByName(name) {
        if (tabValue === 0) {
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].name === name) {
                    return rows[i];
                }
            }
        } else {
            for (let i = 0; i < bookedDiv.length; i++) {
                if (bookedDiv[i].name === name) {
                    return bookedDiv[i];
                }
            }
        }
        return null;
    }

    let columns = [
        {
            field: "logo",
            headerName: "Logo",
            width: 40,
            renderCell: (params) => {
                const row = findRowById(params.id);
                let imageURL = process.env.REACT_APP_API_URI + '/images/' + row.identifier + '.png';
                if (!row.logourl) {
                    imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
                }
                return (
                    <Avatar src={imageURL} alt={'Company Logo'}
                        sx={{
                            height: 30,
                            width: 30
                        }}
                    >
                    </Avatar>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            width: smUp ? 220 : 180,
            renderCell: (params) => {
                const row = findRowByName(params.value);
                let cellClassName = "name-column--cell";
                if (row.profit < 0) {
                    cellClassName = "negative-column--cell";
                }
                return (
                    <Button color="inherit" variant="text" size="medium" component={Link}
                        to={{
                            pathname: "/detailview",
                        }}
                        state={{ identifier: row.identifier }}
                        sx={{ m: 0, p: 0, minWidth: 0 }}
                    >
                        <Stack className={cellClassName} maxWidth={smUp ? 200 : 180}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
                            <Typography variant="h7" noWrap>{row.isin} · {row.identifier}</Typography>
                        </Stack>
                    </Button>
                );
            }
        },
        {
            field: "exday",
            headerName: "Ex-Tag",
            width: 100,
            headerAlign: "center",
            type: "date",
            valueFormatter: (params) => {
                const valueFormatted = formatDate(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "paymentdate",
            headerName: "Zahltag",
            width: 100,
            type: "date",
            headerAlign: "center",
            valueFormatter: (params) => {
                const valueFormatted = formatDate(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "quantity",
            headerName: "Bestand",
            width: 80,
            align: "right",
            headerAlign: "center",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "pershare",
            headerName: "pro Aktie",
            width: 80,
            headerAlign: "center",
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value, 3);
                return `${valueFormatted}`;
            },
        },
        {
            field: "amount",
            headerName: "Betrag",
            width: 120,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                const row = findRowById(params.id);
                const val = formatNumber(params.value) + " " + getCurrencySymbol(row.currency);
                return (
                    <div style={{ textAlign: 'right' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{val}</Typography>
                    </div>
                );
            }
        },
        {
            field: "action",
            headerName: "Aktion",
            width: 180,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const row = findRowById(params.id);
                const divDatas = {};
                divDatas.transactionDate = row.paymentdate;
                divDatas.quantity = row.quantity;
                divDatas.pershare = row.pershare;
                return (
                    <Button disabled={row.payed !== '0'} color="inherit" variant="outlined" size="small" component={Link}
                        to={{
                            pathname: "/transactiondividend",
                        }}
                        state={{ identifier: row.identifier, type: 'DIVIDEND', divDatas: divDatas }}
                    >
                        Buchen
                    </Button>
                );
            }
        },
    ];

    let columnsBooked = [
        {
            field: "logo",
            headerName: "Logo",
            width: 40,
            renderCell: (params) => {
                const row = findRowById(params.id);
                let imageURL = process.env.REACT_APP_API_URI + '/images/' + row.identifier + '.png';
                if (!row.logourl) {
                    imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
                }
                return (
                    <Avatar src={imageURL} alt={'Company Logo'}
                        sx={{
                            height: 30,
                            width: 30
                        }}
                    >
                    </Avatar>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            width: smUp ? 220 : 180,
            renderCell: (params) => {
                const row = findRowByName(params.value);
                let cellClassName = "name-column--cell";
                if (row.profit < 0) {
                    cellClassName = "negative-column--cell";
                }
                return (
                    <Button color="inherit" variant="text" size="medium" component={Link}
                        to={{
                            pathname: "/detailview",
                        }}
                        state={{ identifier: row.identifier }}
                        sx={{ m: 0, p: 0, minWidth: 0 }}
                    >
                        <Stack className={cellClassName} maxWidth={smUp ? 200 : 180}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
                            <Typography variant="h7" noWrap>{row.isin} · {row.identifier}</Typography>
                        </Stack>
                    </Button>
                );
            }
        },
        {
            field: "paymentdate",
            headerName: "Datum",
            width: 100,
            headerAlign: "center",
            type: "date",
            valueFormatter: (params) => {
                const valueFormatted = formatDate(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "quantity",
            headerName: "Bestand",
            width: 80,
            align: "right",
            headerAlign: "center",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "pershare",
            headerName: "pro Aktie",
            width: 80,
            type: "number",
            headerAlign: "center",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value, 3);
                return `${valueFormatted}`;
            },
        },
        {
            field: "amount",
            headerName: "Betrag Brutto",
            width: 120,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                const val = formatNumber(params.value) + " €";
                return (
                    <div style={{ textAlign: 'right' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{val}</Typography>
                    </div>
                );
            }
        },
        {
            field: "net_amount",
            headerName: "Netto",
            width: 120,
            align: "right",
            headerAlign: "right",
            renderCell: (params) => {
                const val = formatNumber(params.value) + " €";
                return (
                    <div style={{ textAlign: 'right' }}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{val}</Typography>
                    </div>
                );
            }
        },
        {
            field: "payed",
            headerName: "gebucht",
            width: 80,
            align: "center",
            renderCell: (params) => {
                const row = findRowById(params.id);
                return (
                    row.payed !== '0' && (<Avatar alt={'Zahlungseingang gebucht'}
                        sx={{
                            backgroundColor: colors.greenAccent[300],
                            height: 24,
                            width: 24
                        }}
                    >
                        <DoneIcon />
                    </Avatar>)
                );
            }
        },
    ];

    let columnsMobil = [
        {
            field: "logo",
            headerName: "Logo",
            width: 40,
            renderCell: (params) => {
                const row = findRowById(params.id);
                let imageURL = process.env.REACT_APP_API_URI + '/images/' + row.identifier + '.png';
                if (!row.logourl) {
                    imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
                }
                return (
                    <Avatar src={imageURL} alt={'Company Logo'}
                        sx={{
                            height: 30,
                            width: 30
                        }}
                    >
                    </Avatar>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            width: smUp ? 220 : 180,
            renderCell: (params) => {
                const row = findRowById(params.id);
                let cellClassName = "name-column--cell";
                if (row.profit < 0) {
                    cellClassName = "negative-column--cell";
                }
                return (
                    <Button color="inherit" variant="text" size="medium" component={Link}
                        to={{
                            pathname: "/detailview",
                        }}
                        state={{ identifier: row.identifier }}
                        sx={{ m: 0, p: 0, minWidth: 0 }}
                    >
                        <Stack maxWidth={smUp ? 200 : 160}>
                            <Typography className={cellClassName} variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
                            <Stack direction="row">
                                <Typography variant="h7" noWrap >{formatNumber(row.quantity)} X</Typography>
                                <Typography marginLeft="2px" variant="h7" noWrap fontWeight="bold"> {formatNumber(row.pershare, 3)} {getCurrencySymbol(row.currency)} </Typography>
                            </Stack>
                            <Typography variant="h7" noWrap >Zahltag: {formatDate(row.paymentdate)}</Typography>
                        </Stack>
                    </Button>
                );
            }
        },
        {
            field: "action",
            headerName: "Aktion",
            width: 120,
            renderCell: (params) => {
                const row = findRowById(params.id);
                const val = formatNumber(row.amount) + " " + getCurrencySymbol(row.currency);
                const divDatas = {};
                divDatas.transactionDate = row.paymentdate;
                divDatas.quantity = row.quantity;
                divDatas.pershare = row.pershare;
                return (
                    <Button disabled={row.payed !== '0'} color="success" variant="outlined" size="small" component={Link}
                        startIcon={<PaidIcon />}
                        to={{
                            pathname: "/transactiondividend",
                        }}
                        state={{ identifier: row.identifier, type: 'DIVIDEND', divDatas: divDatas }}
                    >
                        {val}
                    </Button >
                );
            }
        },
    ];

    let columnsMobilBooked = [
        {
            field: "logo",
            headerName: "Logo",
            width: 40,
            renderCell: (params) => {
                const row = findRowById(params.id);
                let imageURL = process.env.REACT_APP_API_URI + '/images/' + row.identifier + '.png';
                if (!row.logourl) {
                    imageURL = process.env.REACT_APP_API_URI + '/images/error.png';
                }
                return (
                    <Avatar src={imageURL} alt={'Company Logo'}
                        sx={{
                            height: 30,
                            width: 30
                        }}
                    >
                    </Avatar>
                );
            }
        },
        {
            field: "name",
            headerName: "Name",
            width: smUp ? 220 : 180,
            renderCell: (params) => {
                const row = findRowById(params.id);
                let cellClassName = "name-column--cell";
                if (row.profit < 0) {
                    cellClassName = "negative-column--cell";
                }
                return (
                    <Button color="inherit" variant="text" size="medium" component={Link}
                        to={{
                            pathname: "/detailview",
                        }}
                        state={{ identifier: row.identifier }}
                        sx={{ m: 0, p: 0, minWidth: 0 }}
                    >
                        <Stack maxWidth={smUp ? 200 : 160}>
                            <Typography className={cellClassName} variant="body2" sx={{ fontWeight: 'bold' }} noWrap>{params.value}</Typography>
                            <Stack direction="row">
                                <Typography variant="h7" noWrap >{formatNumber(row.quantity)} X</Typography>
                                <Typography marginLeft="2px" variant="h7" noWrap fontWeight="bold"> {formatNumber(row.pershare, 3)} {getCurrencySymbol(row.currency)} </Typography>
                            </Stack>
                            <Typography variant="h7" noWrap >Datum: {formatDate(row.paymentdate)}</Typography>
                        </Stack>
                    </Button>
                );
            }
        },
        {
            field: "action",
            headerName: "Aktion",
            width: 120,
            renderCell: (params) => {
                const row = findRowById(params.id);
                const val = formatNumber(row.amount) + " €";
                const valNet = formatNumber(row.net_amount) + " €";
                const divDatas = {};
                divDatas.transactionDate = row.paymentdate;
                divDatas.quantity = row.quantity;
                divDatas.pershare = row.pershare;
                return (
                    <Stack maxWidth={smUp ? 200 : 160}>
                        <Typography variant="body2" noWrap fontWeight="bold">Brutto: {val}</Typography>
                        <Typography variant="body2" noWrap >Netto: {valNet}</Typography>
                    </Stack>
                );
            }
        },
    ];

    // component Tabpanel to make things visible in the tab area
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    // Diese Box enthaelt den Tab Client Context
                    <Box sx={{ p: 0 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    // helper function
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    // change tab
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        sessionStorage.setItem('tabDiv', JSON.stringify(newValue));
        setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
    };

    const handleClickOpen = () => {
        setOpen(true);
        setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
    };

    const handleClose = () => {
        setOpen(false);
        setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
    };

    const handleSave = (updatedFromDate, updatedToDate) => {
        console.log("Neues von Datum:", updatedFromDate);
        setFilterDate(updatedFromDate);
        sessionStorage.setItem("filterDate", JSON.stringify(updatedFromDate.toISOString()));

        console.log("Neues bis Datum:", updatedToDate);
        setFilterBisDate(updatedToDate);
        sessionStorage.setItem("filterBisDate", JSON.stringify(updatedToDate.toISOString()));

        setScrollPosition(window.pageYOffset === scrollPosition ? window.pageYOffset + 1 : window.pageYOffset);
    }

    return (
        <Box id="tabDivMainBox">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
                <Grid item xs={12} mt={1}>
                    <Grid container alignItems="center"
                        rowSpacing={0}
                        backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
                        padding={2}
                        border={theme.palette.mode === "dark" ? 0 : 0}
                        borderRadius="8px"
                        justifyContent="space-between"
                    >

                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" justifyContent={smUp ? "flex-start" : "space-between"}>
                                <Typography variant="h5">
                                    {smUp ? "Ausschüttungen buchen" : "Div. buchen"}
                                </Typography>
                                <Typography variant="body2" ml={smUp ? 2 : 0}>
                                    {formatDate(filterDate)} -  {formatDate(filterBisDate)}
                                </Typography>
                                <IconButton aria-label="Change dates..." onClick={handleClickOpen}>
                                    <DateRangeIcon />
                                </IconButton>
                            </Stack>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} marginTop={0}>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="security tabs" textColor="secondary"
                            indicatorColor="secondary"
                            sx={{
                                backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
                                "& .MuiTab-root": {
                                    minWidth: 180,
                                },
                            }}>
                            <Tab label="NEU - nicht gebucht"  {...a11yProps(0)} />
                            <Tab label="Gebucht"  {...a11yProps(1)} />
                            {smUp && (<Box sx={{ flexGrow: 1 }} />)}
                            {smUp && (<Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button component={Link}
                                    to={{
                                        pathname: "/transactiondividend",
                                    }}
                                    state={{ type: 'DIVIDEND' }}
                                >manuell buchen</Button>
                            </Box>
                            )}
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <FinDataGrid
                                id="gridDividends"
                                autoHeight
                                density="comfortable"
                                pageSize={25}
                                rowHeight={60}
                                headerHeight={smUp ? 60 : 0}
                                rows={rows}
                                columns={smUp ? columns : columnsMobil}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <FinDataGrid
                                id="gridBookedDividends"
                                autoHeight
                                density="comfortable"
                                pageSize={25}
                                rowHeight={60}
                                headerHeight={smUp ? 60 : 0}
                                rows={bookedDiv}
                                columns={smUp ? columnsBooked : columnsMobilBooked}
                            />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Grid>
            <TimeSelectDialog
                open={open}
                handleClose={handleClose}
                fromDate={filterDate}
                toDate={filterBisDate}
                onSave={handleSave}
            />
        </Box>
    )

}

export default DivTabBook
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const WelcomeDialog = ({ open, onClose }) => {
    const navigate = useNavigate();

    const handleGoToTransactionDividend = () => {
        navigate("/TransactionDividend");
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Willkommen bei Finalyser!</DialogTitle>
            <DialogContent>
                <Typography gutterBottom>
                    Vielen Dank, dass Sie sich für Finalyser für Ihre Portfolio-Verwaltung entschieden haben.
                </Typography>
                <Typography gutterBottom>
                    Da Ihr Portfolio derzeit leer ist, wollen wir mit Ihrem ersten Kauf beginnen.
                </Typography>
                <Typography gutterBottom>
                    Klicken Sie auf den Link unten, um zur Transaktionsseite zu gelangen:
                </Typography>
                <Link component="button" variant="body1" onClick={handleGoToTransactionDividend}>
                    Transaktionsseite
                </Link>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Schließen</Button>
            </DialogActions>
        </Dialog>
    );
};

export default WelcomeDialog;

import { Box, CircularProgress, Grid, IconButton, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
//import GaugeChart from 'react-gauge-chart'
import RefreshIcon from '@mui/icons-material/Refresh';
import Header from "../components/header";
import { Topsflops } from "../components/topsflops";
//import { formatMuiDate } from "../misc/helperfunctions";
import { UserContext } from "../theme/userContext";
import BarChartDipGainFinder from "../components/barchartDipGainFinder";
import { tokens } from "../theme/theme";
import { formatDate, formatMuiDate, formatNumber, isSameDate } from "../misc/helperfunctions";
import { TypoStyledNumber2 } from "../components/typoStyledNumber2";
import TimeSelectDialog from "./dialogs/timeSelectDialog";
import TimeSelectButtons from "../components/timeSelectButtons";
import WelcomeDialog from "./dialogs/welcomeDialog";
import HelpText from "../components/helpText";
// Doko: https://www.npmjs.com/package/react-gauge-chart

const Feed = () => {

    const { user } = useContext(UserContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [performanceData, setPerformanceData] = useState({});
    const [firstBuyDate, setFirstBuyDate] = useState(new Date('2000-01-01'));
    const sDate = new Date();
    sDate.setMonth(0);
    sDate.setDate(1);
    const [startDate, setStartDate] = useState(sDate);
    const [endDate, setEndDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true); // for loading Icon
    const [isLoadingPerf, setIsLoadingPerf] = useState(true); // for loading Icon
    const [willReload, setWillreload] = useState(false);
    const [range, setRange] = useState(() => {
        const storedRange = sessionStorage.getItem('dashRange');
        return storedRange ? JSON.parse(storedRange) : 5;  // über die ButtonGroup einzustellenden range. Default: YTD
    });
    // eslint-disable-next-line
    const [d30Change, setD30Change] = useState(0);
    // eslint-disable-next-line
    const smUp = useMediaQuery((theme) => theme.breakpoints.up('sm'), {
        defaultMatches: true,
        noSsr: false
    });
    const [openTimeSelectDialog, setOpenTimeSelectDialog] = useState(false);
    const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);

    async function fetchPortfolioDetails() {
        try {

            // wenn user.p_user = "undefind", dann abbruch
            if (!user.p_user) {
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            let url = process.env.REACT_APP_API_URI + "/api/performance?puser=" + user.p_user;
            const response = await fetch(url);
            const data = await response.json();
            setData(data);

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }

    async function fetchPerformanceDatas() {
        try {

            // wenn user.p_user = "undefind", dann abbruch
            if (!user.p_user) {
                setIsLoadingPerf(false);
                return;
            }

            setIsLoadingPerf(true);
            let startDataDate = new Date(startDate);

            /** Load firstbuydate */
            let url = process.env.REACT_APP_API_URI + "/api/firstbuydate?puser=" + user.p_user;
            const resp3 = await fetch(url);
            const data3 = await resp3.json();
            console.log("Data3 from API: ", data3, user.p_user);

            if (!data3.firstbuydate) {
                console.log("Open welcome dialog");
                setOpenWelcomeDialog(true);
            } else {
                const firstBuyDateDate = new Date(data3.firstbuydate);
                setFirstBuyDate(firstBuyDateDate);

                if (firstBuyDateDate > startDate) {
                    //setStartDate(firstBuyDateDate);
                    startDataDate = firstBuyDateDate;
                }
            }

            /** Load perfomanceData */
            url = process.env.REACT_APP_API_URI + "/api/performanceperdate?puser=" + user.p_user + "&startdate=" + formatMuiDate(startDataDate) + "&enddate=" + formatMuiDate(endDate);
            const resp2 = await fetch(url);
            const data2 = await resp2.json();
            console.log(data2);
            setPerformanceData(data2);

            setIsLoadingPerf(false);
        } catch (error) {
            console.error(error);
            setIsLoadingPerf(false);
        }
    }

    /*   function percentChange(a, b) {
          return ((b - a) / a) * 100;
      } */

    /*
  async function fetchTrend30d() {
      try {
            const d = new Date();
           d.setDate(d.getDate() - 30);
           let url = process.env.REACT_APP_API_URI + "/api/marketvalueperdate?puser=" + user.p_user + "&date=" + formatMuiDate(d);
           console.log(url);
           const response = await fetch(url);
           const marketprice = await response.json();
           console.log(marketprice);
 
           // calculate trend
           if (data) {
               const change = percentChange(marketprice.market_price, data.total_market_price);
               console.log(change);
               setD30Change(change);
           }
 
      } catch (error) {
          console.error(error);
      }
  }*/

    /*  useEffect(() => {
         console.log("useEffect dashboard 1");
         fetchPortfolioDetails();
         //fetchTrend30d();
         // eslint-disable-next-line
     }, [user, willReload]); */

    /* useEffect(() => {
        console.log("useEffect dashboard 2");
        const firstBuyDateDate = new Date(firstBuyDate);
        if (firstBuyDateDate > startDate) {
            setStartDate(firstBuyDateDate);
        }
        // fetchPortfolioDetails();
        // fetchPerformanceDatas();
        // eslint-disable-next-line
    }, [startDate]); */

    const reloadCallback = useCallback(setTimeRange, [range]);
    useEffect(() => {
        console.log("useEffect dashboard 3 -> timerange");
        reloadCallback();
    }, [reloadCallback]);

    const fetchPortfolioDetailsCallback = useCallback(fetchPortfolioDetails, [startDate, user, willReload]);
    useEffect(() => {
        console.log("useEffect fetchPortfolioDetailsCallback");
        fetchPortfolioDetailsCallback();
    }, [fetchPortfolioDetailsCallback]);

    const fetchPerformanceDatasCallback = useCallback(fetchPerformanceDatas, [startDate, user, willReload]);
    useEffect(() => {
        console.log("useEffect fetchPerformanceDatasCallback");
        fetchPerformanceDatasCallback();
    }, [fetchPerformanceDatasCallback]);

    /*  <Box flex={5} p={2} >Dashboard
              <GaugeChart id="gauge-chart1" percent={0.86} colors={["#FF0000", "#FBFF01", "#00FF00"]} />
          </Box>  */

    // Funktion zum Einstellen des Zeitraums
    const handleRangeChange = (range) => {
        setRange(range);
        sessionStorage.setItem('dashRange', JSON.stringify(range));
    };

    // Funktion zum Einstellen des Zeitraums
    function setTimeRange() {
        const d = new Date();

        const oneDayAgo = Date.now() - 1 * 24 * 60 * 60 * 1000;
        const oneWeekAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
        const oneMonthAgo = Date.now() - 30 * 24 * 60 * 60 * 1000;
        const threeMonthsAgo = Date.now() - 90 * 24 * 60 * 60 * 1000;
        const oneYearAgo = Date.now() - 365 * 24 * 60 * 60 * 1000;
        const threeYearsAgo = Date.now() - 365 * 3 * 24 * 60 * 60 * 1000;
        const all = Date.now() - 365 * 10 * 24 * 60 * 60 * 1000;  // Fixme to first invenstment date

        switch (range) {
            case 1:
                setStartDate(new Date(oneDayAgo));
                setEndDate(new Date());
                break;
            case 2:
                setStartDate(new Date(oneWeekAgo));
                setEndDate(new Date());
                break;
            case 3:
                setStartDate(new Date(oneMonthAgo));
                setEndDate(new Date());
                break;
            case 4:
                setStartDate(new Date(threeMonthsAgo));
                setEndDate(new Date());
                break;
            case 5: // YTD
                //Set startdate to 1.1. of current year
                d.setMonth(0);
                d.setDate(1);
                setStartDate(d);
                setEndDate(new Date());
                break;
            case 6:
                setStartDate(new Date(oneYearAgo));
                setEndDate(new Date());
                break;
            case 7:
                setStartDate(new Date(threeYearsAgo));
                setEndDate(new Date());
                break;
            case 8:
                setStartDate(new Date(firstBuyDate));
                setEndDate(new Date());
                break;
            case 9:
                // TimeDialog active...
                break;
            default:
                setStartDate(new Date(all));
                setEndDate(new Date());
                break;
        }
    }

    const handleClickOpen = () => {
        console.log("handleClickOpen");
        setOpenTimeSelectDialog(true);
    };
    const handleClose = () => {
        setOpenTimeSelectDialog(false);
    };
    const handleSave = (updatedFromDate, updatedToDate) => {
        console.log("Neues von Datum:", updatedFromDate);
        setStartDate(new Date(updatedFromDate));

        console.log("Neues bis Datum:", updatedToDate);
        setEndDate(updatedToDate);

        handleRangeChange(9);
    }
    const handleCloseWelcomeDialog = () => {
        setOpenWelcomeDialog(false);
    };

    const Smartbox = styled(Box)(({ theme }) => ({
        display: 'flex',
        backgroundColor: theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100],
        borderRadius: "8px",
        padding: "10px",
        paddingBottom: "20px",
    }));

    if (!user.p_user) {
        return <div>Anmeldung...</div>;
    }

    return (
        <Box id="dashboardMainBox" m="20px" >
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} alignItems="center">
                <Grid item xs={3} sm={2}>
                    <Header title="DASHBOARD" subtitle="" />
                </Grid>
                <Grid item xs={1} >
                    <Box display="flex" justifyContent="center" ml={4} >
                        {(isLoading || isLoadingPerf) && (
                            <CircularProgress color="inherit" />
                        )}
                        {(!isLoading && !isLoadingPerf) && (
                            <IconButton ml={2} size="large" aria-label="refresh" onClick={setWillreload}>
                                <RefreshIcon fontSize="large" />
                            </IconButton>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={8} >
                </Grid>

                <Grid item xs={12} >
                    <Box height={40} mt={0} mr={0} ml={0} mb={0} color="inherit">
                        {/* <ButtonGroup size="small" variant="outlined" aria-label="text button group" color="primary"> */}
                        <Stack direction="row">
                            <TimeSelectButtons
                                range={range}
                                handleRangeChange={handleRangeChange}
                                handleClickOpen={handleClickOpen}
                            />
                        </Stack>
                        {/* </ButtonGroup> */}
                    </Box>
                </Grid>

                <Grid item xs={4} lg={3} >
                    <Box
                        marginTop={1}
                        padding={2}
                        border={theme.palette.mode === "dark" ? 0 : 0}
                        borderRadius="8px"
                        backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
                    >
                        <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h5" display="block"
                        >
                            Marktwert gesamt
                        </Typography>
                        <Typography
                            color={colors.greenAccent[300]}
                            variant="h4"
                        >
                            {formatNumber(data.total_market_price) + " €"}
                        </Typography>
                        <Stack direction="row" mt={2} spacing={1} alignItems="center" justifyContent="space-between" >
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                Davon Cash:
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {formatNumber(data.totalCashBalance) + ' €'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" mt={0} mb={1} spacing={1} alignItems="center" justifyContent="space-between" >
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                Cash-Quote:
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {formatNumber(data.totalCashPrz) + ' %'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" mt={1} spacing={1} alignItems="center" justifyContent="space-between" >
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                Wert am {formatDate(startDate)}:
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {formatNumber(performanceData.total_value_start) + ' €'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" mt={0} spacing={1} alignItems="center" justifyContent="space-between" >
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                Investiert:
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {formatNumber(performanceData.sum_investigation) + ' €'}
                            </Typography>
                        </Stack>
                        <Stack direction="row" mt={0} spacing={1} alignItems="center" justifyContent="space-between" >
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                Ein- Auszahlungen:
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {formatNumber(performanceData.performance_neutral_movement) + ' €'}
                            </Typography>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={4} lg={3} >
                    <Box
                        marginTop={1}
                        padding={2}
                        border={theme.palette.mode === "dark" ? 0 : 0}
                        borderRadius="8px"
                        backgroundColor={theme.palette.mode === "dark" ? colors.primary[400] : colors.primary[100]}
                    >
                        <Stack direction="row" mt={0} mb={2} spacing={1} alignItems="center" >
                            <Typography
                                color="textPrimary"
                                variant="h5" display="block"
                            >
                                Rendite
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h7" display="block"
                                sx={{ fontStyle: 'italic' }}
                            >
                                {formatDate(startDate)} - {formatDate(endDate)}
                            </Typography>
                        </Stack>

                        <Grid container spacing={1} justifyContent="flex-end">
                            <Grid item xs={5}>
                                <Typography color="textPrimary" variant="body1">
                                    Kursgewinne:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TypoStyledNumber2 value={performanceData.profit_total} unit='€' />
                            </Grid>
                            <Grid item xs={3}>
                                <TypoStyledNumber2 value={performanceData.profit_total_p} unit='%' showArrow />
                            </Grid>

                            <Grid item xs={5}>
                                <Typography color="textPrimary" variant="body1">
                                    Realisiert:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TypoStyledNumber2 value={performanceData.realized_gains_losses} unit='€' />
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography color="textPrimary" variant="body1">
                                    Dividenden (Brutto):
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TypoStyledNumber2 value={performanceData.sum_dividends} unit='€' />
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>

                            <Grid item xs={5}>
                                <Typography color="textPrimary" variant="body1">
                                    Steuern und Gebühren:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TypoStyledNumber2 value={-1 * performanceData.sum_tax} unit='€' />
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography color="textPrimary" variant="body1" sx={{ fontWeight: 'bold' }}>
                                    Abs. Performance:
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <TypoStyledNumber2 value={performanceData.sum_abs_performance} unit='€' bold />
                            </Grid>
                            <Grid item xs={3} >
                                <TypoStyledNumber2 value={performanceData.abs_performance_prz} unit='%' bold showArrow />
                            </Grid>
                            {!isSameDate(endDate, new Date()) && (<> <Grid item xs={5}>
                                <Typography color="textPrimary" variant="body1">
                                    Wert am {formatDate(endDate)}:
                                </Typography>
                            </Grid>
                                <Grid item xs={4}>
                                    <TypoStyledNumber2 value={performanceData.total_value_end} unit='€' />
                                </Grid>
                                <Grid item xs={3}>
                                </Grid></>)}
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                </Grid>
                <Grid item xs={4} >
                    <Topsflops tops='true' />
                </Grid>
                <Grid item xs={4} >
                    <Topsflops />
                </Grid>
                <Grid item xs={4} >
                    {/*  <Cardgauge percent={0.50} /> */}
                </Grid>
                <Grid item xs={4} sm={8} >
                    <Smartbox height="400px">
                        {(isLoading || isLoadingPerf) && (
                            <CircularProgress color="inherit" />
                        )}
                        {(!isLoading && !isLoadingPerf) && (

                            <BarChartDipGainFinder height="400px" />

                        )}
                    </Smartbox>
                </Grid>
            </Grid>
            <HelpText viewName='dashboard1' />
            <HelpText viewName='dashboard2' />
            <HelpText viewName='dashboard3' />
            <HelpText viewName='dashboard' />
            <TimeSelectDialog
                open={openTimeSelectDialog}
                handleClose={handleClose}
                fromDate={startDate}
                toDate={endDate}
                onSave={handleSave}
            />
            <WelcomeDialog
                open={openWelcomeDialog}
                onClose={handleCloseWelcomeDialog}
            />
        </Box>

    )

}

export default Feed
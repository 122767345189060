import { Box, Stack, Typography, useTheme } from "@mui/material";
import { formatNumber } from "../misc/helperfunctions";
import { tokens } from "../theme/theme";
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

export const TypoStyledNumber2 = (props) => {
    const { value, unit } = props;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const prefix = value < 0 ? '' : '+';
    let color = colors.greenAccent[300]; // colors.greenAccent[200];
    if (value && Number(value) < 0) {
        color = colors.redAccent[300]; //colors.redAccent[200];
    }
    const stylednumber = prefix + '' + formatNumber(value) + ' ' + unit;
    let isPositive = Number(value) >= 0;

    if (props.showbox) {
        return (
            <Box sx={{ display: 'inline-block', bgcolor: color, pl: 1, pr: 1, borderRadius: '4px' }}>
                <Typography
                    color='#FFFFFF'
                    variant="body1"
                    sx={{ fontWeight: props.bold ? 'bold' : '' }}
                    textAlign="right"
                >
                    {stylednumber}
                </Typography>
            </Box>
        );
    }

    if (props.showArrow) {
        return (
            <Stack direction="row" alignItems="center" justifyContent="flex-end">
                {isPositive && (<NorthIcon fontSize="small" color="success" sx={{ marginRight: '2px', marginLeft: '0px', width: 14, height: 14, }} />)}
                {!isPositive && (<SouthIcon fontSize="small" color="error" sx={{ marginRight: '2px', marginLeft: '0px', width: 14, height: 14, }} />)}
                <Typography variant="body1" color={color} noWrap >{formatNumber(value < 0 ? value * -1 : value) + ' ' + unit}</Typography>
            </Stack>
        );
    }

    return (
        <Typography
            color={color}
            variant="body1"
            sx={{ fontWeight: props.bold ? 'bold' : '' }}
            textAlign="right"
        >
            {stylednumber}
        </Typography>
    );
}
import React, { useContext, useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    Button,
    FormControl,
    Select,
    MenuItem,
} from '@mui/material';
import { UserContext } from "../../theme/userContext";

const AccountDialog = ({ open, onClose, account, onSave }) => {
    const { user } = useContext(UserContext);
    const [name, setName] = useState(account?.name || "");
    const [currencyCode, setCurrencyCode] = useState(
        account?.currency_code || "EUR"
    );
    const [description, setDescription] = useState(account?.description || "");

    useEffect(() => {
        console.log("AccountDialog open with " + account?.name)
        if (account) {
            setName(account.name);
            setCurrencyCode(account.currency_code);
            setDescription(account.description);
        } else {
            setName(null);
            setCurrencyCode("EUR");
            setDescription(null);
        }
        // eslint-disable-next-line
    }, [open]);


    async function upsertAccount() {

        let url = process.env.REACT_APP_API_URI + "/api/createaccount";

        await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: account?.id,
                name: name,
                p_user: user.p_user,
                currency_code: currencyCode,
                description: description,
            }),
        })
            .then((response) => {
                if (response.status !== 200) {
                    return response.json().then((error) => {
                        throw new Error(error.message);
                    });
                }
                return response.json();
            })
            .then((data) => {
                console.log("Changes saved");
            })
            .catch((error) => {
                console.error(error.message);
                throw new Error(error.message);
            });

    }

    async function handleSave() {
        await upsertAccount();
        onSave({
            name,
            currency_code: currencyCode,
            description,
        });
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle>{account ? "Edit" : "Add"} Account</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    fullWidth
                    value={name || ""}
                    onChange={(e) => setName(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
                <FormControl fullWidth margin="dense" sx={{ height: '64px' }}>
                    <Select
                        labelId="currency-code-label"
                        value={currencyCode || ""}
                        onChange={(e) => setCurrencyCode(e.target.value)}
                        label=""
                    >
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="GBP">GBP</MenuItem>
                        <MenuItem value="HKD">HKD</MenuItem>
                        <MenuItem value="CHF">CHF</MenuItem>
                        <MenuItem value="SEK">SEK</MenuItem>
                        <MenuItem value="NOK">NOK</MenuItem>
                        <MenuItem value="AUD">AUD</MenuItem>
                    </Select>
                </FormControl>


                <TextField
                    margin="dense"
                    label="Description"
                    fullWidth
                    value={description || ""}
                    onChange={(e) => setDescription(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AccountDialog;

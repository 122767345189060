import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import HelpText from '../components/helpText';



const HelpPage = () => {
    return (
        <Box id="impMainBox" m="20px">
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}
                maxWidth="800px"
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={0} alignItems="center" marginTop={0} >
                        <Avatar src='/static/BGSF.US.png' alt={'Company Logo'}
                            sx={{
                                marginLeft: 0,
                                height: 28,
                                width: 28
                            }}
                        >
                        </Avatar>
                        <Typography variant="h4" ml={2}>
                            Hilfeseite für finalyser.app-Nutzer
                        </Typography>
                    </Stack>

                    <Typography variant="h5" align="left" mb={2} mt={2}>
                        Erstellen Sie Ihr Depot für die langfristige Analyse
                    </Typography>
                    <Typography variant="body1" align="justify" paragraph>
                        Willkommen bei finalyser.app, Ihrer Plattform für transparente und effiziente Portfolioverwaltung! In dieser
                        Anleitung erklären wir Ihnen Schritt für Schritt, wie Sie Ihre Wertpapiertransaktionen in finalyser.app erfassen
                        und die leistungsstarken Analysefunktionen nutzen können.
                        Zur langfristigen Analyse Ihrer Wertpapierinvestments benötigen Sie zunächst einige Wertpapiere in Ihrem Depot.
                        Starten Sie daher mit der Erfassung von "Käufen", die Sie bei verschiedenen Banken getätitigt haben.
                    </Typography>
                    <Typography variant="h6" align="left" mb={1}>
                        1. Transaktionen dokumentieren:
                    </Typography>
                    <Typography variant="body1" align="justify" paragraph>
                        In finalyser.app gibt es im Wesentlichen drei Transaktionsarten, die Sie erfassen können:
                    </Typography>
                    <Typography variant="body1" align="justify" paragraph>
                        a. Kauf: Um den Kauf eines Wertpapiers zu dokumentieren, navigieren Sie zur Seite "Kauf/Verkauf" (
                        <Link to="/transactiondividend">Kauf/Verkauf</Link>). Hier können Sie alle relevanten
                        Informationen zum Kauf eingeben, wie zum Beispiel den Namen des Wertpapiers, den Kaufpreis, die Anzahl der
                        erworbenen Stücke und das Kaufdatum.
                    </Typography>
                    <Typography variant="body1" align="justify" paragraph>
                        b. Verkauf: Ähnlich wie beim Erfassen eines Kaufs, können Sie auch den Verkauf eines Wertpapiers auf der Seite
                        "Kauf/Verkauf" dokumentieren. Geben Sie hier die notwendigen Informationen zum Verkauf an, etwa den Verkaufspreis,
                        die Anzahl der verkauften Stücke und das Verkaufsdatum.
                    </Typography>
                    <Typography variant="body1" align="justify" paragraph>
                        c. Dividendenzahlung: Um eine Dividendenzahlung zu erfassen, navigieren Sie zur Seite "Dividendenbuchung" (
                        <Link to="/transactiondividend">Dividende buchen</Link>). Hier wird Ihnen automatisch die letzte
                        Zahlung des Unternehmens vorgeschlagen, um die Eingabe zu erleichtern. Geben Sie die entsprechenden Informationen zur Dividendenzahlung ein, wie beispielsweise das Datum der Zahlung und die Höhe der Dividende pro Stück. Zusätzlich finden Sie im Bereich "Dividenden/Buchen" eine Liste für "nicht-gebuchte" Zahlungen, die Ihnen bei der Erfassung hilft.
                    </Typography>
                    <Typography variant="h6" align="left" mb={1}>
                        2. Dashboard und Finanzkennzahlen:
                    </Typography>
                    <Typography variant="body1" align="justify" paragraph>
                        Das Dashboard von finalyser.app bietet Ihnen hilfreiche Finanzkennzahlen zur Performance Ihres Depots für verschiedene Zeiträume. Hier erhalten Sie einen schnellen Überblick über die Entwicklung Ihrer Investments. Ebenfalls auf dem Dashboard finden Sie Ihre persönlichen Tops und Flops sowie den Dip-Gain Finder, der Ihnen dabei hilft, gezielte Kauf- oder Verkaufsentscheidungen zu treffen.
                    </Typography>
                    <HelpText viewName='dashboard3' />
                    <img
                        src="/static/myTops.png"
                        alt="My Tops..."
                        style={{ cursor: 'pointer', maxWidth: '100%', width: '400px', height: 'auto' }}
                    />
                    <HelpText viewName='dashboard' />
                    <img
                        src="/static/dipGain.png"
                        alt="Dip- Gainfinder..."
                        style={{ cursor: 'pointer', maxWidth: '100%', height: 'auto' }}
                    />
                    <Typography variant="body1" align="justify" paragraph>
                        Mit finalyser.app haben Sie alle wichtigen Informationen zu Ihrem Portfolio stets im Blick. Nutzen Sie die vielfältigen Funktionen und Analysen, um Ihre Wertpapierinvestments effizient und langfristig erfolgreich zu verwalten. Bei weiteren Fragen oder Anliegen steht Ihnen unser Support-Team gerne zur Verfügung.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HelpPage;

import { Box, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import { useCallback, useContext, useEffect, useState } from "react";
import FinDataGrid from '../../components/findatagrid';
import { formatDate, formatNumber, formatNumberCurrency } from "../../misc/helperfunctions";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ConfirmDialog from "../dialogs/confirmDialog";
import { Link } from "react-router-dom";
import { UserContext } from "../../theme/userContext";

const Porttransactions = ({ identifier, ...params }) => {

    const { user } = useContext(UserContext);
    const [transactions, setTransactions] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false); // TransactionDialog
    const [rowid, setRowid] = useState(); // TransactionDialog
    // Fetch Highlights
    async function fetchPorttransactions() {
        try {
            console.log("fetchPorttransactions: " + identifier);
            if (identifier && identifier.length > 1) {
                let puser = user?.p_user;
                let url = process.env.REACT_APP_API_URI + "/api/porttransaction?puser=" + puser + "&identifier=" + identifier;
                const response = await fetch(url);
                const data = await response.json();
                setTransactions(data);
            }
        } catch (error) {
            console.error(error);
        }
    }
    // eslint-disable-next-line
    const fetchPorttransactionsCallback = useCallback(fetchPorttransactions, [rowid]);
    useEffect(() => {
        console.log("useEffect fetch porttransaction")
        fetchPorttransactionsCallback();
    }, [fetchPorttransactionsCallback]);


    const deleteTransactionConfirm = (id) => {
        console.log("Porttransactions will delete transaction with id: " + id + " open ConfirmDialog")
        setRowid(id);
        setConfirmOpen(true);
    };

    async function deleteTransaction() {
        console.log("Porttransactions will delete transaction with id: " + rowid)
        try {
            if (rowid) {
                let url = process.env.REACT_APP_API_URI + "/api/deleteporttransaction";

                await fetch(url, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        id: rowid,
                        puser: user?.p_user,
                        identifier: identifier,
                    }),
                })
                    .then((response) => response.json())
                    .then(() => {
                        console.log("Changes saved");
                    })
                    .catch((error) => {
                        console.error(error);
                        return false;
                    })

                // Datensatz gelöscht
                setRowid(null);

            }
        } catch (error) {
            console.error(error);
        }
    }

    // helper function
    function findRowById(id) {
        for (let i = 0; i < transactions.length; i++) {
            if (transactions[i].id === id) {
                return transactions[i];
            }
        }
        return null;
    }

    const columns = [
        //  { field: "id", headerName: "ID", with: 20 },
        {
            field: "transactiondate",
            headerName: "Datum",
            width: 100,
            type: "date",
            valueFormatter: (params) => {
                const valueFormatted = formatDate(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "transaction",
            headerName: "Typ",
            cellClassName: "name-column--cell",
            width: 90,
        },
        {
            field: "quantity",
            headerName: "Stück",
            width: 120,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "price",
            headerName: "Preis / St.",
            width: 100,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "total_gross_amount",
            headerName: "Betrag",
            width: 100,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumber(params.value);
                return `${valueFormatted}`;
            },
        },
        {
            field: "totaltax",
            headerName: "Steuern",
            width: 100,
            type: "number",
            renderCell: (params) => {
                const row = findRowById(params.id);
                const amount = formatNumber(parseFloat(row.tax) + parseFloat(row.tax_foreign));
                return (
                    <Typography variant="body1">{amount}</Typography>
                );

            },
        },
        {
            field: "currencycode",
            headerName: "Währung",
            width: 80,
        },
        {
            field: "total_net_amount",
            headerName: "Gesamtbetrag netto €",
            width: 160,
            type: "number",
            valueFormatter: (params) => {
                const valueFormatted = formatNumberCurrency(params.value, '€');
                return `${valueFormatted}`;
            },
        },
        {
            field: "description",
            headerName: "Notiz",
            width: 200,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 120,
            getActions: (params) => {
                return ([
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Bearbeiten"
                        component={Link}
                        to={{
                            pathname: "/transactiondividend",
                        }}
                        state={{ identifier: identifier, portfoliotransactionid: params.id, editMode: true }}
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Löschen"
                        onClick={() => deleteTransactionConfirm(params.id)}
                        showInMenu
                    />,
                    <GridActionsCellItem
                        icon={<ContentCopyIcon />}
                        label="Duplizieren"
                        // onClick={duplicateUser(params.id)}
                        showInMenu
                    />,
                ]);
            },
        }
    ];

    return (
        <Box id="stockDetailBox" m="10px" width="100%" marginLeft={params.marginLeft}>
            <FinDataGrid
                id="gridTransactions"
                autoHeight
                //maxHeigth="500px"
                // pageSize="25"
                rows={transactions}
                columns={columns}
                density="compact"
                components={{
                    Toolbar: GridToolbar,
                }}
            >
            </FinDataGrid>
            <ConfirmDialog
                title="Delete Transaction?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={deleteTransaction}
            >
                Soll diese Transaktion wirklich gelöscht werden?
            </ConfirmDialog>
        </Box>
    );
};

export default Porttransactions;